import { BaseAction } from '../types';
import {
  EDIT_ATTACHMENT_LOADING,
  EDIT_ATTACHMENT_SUCCESS,
  EDIT_ATTACHMENT_ERROR,
  GET_ATTACHMENTS_CATEGORIES,
  GET_ATTACHMENTS_CATEGORIES_SUCCESS,
  GET_ATTACHMENTS_CATEGORIES_ERROR,
  CLEAR_ATTACHMENTS_STATE,
} from '../constants/referralTypes';
import { AttachmentCategoriesParams } from '../actions/attachmentActions';

export interface attachments {
  loading: boolean;
  data: any[];
  error: any;
  attachmentCategories: {
    loading: boolean;
    data: AttachmentCategoriesParams[];
    error: any;
  };
}

const initialState: attachments = {
  loading: false,
  data: [],
  error: null,
  attachmentCategories: {
    loading: false,
    data: [],
    error: null,
  },
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case EDIT_ATTACHMENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case EDIT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case EDIT_ATTACHMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ATTACHMENTS_CATEGORIES:
      return {
        ...state,
        attachmentCategories: {
          ...state.attachmentCategories,
          loading: true,
        },
      };
    case GET_ATTACHMENTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        attachmentCategories: {
          ...state.attachmentCategories,
          data: action.payload,
          loading: false,
        },
      };
    case GET_ATTACHMENTS_CATEGORIES_ERROR:
      return {
        ...state,
        attachmentCategories: {
          ...state.attachmentCategories,
          error: action.payload,
          loading: false,
        },
      };
    case CLEAR_ATTACHMENTS_STATE:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default reducer;
