import { Box, Button, Chip } from '@mui/material';
import { Referral } from '../../redux/types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState } from 'react';
import { ReferralType, ReferralTypeMenu } from '../Referral/ReferralTypeMenu';
import { updateReferralAction } from '../../redux/actions/referralsActions';
import { useDispatch } from 'react-redux';
import { referralTypeOptions } from '../../globalUtils/utils';

export function ReferralTypeComponent({ referralSubType, id }: Partial<Referral>) {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsEditing(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsEditing(false);
  };

  function setReferralType(type: ReferralType) {
    if (type.referralSubType === referralSubType) {
      handleClose();
      return;
    }
    setIsUpdating(true);
    handleClose();
    dispatch(
      updateReferralAction(
        {
          id,
          ...type,
        },
        () => setIsUpdating(false),
      ),
    );
  }

  return (
    <>
      <Button onClick={handleClick} style={{ padding: 0, marginLeft: '8px', cursor: 'pointer' }}>
        <Chip
          data-id="referralTypeView"
          variant="outlined"
          label={
            <Box style={{ display: 'flex' }} alignItems="center">
              {isUpdating ? (
                <span>Updating...</span>
              ) : (
                <>
                  <EditOutlinedIcon style={{ marginRight: '8px' }} />
                  <span>{referralTypeOptions.find((x) => x.value === referralSubType)?.label}</span>
                </>
              )}
            </Box>
          }
          color="primary"
        />
      </Button>
      <ReferralTypeMenu anchorEl={anchorEl} onChange={setReferralType} open={isEditing} onCancel={handleClose} />
    </>
  );
}
