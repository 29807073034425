export type TextMessage = {
  body: string;
  to: string;
  from?: string;
  status: TextMessageDeliveryStatus;
  dateSent: Date;
  direction: 'outbound' | 'inbound';
  id: string;
  author: string;
  authorId?: string;
  media: TextMessageMedia[];
};

export type TextMessageMedia = {
  url: string;
};

export type TextMessageDeliveryStatus = {
  message?: string;
  category: TextMessageDeliveryStatusCategory;
};

export enum TextMessageDeliveryStatusCategory {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}
