import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Props } from './MenuItemSearchTypes';
import { Box, Checkbox, CircularProgress, Typography } from '@mui/material';
import { useStyles } from './MenuItemStyles';
import MenuOption from './MenuOption';
import { defaultSortCompareFunction, sortByNameKeyCompareFunction } from '../../globalUtils/helpers';
import ActionButtons from './ActionButtons';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { StyledInputBase, StyledSearch } from '../SelectMenu/SelectMenu';
import searchIcon from '../../images/searchIcon.svg';

const MenuItemSearchComponent: FC<Props> = (props) => {
  const classes = useStyles();
  const {
    options,
    showAvatar,
    onChange,
    initialValues,
    style,
    placeHolder,
    showByGroup,
    setActionButtonOnTop,
    hideSearch,
    groupSelectable,
    onSearchChange,
    searchLoading,
    hideNoResults,
    menuTextStyle,
    hideActionButtons,
    menuItemsContentStyles,
    sortByName,
  } = props;
  const [menuSearchText, setMenuSearchText] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<any[]>(initialValues || []);

  const getGroupNames = (options: any[]): string[] => {
    const groupNamesSet: Set<string> = new Set(options.map((option) => option?.groupName));

    return Array.from(groupNamesSet).sort(defaultSortCompareFunction);
  };

  const getGroups = (groupNames: string[], options: any[]): any => {
    let groups: any = {};
    groupNames.forEach((groupName: string) => {
      const newOptions = filteredOptions.filter((option: any) => option.groupName === groupName);
      groups[groupName] = sortByName ? newOptions.sort(sortByNameKeyCompareFunction) : newOptions;
    });

    return groups;
  };

  const filteredOptions = options.filter(({ searchString }) =>
    searchString.toLowerCase().includes(menuSearchText.toLowerCase()),
  );

  const groupNames: string[] = getGroupNames(filteredOptions);
  const groups: any = getGroups(groupNames, filteredOptions);

  const onSelectOption = (value: any, checked: boolean) => {
    setSelectedOptions((previousSelectedOptions) => {
      const filteredValues =
        value && typeof value === 'object'
          ? previousSelectedOptions.filter((selectedObj) => selectedObj?.id !== value?.id)
          : previousSelectedOptions.filter((selected) => selected !== value);

      return checked ? [...previousSelectedOptions, value] : filteredValues;
    });
  };

  const selectAll = () => {
    filteredOptions.forEach(({ value, disabled }: any) => {
      if (!disabled) {
        onSelectOption(value, true);
      }
    });
  };

  const removeAll = () => {
    filteredOptions.forEach(({ value, disabled }) => {
      if (!disabled) {
        onSelectOption(value, false);
      }
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeAction = useCallback(onChange, []);
  useEffect(() => {
    changeAction(selectedOptions);
  }, [selectedOptions, changeAction]);

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setMenuSearchText(e.target.value);
    if (onSearchChange) {
      onSearchChange(e);
    }
  };

  return (
    <Box style={{ ...style, width: '100%', minWidth: 460, position: 'sticky', overflowY: 'hidden', top: 0 }}>
      <Box style={{ position: 'sticky', top: 0, zIndex: 99999, background: '#FFF', overflowY: 'hidden' }}>
        {setActionButtonOnTop && !hideActionButtons && (
          <Box style={{ borderBottom: '1px solid #00000014', paddingBottom: 8, marginBottom: 4 }}>
            <ActionButtons
              filteredOptions={filteredOptions}
              selectAll={selectAll}
              removeAll={removeAll}
              selectedOptions={selectedOptions}
            />
          </Box>
        )}
        {!hideSearch && (
          <Box className={classes.textFieldBox}>
            <StyledSearch>
              <StyledInputBase
                startAdornment={<img alt="" src={searchIcon} style={{ paddingLeft: 12 }} />}
                placeholder={!placeHolder ? 'Search' : placeHolder}
                onChange={onChangeText}
                inputProps={{ 'aria-label': 'search', style: { width: '100%' } }}
                inputRef={(input) => (selectedOptions.length > 0 ? null : input?.focus())}
                onKeyDown={(e) => e.stopPropagation()}
                endAdornment={
                  searchLoading ? (
                    <Box>
                      <CircularProgress size={16} style={{ marginRight: 16 }} />
                    </Box>
                  ) : null
                }
              />
            </StyledSearch>
          </Box>
        )}
      </Box>
      <Box style={{ ...menuItemsContentStyles, overflowY: 'scroll' }}>
        {showByGroup && Object.keys(groups).length
          ? groupNames.map((groupName: string, index: number) => {
              const group: any[] = groups[groupName];
              const onChangeGroup = (value: boolean) => {
                group.forEach((option: any) => {
                  onSelectOption(option.value, value);
                });
              };
              let defaultChecked = true;
              group.forEach((item) => {
                defaultChecked = defaultChecked && selectedOptions.includes(item.value);
              });
              return (
                <Box display="flex" style={{ flexDirection: 'column' }} key={`${groupName}-${index}`}>
                  <Box alignItems="center" style={{ paddingLeft: groupSelectable ? 20 : 0 }} display="flex">
                    {groupSelectable && (
                      <Checkbox
                        onChange={(_: any, value: boolean) => onChangeGroup(value)}
                        checked={defaultChecked}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        classes={{ root: classes.checkboxRoot }}
                      />
                    )}
                    <Typography
                      style={{ paddingLeft: 12, paddingTop: 13, paddingBottom: 13, color: '#00000099', flex: 1 }}
                    >
                      {group[0]?.groupName || 'Others'}
                    </Typography>
                  </Box>
                  <Box display="flex" style={{ flexDirection: 'column', paddingLeft: groupSelectable ? 20 : 0 }}>
                    {group.map((option, index) => (
                      <MenuOption
                        key={index}
                        disabled={option?.disabled}
                        option={option}
                        selectedOptions={selectedOptions}
                        onSelectOption={onSelectOption}
                        showAvatar={showAvatar}
                        menuTextStyle={menuTextStyle}
                      />
                    ))}
                  </Box>
                </Box>
              );
            })
          : filteredOptions.map((option: any, index) => {
              return (
                <MenuOption
                  key={index}
                  disabled={option?.disabled}
                  option={option}
                  selectedOptions={selectedOptions}
                  onSelectOption={onSelectOption}
                  showAvatar={showAvatar}
                  menuTextStyle={menuTextStyle}
                />
              );
            })}
        {menuSearchText && !filteredOptions.length && !searchLoading && !hideNoResults && (
          <Typography style={{ paddingLeft: 12, paddingTop: 13, paddingBottom: 13, color: '#00000099' }}>
            No Results Found
          </Typography>
        )}
      </Box>
      {!setActionButtonOnTop && !hideActionButtons && (
        <ActionButtons
          filteredOptions={filteredOptions}
          selectAll={selectAll}
          removeAll={removeAll}
          selectedOptions={selectedOptions}
        />
      )}
    </Box>
  );
};

export default MenuItemSearchComponent;
