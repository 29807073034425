import { BaseAction, activeDrawerState } from '../types';
import { SET_ACTIVE_DRAWER } from '../constants/inboxTypes';

const initialState: activeDrawerState = {
  activeDrawer: 'inbox',
};

const reducer = (state: activeDrawerState = initialState, action: BaseAction) => {
  switch (action.type) {
    case SET_ACTIVE_DRAWER:
      return {
        ...state,
        activeDrawer: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
