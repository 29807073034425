import {
  AdduserIcon,
  AttachmentIcon,
  ClockIcon,
  CommentCaptionIcon,
  FileIcon,
  MessageIcon,
  TaskIcon,
  UrgentIcon,
} from '../../components/Notifications/NotificationIcons';

export const NOTIFICATION_ICON_MAP = {
  Mention: CommentCaptionIcon,
  AddUser: AdduserIcon,
  Time: ClockIcon,
  Task: TaskIcon,
  Failure: UrgentIcon,
  Urgent: UrgentIcon,
  Attachment: AttachmentIcon,
  Referral: FileIcon,
  Message: MessageIcon,
} as Record<string, typeof CommentCaptionIcon>;
