import { Dispatch } from 'redux';
import {
  addToExistingReferralApi,
  assignInboxApi,
  getAttachmentApi,
  fetchInboxApi,
  forwardInboxToAsanaApi,
  getInboxItemApi,
  unAssignInboxApi,
  updateInboxApi,
  updateInboxRegionApi,
  updateStatusApi,
} from '../../api/inboxApi';
import {
  UPDATE_INBOX_FILTERS,
  UPDATE_INBOX_ACTION_LOADING,
  LOAD_USER_ACTION_RESPONSE_TO_STORE,
  UPDATE_INBOX_ACTION_ERROR,
  UPDATE_LIST_ITEMS,
  FETCH_RECEIVED_LOADING,
  LOAD_RECEIVED_TO_STORE,
  UPDATE_GLOBAL_INBOX_LOADING,
  SET_INBOX_ACTIVE_ROW,
  LOAD_NEW_INBOX_ITEM_TO_STORE,
  INBOX_ITEM_LOADING,
  INBOX_ITEM_ERROR,
  ADD_ACTIVE_ROW_COMMENTS,
  FORWARD_INBOX_TO_ASANA,
  FORWARD_INBOX_TO_ASANA_SUCCESS,
  FORWARD_INBOX_TO_ASANA_ERROR,
  ADD_TO_EXISTING_REFERRAL,
  ADD_TO_EXISTING_REFERRAL_SUCCESS,
  ADD_TO_EXISTING_REFERRAL_ERROR,
  CLEAR_FORWARD_TO_ASANA,
  CLEAR_ADD_TO_EXISTING_REFERRAL,
  SET_INBOX_RECEIVED_SORT_DIRECTION,
  SET_ARCHIVED_INBOX_SORT_DIRECTION,
  FETCH_ARCHIVED_LOADING,
  LOAD_ARCHIVED_TO_STORE,
} from '../constants/inboxTypes';
import { FaxType } from '../../views/Inbox/inboxTypes';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import { updateArchivedInboxCount, updateInboxReceivedCount } from '../actionCreators/inboxActionCreators';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import socket from '../../api/socketsApi';
import { SocketPaths } from '../../globalUtils/constants';

const inboxApiCallPageSize: number = 26;

export const actionCreator = (action: string, payload: any) => {
  return {
    type: action,
    payload,
  };
};

export const inboxGlobalLoadingAction = (payload: boolean) => ({
  type: UPDATE_GLOBAL_INBOX_LOADING,
  payload,
});

export const setArchivedInboxLoadingAction = (isLoading: boolean) => actionCreator(FETCH_ARCHIVED_LOADING, isLoading);

export const fetchArchivedInbox =
  (params: any, onFinish?: () => void, hideLoading?: boolean) => async (dispatch: Dispatch) => {
    try {
      let response;
      const reqPayload = { pageSize: inboxApiCallPageSize, ...params, team: undefined };
      response = await fetchInboxApi(reqPayload);
      const data = response.data;
      dispatch(actionCreator(LOAD_ARCHIVED_TO_STORE, data));
    } catch (e: any) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);
    } finally {
      onFinish && onFinish();
      dispatch(setArchivedInboxLoadingAction(false));
      dispatch(inboxGlobalLoadingAction(false));
      console.log('HideLoading value: ', hideLoading);
    }
  };

export const fetchReceivedInbox =
  (params: any, onFinish?: () => void, hideLoading?: boolean) => async (dispatch: Dispatch) => {
    try {
      !hideLoading && dispatch(actionCreator(FETCH_RECEIVED_LOADING, { loading: true }));
      const response = await fetchInboxApi({
        pageSize: inboxApiCallPageSize,
        ...params,
      });
      dispatch(actionCreator(LOAD_RECEIVED_TO_STORE, response.data));
    } catch (e: any) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);
    } finally {
      dispatch(actionCreator(FETCH_RECEIVED_LOADING, { loading: false }));
      dispatch(inboxGlobalLoadingAction(false));
      onFinish && onFinish();
    }
  };

export const updateInboxCountAction =
  (params: any, isReceivedInbox: boolean) => async (dispatch: Dispatch, getState: any) => {
    try {
      const {
        inbox: {
          filters: inboxFilters,
          received: { sortDirection: receivedSortDirection },
        },
      } = getState();
      const response = await fetchInboxApi({
        ...inboxFilters,
        pageSize: 1,
        page: 1,
        sortField: 'faxReceivedDate',
        sortDirection: receivedSortDirection.toUpperCase(),
        ...params,
      });
      const totalCount: number = response?.data?.totalCount;
      if (isReceivedInbox) {
        dispatch(updateInboxReceivedCount(totalCount));
      } else {
        dispatch(updateArchivedInboxCount(totalCount));
      }
    } catch (e: any) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);
    }
  };

export const updateInboxFilters = (params: any) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_INBOX_FILTERS,
    payload: params,
  });
};

export const updateInboxActionLoading = (key: string, payload: boolean) => {
  return {
    type: UPDATE_INBOX_ACTION_LOADING,
    payload,
    key,
  };
};

export const titleOrRegionUpdateResponse = (key: string, payload: any) => {
  return {
    type: LOAD_USER_ACTION_RESPONSE_TO_STORE,
    payload,
    key,
  };
};

export const updateInboxActionError = (key: string, payload: any) => {
  return {
    type: UPDATE_INBOX_ACTION_ERROR,
    payload,
    key,
  };
};

export const forwardInboxToAsanaLoading = () => ({
  type: FORWARD_INBOX_TO_ASANA,
});

export const clearForwardToAsanaState = () => ({
  type: CLEAR_FORWARD_TO_ASANA,
});

export const forwardInboxToAsanaSuccess = (payload: any) => ({
  type: FORWARD_INBOX_TO_ASANA_SUCCESS,
  payload,
});

export const forwardInboxToAsanaError = (payload: any) => ({
  type: FORWARD_INBOX_TO_ASANA_ERROR,
  payload,
});

export const addToExistingReferralLoading = () => ({
  type: ADD_TO_EXISTING_REFERRAL,
});

export const addToExistingReferralSuccess = (payload: any) => ({
  type: ADD_TO_EXISTING_REFERRAL_SUCCESS,
  payload,
});

export const addToExistingReferralError = (payload: any) => ({
  type: ADD_TO_EXISTING_REFERRAL_ERROR,
  payload,
});

export const ClearAddToExistingReferral = () => ({
  type: CLEAR_ADD_TO_EXISTING_REFERRAL,
});

export const updateInbox =
  (key: 'titleUpdate' | 'regionUpdate', params: any, onSuccess?: (data: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(updateInboxActionLoading(key, true));
      const response = key === 'titleUpdate' ? await updateInboxApi(params) : await updateInboxRegionApi(params);
      dispatch(titleOrRegionUpdateResponse(key, response.data));
      onSuccess && onSuccess(response.data);
    } catch (e) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(updateInboxActionError(key, e));
    } finally {
      dispatch(updateInboxActionLoading(key, false));
    }
  };

export const updateInboxList = (payload: any) => {
  return {
    type: UPDATE_LIST_ITEMS,
    payload,
  };
};

export const assignInbox = (params: any, onSuccess: (data: any) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateInboxActionLoading('assignUser', true));
    const response = await assignInboxApi(params);
    dispatch(titleOrRegionUpdateResponse('assignUser', response.data));
    onSuccess && onSuccess(response.data);
  } catch (e) {
    const errorMessage: string = backendErrorHandler(e);
    showErrorMessage(errorMessage);

    dispatch(updateInboxActionError('assignUser', e));
  } finally {
    dispatch(updateInboxActionLoading('assignUser', false));
  }
};

export const unAssignInbox = (params: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateInboxActionLoading('unAssignUser', true));
    const response = await unAssignInboxApi(params);
    dispatch(titleOrRegionUpdateResponse('unAssignUser', response.data));
  } catch (e) {
    const errorMessage: string = backendErrorHandler(e);
    showErrorMessage(errorMessage);

    dispatch(updateInboxActionError('unAssignUser', e));
  } finally {
    dispatch(updateInboxActionLoading('unAssignUser', false));
  }
};

export const updateInboxStatus =
  (params: { id: string | undefined; status: string | undefined }, callback?: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(updateInboxActionLoading('statusUpdate', true));
      const response = await updateStatusApi(params);
      dispatch(titleOrRegionUpdateResponse('statusUpdate', response.data));
      callback && callback();
    } catch (e) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(updateInboxActionError('statusUpdate', e));
    } finally {
      dispatch(updateInboxActionLoading('statusUpdate', false));
    }
  };

export const forwardInboxToAsanaAction = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(forwardInboxToAsanaLoading());
    const { data } = await forwardInboxToAsanaApi(id);
    dispatch(forwardInboxToAsanaSuccess(data));
    dispatch(updateInboxList(data));
  } catch (e: any) {
    dispatch(forwardInboxToAsanaError(e.response.data));

    const errorMessage: string = backendErrorHandler(e);
    showErrorMessage(errorMessage);
  }
};

export const loadNewInboxToStore = (payload: any) => {
  return {
    type: LOAD_NEW_INBOX_ITEM_TO_STORE,
    payload,
  };
};

export const useInboxSocketMessage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Instantiating new inbox sockets');
    const newInboxItemSocket = socket(SocketPaths.newInboxes);
    newInboxItemSocket.on('inbox.new', (data: FaxType) => {
      dispatch(loadNewInboxToStore(data));
      dispatch(updateInboxCountAction({}, true));
    });

    return () => {
      console.log('Closing new inbox socket');
      newInboxItemSocket.close();
    };
    // The linter is wrong about declaring the variables used in the event handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
};

export const addActiveRowCommentsAction = (payload: any) => {
  return {
    type: ADD_ACTIVE_ROW_COMMENTS,
    payload,
  };
};

export const setInboxActiveRow = (payload: any) => {
  return {
    type: SET_INBOX_ACTIVE_ROW,
    payload,
  };
};

export const inboxItemLoading = (payload: boolean) => {
  return {
    type: INBOX_ITEM_LOADING,
    payload,
  };
};
export const inboxItemError = (payload: any) => {
  return {
    type: INBOX_ITEM_ERROR,
    payload,
  };
};

export const getInboxItem = (id: string, callBack?: (responseData: any) => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(inboxItemLoading(true));
    const response = await getInboxItemApi(id);
    callBack && callBack(response.data);
  } catch (e: any) {
    dispatch(inboxItemError(e));
    const errorMessage: string = backendErrorHandler(e);
    showErrorMessage(errorMessage);
  } finally {
    dispatch(inboxItemLoading(false));
  }
};

export const getAttachmentAction = (id: string, onSuccess: (file: any) => void) => async (dispatch: Dispatch) => {
  try {
    const response = await getAttachmentApi(id);
    onSuccess(response.data);
  } catch (e) {
    const errorMessage: string = backendErrorHandler(e);
    showErrorMessage(errorMessage);
    console.log('*****', e);
  }
};

export const addToExistingReferralAction =
  (params: any, onSuccess?: (data?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(addToExistingReferralLoading());
      const { data } = await addToExistingReferralApi(params);
      dispatch(addToExistingReferralSuccess(data));
      onSuccess && onSuccess(data);
    } catch (e: any) {
      const errorMessage: string = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(addToExistingReferralError(e.response.data));
    }
  };

export const setInboxReceivedSortDirection = (sortDirection: 'asc' | 'desc') => ({
  type: SET_INBOX_RECEIVED_SORT_DIRECTION,
  payload: sortDirection,
});

export const setArchivedInboxSortDirection = (sortDirection: 'asc' | 'desc') => ({
  type: SET_ARCHIVED_INBOX_SORT_DIRECTION,
  payload: sortDirection,
});
