import { Box } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { markNotificationAsReadAction, markNotificationAsUnreadAction } from '../../redux/actions/notificationsActions';
import { Notification } from '../../redux/types';
import { useStyles } from './notificationsStyles';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Message from '@mui/icons-material/Message';
import commentCaption from '../../images/commentCaption.svg';
import fileIcon from '../../images/file.svg';
import DoneIcon from '@mui/icons-material/Done';
import addUser from '../../images/addUser.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress } from '@mui/material';

interface Props {
  notification: Notification;
  notificationItemHovered: boolean;
}

export const UnreadIcon: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { notification } = props;

  const markNotificationAsRead = (notification: Notification) => {
    dispatch(markNotificationAsReadAction(notification));
  };

  return (
    <Tooltip title="Mark as Read">
      <Box display="flex" className={classes.unreadIConContainer} onClick={() => markNotificationAsRead(notification)}>
        <Box className={classes.unreadNotificationIcon} />
      </Box>
    </Tooltip>
  );
};

export const ReadIcon: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { notification, notificationItemHovered } = props;

  const markNotificationAsUnread = (notification: Notification) => {
    dispatch(markNotificationAsUnreadAction(notification));
  };

  return (
    <Tooltip title="Mark as Unread">
      {notificationItemHovered ? (
        <Box
          display="flex"
          className={classes.readIconContainer}
          onClick={() => markNotificationAsUnread(notification)}
        />
      ) : (
        <Box />
      )}
    </Tooltip>
  );
};

export const LoaderIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.loaderIconContaienr} alignItems="center">
      <CircularProgress size={18} />
    </Box>
  );
};

export const ClockIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <Box display="flex" style={{ background: '#ED6C021F' }} className={classes.innerIconContainer}>
        <AccessTimeIcon style={{ color: '#FF9800' }} className={classes.innerIcon} />
      </Box>
    </Box>
  );
};

export const AttachmentIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <AttachFileIcon style={{ transform: 'rotate(45deg)', color: '#9E9E9E', fontSize: 28 }} />
    </Box>
  );
};

export const MessageIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <Message style={{ color: '#9E9E9E', fontSize: 28 }} />
    </Box>
  );
};

export const CommentCaptionIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <img src={commentCaption} alt="" />
    </Box>
  );
};

export const FileIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <img src={fileIcon} alt="" />
    </Box>
  );
};

export const TaskIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <Box
        display="flex"
        className={classes.innerIconContainer}
        style={{
          borderRadius: 0,
          border: '2px solid #9E9E9E',
          width: 17,
          height: 17,
          color: '#9E9E9E',
        }}
      >
        <DoneIcon className={classes.innerIcon} style={{ color: '#9E9E9E' }} />
      </Box>
    </Box>
  );
};

export const AdduserIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <img src={addUser} alt="" />
    </Box>
  );
};

export const UrgentIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.notificationIconsContainer}>
      <Box display="flex" className={classes.innerIconContainer} style={{ background: '#D32F2F1F' }}>
        <ErrorOutlineIcon className={classes.innerIcon} style={{ color: '#D32F2F' }} />
      </Box>
    </Box>
  );
};
