import { Box } from '@mui/material';
import { FC } from 'react';
import EditAbleAttachmentItem from '../AttachmentItem/EditAbleAttachmentItem';
import DropZone from '../DropZone/DropZone';
import { AttachmentCategoriesParams } from '../../redux/actions/attachmentActions';

interface Props {
  setAttachmentValues: (attachmentValues: any) => void;
  handleDrop: (acceptedFiles: any, error: any) => void;
  handleRemoveFile: (fileName: string) => void;
  selectedFiles?: any;
  categories: AttachmentCategoriesParams[];
  filesUploadState?: any; // for use in editing referrals attachments UI
  formatSelectedToStr: (selected: string[]) => string[] | any;
  initialCategories?: string[];
  categoryRequired?: boolean;
  hasWeInfusePatient?: boolean;
  hidePublishToWeInfuse?: boolean;
  onSelectPublishToEMR?: (file: any, isSelected: boolean) => void;
}

const AddAttachmentsDropZone: FC<Props> = (props) => {
  const {
    setAttachmentValues,
    handleDrop,
    selectedFiles: rawSelectedFiles,
    handleRemoveFile,
    categories,
    formatSelectedToStr,
    filesUploadState,
    initialCategories,
    categoryRequired,
    hasWeInfusePatient,
    hidePublishToWeInfuse,
    onSelectPublishToEMR,
  } = props;

  const selectedFiles = rawSelectedFiles.map((rawFile: any) => {
    // to cater for cases like in <AddAttachmentsDropZone /> component
    const filename = rawFile?.filename || rawFile?.name?.substring(0, rawFile?.name?.lastIndexOf('.')) || rawFile;
    return {
      id: filename,
      rawFile: rawFile,
      filename,
      createdAt: new Date(),
      extension: rawFile?.extension || rawFile?.rawFile?.name?.split('.').pop(),
      url: '',
    };
  });

  return (
    <Box>
      <DropZone handleDrop={handleDrop} />
      {selectedFiles.map((attachment: any) => {
        return (
          <EditAbleAttachmentItem
            key={attachment.id}
            file={attachment}
            initialCategories={initialCategories}
            handleRemoveFile={handleRemoveFile}
            setAttachmentValues={setAttachmentValues}
            categories={categories}
            formatSelectedToStr={formatSelectedToStr}
            percentComplete={filesUploadState && filesUploadState[attachment.rawFile?.name]?.percentComplete}
            fileUploadError={filesUploadState && filesUploadState[attachment.rawFile?.name]?.error}
            errorToolTip={filesUploadState && filesUploadState[attachment.rawFile?.name]?.errorMessage}
            fileUploadLoading={filesUploadState && filesUploadState[attachment.rawFile?.name]?.loading}
            fileUploadSuccess={filesUploadState && filesUploadState[attachment.rawFile?.name]?.success}
            categoryRequired={categoryRequired}
            hasWeInfusePatient={hasWeInfusePatient}
            hidePublishToEMR={hidePublishToWeInfuse}
            onSelectPublishToEMR={onSelectPublishToEMR}
          />
        );
      })}
    </Box>
  );
};

export default AddAttachmentsDropZone;
