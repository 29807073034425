import { Dispatch } from 'redux';
import { getStatusReasons } from '../../api/statusReasons';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  getStatusReasonsError,
  getStatusReasonsSuccess,
  setStatusReasonsLoading,
} from '../actionCreators/statusReasonsActionCreators';

export const fetchStatusReasons = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setStatusReasonsLoading(true));
    const response = await getStatusReasons();
    dispatch(getStatusReasonsSuccess(response.data));
  } catch (error) {
    const errorMessage: string = backendErrorHandler(error);
    showErrorMessage(errorMessage);
    dispatch(getStatusReasonsError(error));
  } finally {
    dispatch(setStatusReasonsLoading(false));
  }
};
