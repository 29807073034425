import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearProvidersFromSearch, getReferralProviders } from '../redux/actions/referralProvidersAction';
import { InboxFilters, InboxItem, medications, RootState, TaskType } from '../redux/types';
import socket from '../api/socketsApi';
import { getOneReferral, loadNewCommentFromSocket } from '../redux/actions/referralsActions';
import { getCommentApi } from '../api/inboxApi';
import {
  CommentSocketEvents,
  NO_REGION_ID_SET,
  SocketEvents,
  SocketPaths,
  statusesToExcludeFromSimpleStatusChange,
} from './constants';
import {
  addActiveRowCommentsAction,
  fetchArchivedInbox,
  fetchReceivedInbox,
  getAttachmentAction,
  updateInboxCountAction,
} from '../redux/actions/inboxActions';
import { fetchUsersAction } from '../redux/actions/userActions';
import { getTaskStatusListAction } from '../redux/actions/tasksActions';
import { getTaskTemplatesAction } from '../redux/actions/taskTemplatesActions';
import { defaultSortCompareFunction } from './helpers';
import {
  getFileType,
  getReferralsStatusColors,
  getFilteredReferrals,
  getResultsToDisplayInInboxList,
  getTaskColors,
} from './utils';
import scrollIntoViewIfNeeded from '../globalUtils/scrollIntoView/scrollIntoView';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { GetAssigneeOptionHookProps, SearchHandler } from './interfaces';
import { statuses } from '../views/Referrals/Referrals';
import { useLocation } from 'react-router-dom';
import { setAttachmentBeingUploadedToWeInfuseLoading } from '../redux/actionCreators/weInfuseActionCreators';
import { uploadAttachmentToWeInfuseAction } from '../redux/actions/attachmentActions';

interface Args {
  tableEl: any;
  loadMore: () => void;
  hasMore: boolean;
  loading: boolean;
}
export const useScrollHooks = ({ tableEl, loadMore, hasMore, loading }: Args) => {
  const [distanceBottom, setDistanceBottom] = useState(0);
  const scrollListener = useCallback(() => {
    const bottom = tableEl?.current?.scrollHeight - tableEl?.current?.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round((bottom / 100) * 20));
    }

    if (tableEl?.current?.scrollTop > bottom - distanceBottom && hasMore && !loading) {
      loadMore();
    }
  }, [hasMore, loadMore, loading, distanceBottom, tableEl]);

  useLayoutEffect(() => {
    const tableRef = tableEl?.current;
    tableRef?.addEventListener('scroll', scrollListener);
    return () => {
      tableRef?.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener, tableEl]);
};

export const useGetAssigneeOptions = (props?: GetAssigneeOptionHookProps): any[] => {
  const assignees = useSelector((state: RootState) => state.users.data);
  const loggedInUser = useSelector((state: RootState) => state.auth.identityInfo);
  const [assigneeOptions, setAssigneeOptions] = useState<any>([]);

  const getAssigneeOptionsBesidesLoggedInUer = (assigneeOptions: any[]) =>
    assigneeOptions.filter((assigneeOption: any) => !assigneeOption.isLoggedInUser);
  const getLoggedInAssigneeOptions = (assigneeOptions: any[]) =>
    assigneeOptions.filter((assigneeOption: any) => assigneeOption.isLoggedInUser);

  const getSortedAssigneeOptions = (assigneeOptions: any[]): any => {
    return [
      ...getLoggedInAssigneeOptions(assigneeOptions),
      ...getAssigneeOptionsBesidesLoggedInUer(assigneeOptions).sort((a, b) =>
        defaultSortCompareFunction(a, b, 'label'),
      ),
    ];
  };

  useEffect(() => {
    setAssigneeOptions([
      ...assignees.map((user) => {
        return {
          label: `${user.firstName} ${user.lastName}`,
          searchString: `${user.firstName} ${user.lastName}`,
          value: user.id,
          avatarInitials: `${user.firstName?.[0]}${user.lastName?.[0]}`,
          archived: !user.active,
          isLoggedInUser:
            matchCaseInsensitive(user.email, loggedInUser?.email) ||
            matchCaseInsensitive(user.email, loggedInUser?.preferred_username),
          secondaryLabel: (
            <Typography variant="caption" color="#00000061" textTransform="lowercase">{`${
              user?.referralsCount ? user.referralsCount : 0
            } ${user?.referralsCount === 1 ? 'referral' : 'referrals'}`}</Typography>
          ),
        };
      }),
    ]);

    function matchCaseInsensitive(a?: string, b?: string) {
      if (!a || !b) {
        return false;
      }
      return a.toLowerCase() === b.toLowerCase();
    }
  }, [assignees, loggedInUser?.email, loggedInUser?.preferred_username]);

  return getSortedAssigneeOptions(
    props?.hideUnassigned
      ? assigneeOptions
      : [
          ...assigneeOptions,
          {
            label: `Unassigned`,
            searchString: `Unassigned`,
            value: '',
            avatarInitials: `UA`,
            isLoggedInUser: false,
            archived: false,
          },
        ],
  );
};

export const useGetMedicationsOptions = (): any[] => {
  const medications: medications[] = useSelector((state: RootState) => state.medications.data);
  const [medicationsOptions, setMedicationsOptions] = useState<any[]>([]);

  useEffect(() => {
    const formattedMedications = medications
      .map((medication: any) => ({
        label: medication?.name,
        searchString: medication?.name,
        value: medication.id,
        groupName: medication?.drugTier?.name,
      }))
      .sort((a, b) => defaultSortCompareFunction(a, b, 'groupName'));

    const noMedicationFilter = {
      label: 'No medication',
      searchString: 'No medication',
      id: 'noMedication',
      value: null,
    };
    setMedicationsOptions([...formattedMedications, noMedicationFilter]);
  }, [medications]);

  return medicationsOptions;
};

export const useGetTasksOptions = (tab: string): any[] => {
  const { archivedTabTaskStatuses, openTabTaskStatuses } = useSelector((state: RootState) => state.taskTemplates);
  const [tasksOptions, setTasksOptions] = useState<any[]>([]);

  const getTabTaskOptions = (tab: string, archivedOptions?: any[], openOptions?: any[]): any[] => {
    switch (tab) {
      case 'OPEN':
        return openOptions || [];
      case 'ARCHIVE':
        return archivedOptions || [];
      default:
        const openTasks = openOptions || [];
        const archiveTasks = archivedOptions || [];
        return [...openTasks, ...archiveTasks];
    }
  };

  useEffect(() => {
    const tabTaskStatuses: any[] = getTabTaskOptions(tab, archivedTabTaskStatuses, openTabTaskStatuses);
    setTasksOptions(
      tabTaskStatuses.map((taskStatus: any) => ({
        ...taskStatus,
        label: taskStatus?.name?.toLowerCase(),
        value: taskStatus?.id,
        searchString: taskStatus?.name,
        listItemView: (
          <Box display="flex" alignItems="center">
            <img alt={''} style={{ marginRight: 12 }} src={getTaskColors(taskStatus.name).iconImage} />
            <Typography style={{ textTransform: 'capitalize' }} variant="body1">
              {taskStatus.name?.toLowerCase()}
            </Typography>
          </Box>
        ),
      })),
    );
  }, [archivedTabTaskStatuses, openTabTaskStatuses, tab]);

  return tasksOptions.sort((a, b) => defaultSortCompareFunction(a, b, 'sortOrder'));
};

export const useGetTaskTypesOptions = (): any[] => {
  const {
    tasks: {
      taskTypes: { data: taskTypesData },
    },
  } = useSelector((state: RootState) => state);
  const [taskTypesOptions, setTaskTypesOptions] = useState<any[]>([]);

  useEffect(() => {
    setTaskTypesOptions(
      taskTypesData.map((taskType: TaskType) => ({
        label: taskType?.name,
        value: taskType.id,
        searchString: taskType?.name,
      })),
    );
  }, [taskTypesData]);

  return taskTypesOptions.sort((a, b) => a.label.localeCompare(b.label));
};

export const useGetReferralStatusesOptions = (): any[] => {
  const referralStatuses: any[] = useSelector((state: RootState) => state.referrals?.referralStatuses?.data);
  const [referralStatusesOptions, setReferralStatusesOptions] = useState<any[]>([]);

  useEffect(() => {
    setReferralStatusesOptions(
      referralStatuses.map((referralStatus: any) => {
        const statusAndSortValue = getReferralsStatusColors(referralStatus?.title);

        return {
          label: referralStatus?.title,
          value: referralStatus?.id,
          searchString: referralStatus?.title,
          sortValue: statusAndSortValue.sortValue,
          icon: statusAndSortValue.iconImage,
        };
      }),
    );
  }, [referralStatuses]);

  return referralStatusesOptions.sort((a, b) => defaultSortCompareFunction(a, b, 'sortValue'));
};

export const useLoadNewCommentFromSocketToStore = (id: string, type: string, eventName: string) => {
  const loggedInUser = useSelector((state: RootState) => state.auth.identityInfo);
  const assignees = useSelector((state: RootState) => state.users.data);
  const dispatch = useDispatch();

  useEffect(() => {
    const socketInstance = socket(SocketPaths.notifications);
    const loggedInAssignee = assignees.find(
      (assignee) => assignee.email === loggedInUser?.email || assignee.email === loggedInUser?.preferred_username,
    );

    if (loggedInAssignee) {
      socketInstance.emit('comments.subscribe', {
        type,
        id: id,
        userId: loggedInAssignee?.id,
      });
    }

    socketInstance.on(eventName, (data) => {
      console.log(`Received new ${eventName}: comment for type: ${type}`, data);
      // TODO: Work with the backend to get all the missing properties to avoid making additional API calls.
      getCommentApi(data.id).then((response): any => {
        if (data?.user?.id !== loggedInAssignee?.id && CommentSocketEvents.includes(eventName)) {
          dispatch(loadNewCommentFromSocket(response.data));
          eventName === SocketEvents.newInboxComment && dispatch(addActiveRowCommentsAction(response.data));
        }
      });
    });

    return () => {
      socketInstance.emit('comments.unsubscribe', {
        type: eventName,
        id,
        userId: loggedInAssignee?.id,
      });
      socketInstance?.close();
    };
  }, [assignees, dispatch, eventName, id, loggedInUser, type]);
};

export const usePreloadCommonStatesToReducer = (): boolean => {
  const dispatch = useDispatch();
  const { taskTemplates, patients, medications, users } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getTaskStatusListAction());
    dispatch(fetchUsersAction());
    dispatch(getTaskTemplatesAction());
  }, [dispatch]);

  useEffect(() => {
    setLoading(users.loading || medications.loading || patients.loading || taskTemplates.loading);
  }, [users, medications, patients, taskTemplates]);

  return loading;
};

export const useReferralEnabledForSimpleStatusChange = (currentReferral: any): boolean => {
  const [isEnabledForSimpleStatusChange, setIsEnabledForSimpleStatusChange] = useState<boolean>(false);
  useEffect(() => {
    setIsEnabledForSimpleStatusChange(
      !statusesToExcludeFromSimpleStatusChange.includes(currentReferral?.status?.title?.toLowerCase()),
    );
  }, [currentReferral]);

  return isEnabledForSimpleStatusChange;
};

/**
 * @deprecated this utility is not being used as this data is now appended in the original api
 */
export const useGetUpdatedReferrals = (referrals: any): any => {
  const { patients } = useSelector((state: RootState) => state);
  const [updatedReferrals, setUpdatedReferrals] = useState<any[]>(referrals);

  useEffect(() => {
    setUpdatedReferrals(
      referrals?.map((referral: any) => ({
        ...referral,
        patient: patients?.data?.find((patient: any) => patient.id === referral?.patientId),
      })),
    );
  }, [patients, referrals]);

  return updatedReferrals;
};

export const useSearchOnChangeHandler = (
  searchCallBack: (searchValue: string) => void,
  initialSearchTerm?: string,
): SearchHandler => {
  const [searchValueChangeTimeout, setSearchValueChangeTimeout] = useState<any>(0);
  const [searchValue, setSearchValue] = useState<string>(initialSearchTerm || '');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    if (searchValueChangeTimeout) {
      clearTimeout(searchValueChangeTimeout);
    }

    setSearchValueChangeTimeout(
      setTimeout(() => {
        searchCallBack(event.target.value);
      }, 1000),
    );
  };

  const onClearSearch = (): void => {
    setSearchValue('');
    searchCallBack('');
  };

  return { onChange, searchValue, onClearSearch, setSearchValue };
};

export const useScrollIntoView = (elementId?: string | null, dependencies?: any, clearScrollIntoView?: () => void) => {
  useEffect(() => {
    if (elementId && !dependencies?.loading) {
      const element = document.getElementById(elementId);

      if (element) {
        scrollIntoViewIfNeeded(element, { behavior: 'auto', block: 'center', inline: 'nearest' }, clearScrollIntoView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, dependencies]);
};

export const useOpenFileInNewTab = (fileId?: string, fileExtension?: string, callBack?: () => void) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<any>();
  const onSuccess = useCallback(() => {
    callBack && callBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fileId) {
      dispatch(getAttachmentAction(fileId, setFile));
    }
  }, [dispatch, fileId]);

  useEffect(() => {
    if (file && fileExtension) {
      const fileBlob: Blob = new Blob([file], { type: `${getFileType(fileExtension)}/${fileExtension}` });
      const fileURL: string = URL.createObjectURL(fileBlob);
      window.open(fileURL);
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, onSuccess]);
};

export const useProviderHooks = (clinicId?: string, shouldIncludeTemporary: boolean | undefined = undefined) => {
  const { providers: providersState, loading } = useSelector((state: RootState) => state.referralProviders);

  const [hideNoResults, setHideNoResults] = useState(true);
  const [searchString, setSearchString] = useState('');

  const providerOptions = providersState
    ?.map((provider: any) => {
      const label = (
        <Box display="flex" justifyContent="flex-start">
          {/* <CustomAvatar
          name={`${provider?.firstName || ''} ${provider?.lastName || ''}s`}
          style={{ marginRight: 8, width: 30, height: 30 }}
        /> */}
          <Typography
            title={`${provider?.firstName} ${provider?.lastName}${
              provider?.credentials ? ', ' + provider?.credentials : ''
            }`}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {`${provider?.firstName} ${provider?.lastName}${provider?.credentials ? ', ' + provider?.credentials : ''}`}
          </Typography>
          {provider?.isTemporary && (
            <Tooltip title="Temporary Provider" placement="top">
              <Chip
                size="small"
                label="TP"
                sx={{
                  '& .MuiChip-label': {
                    fontSize: '12px !important',
                  },
                }}
                style={{ color: '#ED6C02', backgroundColor: 'rgba(237, 108, 2, 0.12)', marginLeft: 8 }}
              />
            </Tooltip>
          )}
        </Box>
      );
      return {
        ...provider,
        label,
        labelWithoutAvatar: `${provider?.firstName} ${provider?.lastName}${
          provider?.credentials ? ', ' + provider?.credentials : ''
        }`,
        searchString: provider?.firstName + ' ' + provider?.lastName + ' ' + provider?.npi,
        secondaryLabel: `# ${provider?.npi || ''} ${
          provider?.region?.abbreviation ? ' - ' + provider?.region?.abbreviation : ''
        }`,
        inputId: 'provider',
      };
    })
    .sort((a, b) => a?.name?.localeCompare(b?.name));

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((searchText: string) => {
      setHideNoResults(false);
      dispatch(
        getReferralProviders({
          search: searchText,
          // for backend to return both temporary and weinfuse providers, it does not need isTemporary flag to exist in the request
          ...(shouldIncludeTemporary ? {} : { isTemporary: shouldIncludeTemporary }),
          clinicId,
        }),
      );
    }, 500),
    [clinicId],
  );

  const handleSearch = (searchText: string) => {
    // clear loaded providers on every new search
    dispatch(clearProvidersFromSearch());
    setHideNoResults(true);
    if (searchText.length > 1) {
      delayedQuery(searchText);
    }
    setSearchString(searchText);
  };

  const onClose = () => {
    setSearchString('');
  };
  useEffect(() => {
    return () => {
      dispatch(clearProvidersFromSearch());
    };
  }, [dispatch]);
  return {
    hideNoResults,
    handleSearch,
    providersState,
    loading,
    providerOptions,
    onClose,
    searchString,
  };
};

export const useGetRegionsFromStore = (appendNoRegion?: boolean): any[] => {
  const {
    regions: { data },
  } = useSelector((state: RootState) => state);
  return useMemo(
    (): any[] =>
      appendNoRegion
        ? [
            ...data,
            {
              id: NO_REGION_ID_SET,
              name: 'No Region Set',
              describe: 'No Region Set',
              location: undefined,
              createdAt: undefined,
              updatedAt: undefined,
              value: NO_REGION_ID_SET,
            },
          ]
        : data,
    [appendNoRegion, data],
  );
};

export const useGetFilteredReferralsList = (isArchived: boolean): any[] => {
  const {
    activeReferralsList,
    archivedReferralsList,
    filters,
    referralStatuses: { archivedStatuses, activeStatuses },
  } = useSelector((state: RootState) => state.referrals);
  const referrals = isArchived ? archivedReferralsList.data : activeReferralsList.data;
  return getFilteredReferrals(
    referrals,
    filters,
    activeStatuses,
    archivedStatuses,
    isArchived ? statuses.ARCHIVED : statuses.ACTIVE,
  );
};

export const useGetInboxUpdateOperations = () => {
  const {
    inbox: {
      received: { results: receivedInboxResults, sortDirection: receivedSortDirection },
      archived: { results: archivedInboxResults, sortDirection: archivedSortDirection },
      filters,
    },
  } = useSelector((state: RootState) => state);
  const [currentTab, setCurrentTab] = useState('');
  const { search: searchParam } = useLocation();
  const dispatch = useDispatch();
  const tabs = useMemo(
    () => ({
      ARCHIVED: 'archived',
      RECEIVED: 'received',
    }),
    [],
  );

  const loadNewInboxItems = useCallback(
    (isInboxReceived: boolean, filters: InboxFilters): void => {
      const fetchInboxItemsWrapper = (
        wrapperFunction: (payload: any, onFinish: () => void, hideLoading: boolean) => void,
        payload: any,
      ): void => {
        dispatch(wrapperFunction(payload, () => null, true));
      };

      fetchInboxItemsWrapper(isInboxReceived ? fetchReceivedInbox : fetchArchivedInbox, {
        ...filters,
        page: 1,
        sortField: 'faxReceivedDate',
        sortDirection: isInboxReceived ? receivedSortDirection.toUpperCase() : archivedSortDirection.toUpperCase(),
      });
    },
    [archivedSortDirection, dispatch, receivedSortDirection],
  );

  const updateInboxCount = useCallback(
    (data: any, isInboxReceived: boolean): void => {
      dispatch(
        updateInboxCountAction(
          {
            ...filters,
            pageSize: 1,
            page: 1,
            sortField: 'faxReceivedDate',
            sortDirection: isInboxReceived ? receivedSortDirection.toUpperCase() : archivedSortDirection.toUpperCase(),
          },
          isInboxReceived,
        ),
      );
    },
    [archivedSortDirection, dispatch, filters, receivedSortDirection],
  );

  const getTotalInboxItemsLoaded = useCallback(
    (isInboxReceived: boolean, receivedInboxResults: InboxItem[], archivedInboxResults: InboxItem[]): number => {
      const result: InboxItem[] = getResultsToDisplayInInboxList(
        isInboxReceived ? receivedInboxResults : archivedInboxResults,
        filters,
      );
      return result.length;
    },
    [filters],
  );

  const onUpdateInboxSuccess = useCallback(
    (data: any): void => {
      const isInboxReceived: boolean = currentTab?.toLowerCase() === tabs.RECEIVED.toLowerCase();
      updateInboxCount(data, isInboxReceived);

      if (getTotalInboxItemsLoaded(isInboxReceived, receivedInboxResults, archivedInboxResults) <= 25) {
        loadNewInboxItems(isInboxReceived, filters);
      }
    },
    [
      archivedInboxResults,
      currentTab,
      filters,
      getTotalInboxItemsLoaded,
      loadNewInboxItems,
      receivedInboxResults,
      tabs.RECEIVED,
      updateInboxCount,
    ],
  );

  useEffect(() => {
    if (searchParam?.toLowerCase().includes(`tab=${tabs.ARCHIVED.toLowerCase()}`)) {
      setCurrentTab(tabs.ARCHIVED);
    } else if (searchParam?.toLocaleLowerCase().includes(`tab=${tabs.RECEIVED.toLowerCase()}`)) {
      setCurrentTab(tabs.RECEIVED);
    }
  }, [searchParam, tabs]);

  return {
    loadNewInboxItems,
    updateInboxCount,
    getTotalInboxItemsLoaded,
    onUpdateInboxSuccess,
  };
};

export const useGetAttachmentOperations = () => {
  const dispatch = useDispatch();

  const publishAttachmentToWeInfuse = (
    referralId?: string,
    attachmentId?: string,
    disableFetchingReferral?: boolean,
    callback?: () => void,
  ) => {
    const onSuccessCallBack = (referralId: string, attachmentId: string) => {
      disableFetchingReferral && callback && callback();

      dispatch(
        !disableFetchingReferral
          ? getOneReferral(referralId, true, () => {
              callback && callback();
              dispatch(setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, false));
            })
          : setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, false),
      );
    };

    if (referralId && attachmentId) {
      dispatch(setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, true));
      dispatch(
        uploadAttachmentToWeInfuseAction(referralId, attachmentId, () => {
          onSuccessCallBack(referralId, attachmentId);
        }),
      );
    }
  };

  return {
    publishAttachmentToWeInfuse,
  };
};

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  return isMounted;
};
