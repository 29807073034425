import axios, { AxiosResponse } from 'axios';

export const getMedicalRequestPreviewApi = async (data: any): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const result = await axios({
    url: `${baseUrl}/faxes/med-rec-req/create`,
    method: 'POST',
    responseType: 'blob',
    data,
  });
  return result;
};

export const getItemReasonsApi = (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.get(`${baseUrl}/med-rec-req-reasons`);
};

export const sendMedicalRecordsFaxApi = async (data: any): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.post(`${baseUrl}/faxes/med-rec-req/send`, data);
};

export const resendMedicalRecordsFaxWithEditsApi = async (data: any): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.post(`${baseUrl}/faxes/med-rec-req/resend`, data);
};

export const resendMedicalRecordsFaxWithoutEditsApi = async (data: any): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { faxId } = data;
  return axios.post(`${baseUrl}/faxes/resend/${faxId}`);
};
