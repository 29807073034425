import React, { useEffect, useState } from 'react';
import { OrderEntryTaskBody } from './OrderEntryTaskBody';
import { HttpOrderEntryTaskApi } from '../api/http-api';
import { OrderEntryTaskPresenter, OrderEntryTaskViewModel } from '../presenter';
import { LinkOrdersDialog } from './LinkOrdersDialog';
import { TaskLogic } from '../../taskTypes';
import { Task } from '../../../../redux/types';
import { useTaskRefresh } from '../../../task-refresh';

export function StatefulOrderEntryTask(task: Task): TaskLogic {
  const taskId = task.id;
  const taskStatus = task.status?.name || '';
  const [view, setView] = useState<OrderEntryTaskViewModel>(OrderEntryTaskPresenter.INITIAL_VIEW_MODEL);
  const refreshTask = useTaskRefresh();
  const [presenter] = useState(
    () =>
      new OrderEntryTaskPresenter(new HttpOrderEntryTaskApi(), taskId, setView, () => refreshTask(task.id), console),
  );
  const isNotApplicable = taskStatus === 'NOT APPLICABLE';
  const isTemporaryPatient = task.referral?.patient?.isTemporary;

  useEffect(() => {
    presenter.display();
  }, [presenter, isNotApplicable, isTemporaryPatient]);

  return {
    taskBody: <OrderEntryTaskBody {...view.body} onStartLink={() => presenter.openDialog()} />,
    statusChangeHook: {
      component: (
        <LinkOrdersDialog
          {...view.dialog}
          onSave={() => presenter.save()}
          onCancel={() => presenter.cancelDialog()}
          onOrderToggle={(orderId) => presenter.toggleOrderSelected(orderId)}
        />
      ),
      allowChange: presenter.validateStatusChange.bind(presenter),
      getStatusOptionDisplayState: presenter.getStatusOptionDisplayState.bind(presenter),
    },
  };
}
