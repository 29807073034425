import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    adornment: {
      width: 32,
      height: 32,
      marginRight: 16,
      paddingLeft: 8,
    },
    root: {
      background: ({ open }: any) => (open ? alpha(theme.palette.common.black, 0.04) : ''),
      // paddingLeft: 16,
      // paddingRight: 16,
      marginRight: 16,
      borderRadius: 4,
      cursor: 'pointer',
    },
    selectRoot: {
      '&:focus': {
        backgroundColor: 'yellow',
      },
    },
    parent: {
      position: 'relative' as any,
      width: '100%',
      cursor: ({ disabled }: any) => (disabled ? 'no-drop' : 'pointer'),
      '&:hover': {
        backgroundColor: ({ disabled }: any) => (disabled ? '' : 'rgba(25, 118, 210, 0.04)'),
      },
    },
    input: {
      lineHeight: '48px !important',
      marginTop: '8px !important',
    },
    parentWithSearch: {
      position: 'relative' as any,
      width: '100%',
      padding: 12,
    },
    label: {
      position: 'absolute' as any,
      left: 56,
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    },
    endAdornment: {
      top: '0px !important',
    },
    boxIcon: {
      width: 32,
      height: 32,
    },
  };
});
