import { Task } from '../../../redux/types';
import { TaskLogic } from '../taskTypes';
import { PriorAuthTaskController } from './prior-auth-task-controller';
import { Typography } from '@mui/material';
import { TaskLine } from '../../../components/TasksList/TaskLine';
import { useTaskRefresh } from '../../task-refresh';
import { PriorAuthDecisionActions } from './react-component/PriorAuthDecisionActions';
import { PriorAuthDecisionDialog } from './react-component/PriorAuthDecisionDialog';

export function PriorAuthTaskType(task: Task, controller: ReturnType<typeof PriorAuthTaskController>): TaskLogic {
  const refreshTask = useTaskRefresh();
  const view = controller(task.id, task.statusId, () => refreshTask(task.id));

  return {
    statusChangeHook: {
      component: <PriorAuthDecisionDialog dialog={view.dialog} actions={view.actions} />,
      allowChange: view.validateStatusChange,
      getStatusOptionDisplayState: () => ({ disabled: Boolean(!view.canChangeStatus), loading: view.isLoading }),
    },
    taskBody: (
      <>
        {view.showDecision && (
          <TaskLine labelText="Decision">
            {view.isLoading ? (
              'Loading...'
            ) : (
              <>
                <Typography style={{ marginLeft: '6px' }} variant="body2">
                  {view.result}
                </Typography>
                <PriorAuthDecisionActions {...view.actions} />
              </>
            )}
          </TaskLine>
        )}
      </>
    ),
    taskTitleIcon: view.showDecision ? (
      <Typography fontSize="10pt" style={{ marginLeft: '8px' }}>
        {view.result}
      </Typography>
    ) : null,
  };
}

export type PriorAuthDecisionActionsView = ReturnType<ReturnType<typeof PriorAuthTaskController>>['actions'];
