import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { alpha } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    attachmentItem: {
      background: '#FAFAFA',
      padding: 5,
      borderRadius: 4,
    },
    selectStyles: {
      height: 32,
      width: '100%',
      borderRadius: 4,
      lineHeight: '32px',
      color: 'rgba(0,0,0,0.87)',
      textAlign: 'left',
      padding: '0 16px',
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    markAsLabel: {
      fontSize: '14px !important',
    },
    menuMarkAsLabel: {
      fontSize: '16px !important',
    },
    editableAttachmentItem: {
      background: '#FAFAFA',
      height: 60,
      padding: 5,
      borderRadius: 4,
      borderLeft: '4px solid #009688',
      marginBottom: 8,
    },
    referralLabel: {
      fontSize: `10px !important`,
    },
    menu: {
      '& div': {
        // this is just an example, you can use vw, etc.
        minWidth: 280,
      },
    },
    listedAttachment: {
      borderRadius: 4,
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    select: {
      '&:focus': {
        background: 'transparent !important',
      },
      color: 'rgba(0,0,0,0.38) !important',
    },
    paper: {
      width: '440px',
    },
    viewInboxItemContainer: {
      textDecoration: 'none',
      color: 'black',
    },
    viewInboxItemMenuItem: {
      marginBottom: '8px !important',
    },
    openInNewTabIcon: {
      marginRight: 28,
      fontSize: '22px !important',
    },
    input: {
      color: 'rgba(0, 0, 0, 0.87) !important',
      WebkitTextFillColor: '',
    },
  };
});

export const styles: any = {
  chip: {
    backgroundColor: '#E8F1FB',
    textTransform: 'none',
    color: '#1976D2',
    fontSize: 12,
    height: 24,
    borderRadius: '4px',
    padding: '4px 8px',
    marginBottom: 4,
  },
};
