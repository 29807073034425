import axios, { AxiosResponse } from 'axios';

export const getPatients = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/patients`);
};

export interface AddPatientParams {
  weinfuse_id?: string;
  identifier?: string;
  isTemporary?: boolean;
  address_id?: number;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string | Date;
  middle_initial?: string;
  sex?: 'Male' | 'Female' | 'Unknown' | '';
  ethnicity?: string;
  race?: string;
  preferred_language?: string;
  email?: string;
  mobile_phone?: string;
  work_phone?: string;
  home_phone?: string;
  address_line_one?: string;
  address_line_two?: string;
  address_city?: string;
  address_state?: string;
  address_zip_code?: string;
  address_zip_code_five?: string;
  updated_at?: string;
  import_created_datetime?: string;
  import_updated_datetime?: string;
  address_created_time?: string;
  address_updated_time?: string;
}
export const addPatientApi = (data: AddPatientParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.post(`${baseUrl}/patients`, data);
};

export interface SearchPatientsParams {
  search?: string;
  phoneTerm?: string;
  dateOfBirth?: string;
  isTemporary?: boolean;
}
export const searchPatients = (data: SearchPatientsParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.post(`${baseUrl}/patients/search`, data);
};

export interface EditPatientParams extends AddPatientParams {
  id?: string;
}
export const editPatientApi = (data: EditPatientParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { id, ...requiredParams } = data;
  return axios.patch(`${baseUrl}/patients/${id}`, requiredParams);
};

export const deletePatientApi = (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return axios.delete(`${baseUrl}/patients/${id}`);
};
