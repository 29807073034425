import React, { FC, useEffect, useState } from 'react';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import { useDispatch, useSelector } from 'react-redux';
import { getInsurancesAction } from '../../redux/actions/insurancesAction';
import { Region, RootState } from '../../redux/types';
import insuranceImage from '../../images/insurance.svg';

interface Props extends Partial<SelectMenuProps> {
  initialValue?: any;
  onChange: (item: any) => void;
  style?: React.CSSProperties;
  labelFor?: string;
  icon?: React.ReactNode;
  valueIcon?: React.ReactNode;
  showClearIcon?: boolean;
  showExpandMore?: boolean;
  referralRegion?: Region;
}

export const defaultInsuranceDisplayValue = {
  id: 'Other',
  name: 'Other',
  label: 'Other',
  regionId: '',
};

const InsuranceSelect: FC<Props> = (props) => {
  const { initialValue, onChange, labelFor, referralRegion, ...restProps } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInsurancesAction());
  }, [dispatch]);

  const insurances = useSelector((state: RootState) => state.insurances.data);

  const [value, setValue] = useState(initialValue);
  const [insuranceOptions, setInsuranceOptions] = useState([...insurances, defaultInsuranceDisplayValue]);

  //filter by region
  useEffect(() => {
    const filteredInsurances = insurances.filter((insurance) => insurance.regionId === referralRegion?.id);
    setInsuranceOptions([...filteredInsurances, defaultInsuranceDisplayValue]);
  }, [insurances, referralRegion?.id]);

  const handleChange = (item: any) => {
    onChange(item ? item : { id: null });
    setValue(item);
  };

  // set value displayed to none when region changes
  useEffect(() => {
    if (value?.regionId && referralRegion?.id !== value?.regionId) {
      setValue(undefined);
    }
  }, [referralRegion, value?.regionId]);

  const isSelectedOption = (option: any): boolean => option?.id === value?.id;

  return (
    <SelectMenu
      value={value?.label ? value : initialValue}
      onChange={handleChange}
      showClearIcon
      hideAddnew
      showExpandMore
      searchLoading={false}
      valueIcon={<img src={insuranceImage} alt="ellipse" />}
      icon={<img src={insuranceImage} alt="ellipse" />}
      options={insuranceOptions.map((option) => ({
        ...option,
        disabled: isSelectedOption(option),
        showDoneIcon: isSelectedOption(option),
        label: option.name,
        searchString: option.name,
      }))}
      label="Select"
      style={{ paddingLeft: 8 }}
      disabled={!referralRegion?.id}
      {...restProps}
    />
  );
};

export default InsuranceSelect;
