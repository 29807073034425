import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';
import { statusDefaults } from '../../globalUtils/utils';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    progress: {
      background: alpha(theme.palette.common.black, 0.04) + ' !important',
      height: 8 + 'px !important',
      borderRadius: 16,
    },
    disabled: {
      // background: 'red !important',
      // opacity: '1 !important',
    },
    banner: {
      padding: 10,
      borderRadius: 4,
      border: `1px solid #0288D1`,
      color: '#0288D1',
    },
    bar: {
      borderRadius: 16,
      backgroundColor: '#2E7D32 !important',
    },
    colorSecondary: {
      // backgroundColor: theme.palette.success.main
    },
    summary: {
      cursor: 'pointer',
      '&:hover': {
        // background: ({ open}: any) => !open && 'red',
      },
      alignItems: 'center',
    },
    content: {
      width: '100%',
    },
    hovered: {
      padding: '0 !important',
      '&:hover': {
        background: ({ open }: any) => !open && alpha(theme.palette.common.black, 0.04),
        padding: '0 !important',
      },
      '&.Mui-disabled': {
        opacity: '1 !important',
        cursor: 'no-drop',
      },
    },

    newTask: {
      background: '#E8F5E9 !important',
      borderRadius: '8px !important',
      padding: '0 !important',
      '&:hover': {
        background: ({ open }: any) => !open && alpha(theme.palette.common.black, 0.04),
        padding: '0 !important',
      },
      '&.Mui-disabled': {
        opacity: '1 !important',
        cursor: 'no-drop',
        padding: '0 !important',
      },
    },
    taskItem: {
      width: '100%',
      border: ({ open }: any) => open && `1px solid #e0e0e0`,
    },

    disabledTitle: {
      color: `${alpha(theme.palette.common.black, 0.38)} !important`,
      textTransform: 'capitalize',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    disabledChipTitle: {
      color: ({ statusName, disabled }: any) =>
        `${
          statusName?.toUpperCase() === statusDefaults.COMPLETED
            ? '#FFFFFF !important'
            : disabled
            ? alpha(theme.palette.common.black, 0.38)
            : 'inherit'
        }`,
      textTransform: 'capitalize',
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      minHeight: '32px !important',
    },
    title: {
      color: 'inherit',
      textTransform: 'capitalize',
      paddingRight: '15px !important',
      paddingLeft: '15px !important',
      minHeight: '32px !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tooltip: {
      background: '#616161 !important',
      opacity: '0.9 !important',
      color: '#FFFFFF !important',
    },
    addTasksWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    newTaskButton: {
      color: '#000000 !important',
      textTransform: 'none',
      padding: '16px !important',
      opacity: 0.6,
    },
    paper: {
      minWidth: '738px',
      minHeight: '308px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      width: '40%',
      height: 56,
    },
    labelInput: {
      display: 'flex',
      width: '60%',
      alignItems: 'center',
      height: 48,
      boxSizing: 'border-box',
    },
    waitText: {
      fontSize: '14px !important',
      color: '#9E9E9E',
      whiteSpace: 'nowrap',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    headerWrapper: {
      padding: 4,
    },
    boxWrapper: {
      width: 315,
      padding: 8,
      background: '#FFFFFF',
      fontSize: '14px !important',
      borderRadius: 6,
    },
    headerText: {
      color: '#000000',
      fontSize: '14px !important',
      lineHeight: '20px !important',
      fontWeight: '600 !important',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    headerLink: {
      fontSize: '14px !important',
      lineHeight: '20px !important',
      fontWeight: '400 !important',
      textDecoration: 'none',
    },
    primaryText: { color: '#000000', opacity: 0.6, fontSize: '14px !important' },
    secondaryText: { color: '#000000', opacity: 0.4, fontSize: '14px !important' },
    tooltipStyles: {
      fontSize: '14px !important',
    },
    addIconButton: {
      '&:hover': {
        background: '#0000000F !important',
      },
    },
    disableRipple: {
      position: 'inherit',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    darkPrimary: {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
    titleText: {
      display: '-webkit-box !important',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});
