import {
  UPDATE_TASKLIST_FILTERS,
  GET_TASKS_COUNT,
  GET_OPEN_TASKS_LOADING,
  GET_ARCHIVED_TASKS_LOADING,
  GET_OPEN_TASKS_SUCCESS,
  GET_ARCHIVED_TASKS_SUCCESS,
  GET_OPEN_TASKS_ERROR,
  GET_ARCHIVED_TASKS_ERROR,
  SET_OPEN_TASKS_GLOBAL_LOADING,
  SET_ARCHIVED_TASKS_GLOBAL_LOADING,
  SET_SPECIFIC_TASK_UPDATE_LOADING,
} from '../constants/tasksTypes';

export const getOpenTasksLoading = (loading: boolean) => ({
  type: GET_OPEN_TASKS_LOADING,
  payload: loading,
});

export const getArchivedTasksLoading = (loading: boolean) => ({
  type: GET_ARCHIVED_TASKS_LOADING,
  payload: loading,
});

export const getOpenTasksSuccess = (payload: any) => ({
  type: GET_OPEN_TASKS_SUCCESS,
  payload,
});

export const getArchivedTasksSuccess = (payload: any) => ({
  type: GET_ARCHIVED_TASKS_SUCCESS,
  payload,
});

export const getOpenTasksError = (payload: any) => ({
  type: GET_OPEN_TASKS_ERROR,
  payload,
});

export const getArchivedTasksError = (payload: any) => ({
  type: GET_ARCHIVED_TASKS_ERROR,
  payload,
});

export const updateTaskListFilters = (payload: any) => ({
  type: UPDATE_TASKLIST_FILTERS,
  payload,
});

export const getTasksCount = (payload: { openTotalCount: number; archivedTotalCount: number }) => ({
  type: GET_TASKS_COUNT,
  payload,
});

export const setOpenTasksGlobalLoading = (isLoading: boolean) => ({
  type: SET_OPEN_TASKS_GLOBAL_LOADING,
  payload: isLoading,
});

export const setArchivedGlobalTasksLoading = (isLoading: boolean) => ({
  type: SET_ARCHIVED_TASKS_GLOBAL_LOADING,
  payload: isLoading,
});

export const setSpecificTaskUpdateLoading = (payload: { taskId: string; isLoading: boolean }) => ({
  type: SET_SPECIFIC_TASK_UPDATE_LOADING,
  payload,
});
