import { io } from 'socket.io-client';

const baseUrl = process.env.REACT_APP_WEB_SOCKET_URL as string;
const socketBasePath = process.env.REACT_APP_API_URL as string;
const socket = (path: string) => {
  const accessToken = localStorage.getItem('token');
  const args = {
    path: socketBasePath + path,
    transports: ['websocket', 'polling', 'flashsocket'],
    auth: {
      accessToken,
    },
  };
  return io(baseUrl, args).on('connect_error', (error) => console.error(error, args));
};

export default socket;
