import { BaseAction, ReferralsStore, Task } from '../types';
import {
  CREATE_REFERRAL,
  CREATE_REFERRAL_SUCCESS,
  CREATE_REFERRAL_ERROR,
  ADD_ATTACHMENTS,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_ERROR,
  CREATE_DRAFT_REFERRAL,
  CREATE_DRAFT_REFERRAL_ERROR,
  CREATE_DRAFT_REFERRAL_SUCCESS,
  SET_ACTIVE_REFERRAL,
  FETCH_ACTIVE_REFERRAL_LOADING,
  FETCH_ACTIVE_REFERRAL_FAILURE,
  GET_REFERRAL_CATEGORIES,
  GET_REFERRAL_CATEGORIES_SUCCESS,
  GET_REFERRAL_CATEGORIES_ERROR,
  GET_REFERRAL_STATUSES_LOADING,
  GET_REFERRAL_STATUSES_SUCCESS,
  GET_REFERRAL_STATUSES_ERROR,
  EDIT_REFERRAL_SUCCESS,
  EDIT_REFERRAL_LOADING,
  EDIT_REFERRAL_ERROR,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS,
  MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR,
  CLEAR_ALL_REFERRAL_LISTS,
  UPDATE_REFERRAL_FILTERS,
  EDIT_ATTACHMENT_SUCCESS,
  CLEAR_ATTACHMENTS_STATE,
  DELETE_REFERRAL_LOADING,
  DELETE_REFERRAL_SUCCESS,
  UPDATE_ACTIVE_REFERRAL_TASKS,
  UPDATE_REFERRAL_PROVIDER_SUCCESS,
  REMOVE_ATTACHMENT,
  REMOVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_ERROR,
  CLEAR_ACTIVE_REFERRAL,
  REMOVE_REFERRAL_ITEM_FROM_LIST,
  GET_REFERRALS_COUNT,
  EDIT_PATIENT_SUCCESS,
  GET_ACTIVE_REFERRALS_LOADING,
  GET_ARCHIVED_REFERRALS_LOADING,
  GET_ACTIVE_REFERRALS_SUCCESS,
  GET_ARCHIVED_REFERRALS_SUCCESS,
  GET_ACTIVE_REFERRALS_ERROR,
  GET_ARCHIVED_REFERRALS_ERROR,
  SET_ARCHIVED_REFERRALS_GLOBAL_LOADING,
  SET_ACTIVE_REFERRALS_GLOBAL_LOADING,
  CLEAR_REFERRAL_FILTERS,
  SET_ALL_REFERRALS_GLOBAL_LOADING,
  GET_ALL_REFERRALS_LOADING,
  GET_ALL_REFERRALS_SUCCESS,
  GET_ALL_REFERRALS_ERROR,
  UPDATE_ATTACHMENT_FAX,
} from '../constants/referralTypes';
import { ADD_NEW_TASK_SUCCESS } from '../constants/tasksTypes';
import { activeReferralStatuses, archivedReferralStatuses, ReferralEditFields } from '../../globalUtils/helpers';
import { FAX_ACK_SUCCESS, RESEND_FAX_ACK_SUCCESS, SEND_FAX_ACK_SUCCESS } from '../constants/faxTypes';
import { MAX_FILE_SIZE } from '../../globalUtils/constants';

const addToArrayUniqFaxId = (array: any, newObject: { faxes: any[] } & any): any[] => {
  const newFaxId = newObject.faxId;
  if (!newFaxId) {
    return [...array, newObject];
  }

  const ids = array.map(({ faxes }: any) => faxes?.[0]?.id);

  if (!ids.includes(newFaxId)) {
    return [...array, newObject];
  }
  return array.map((item: any) => (item?.faxes?.[0]?.id === newFaxId ? newObject : item));
};

export const initialState: ReferralsStore = {
  data: [],
  error: null,
  success: false,
  attachments: [],
  attachmentsUpload: {},
  draftReferrals: {
    loading: false,
    data: [],
    error: null,
    success: false,
  },
  referralCategories: {
    loading: false,
    data: [],
    error: null,
    success: false,
  },
  referralStatuses: {
    loading: false,
    data: [],
    error: null,
    success: false,
    archivedStatuses: [],
    activeStatuses: [],
  },
  activeReferral: {
    data: {
      attachments: [],
      outgoingAttachments: [],
      comments: [],
      tasks: [],
    },
    loading: false,
    error: {},
  },
  editReferral: {
    loading: false,
    error: null,
    success: false,
    data: {
      attachments: [],
    },
    field: ReferralEditFields.ANY,
    referralId: '',
  },
  archivedReferralsList: {
    loading: false,
    data: [],
    error: null,
    offset: 0,
    pageSize: 26,
    page: 1,
    hasMore: true,
    globalLoading: false,
  },
  activeReferralsList: {
    loading: false,
    data: [],
    error: null,
    offset: 0,
    pageSize: 26,
    page: 1,
    hasMore: true,
    globalLoading: false,
  },
  allReferrals: {
    loading: false,
    data: [],
    error: null,
    offset: 0,
    pageSize: 26,
    page: 1,
    hasMore: true,
    globalLoading: false,
  },
  filters: {
    assignedUsers: [],
    statuses: [],
    search: '',
    sortDirection: 'DESC',
    sortField: 'createdAt',
    referralTypes: [],
    referralSubTypes: [],
    medications: [],
    providers: [],
    regions: [],
    clinics: [],
    intakeSpecialists: [],
    fromNextFollowupDate: undefined,
    toNextFollowupDate: undefined,
    fromCreatedAt: undefined,
    toCreatedAt: undefined,
    dateOfBirth: undefined,
    primaryInsuranceCarrier: '',
    flags: [],
  },
  deleteReferral: {
    loading: false,
    error: undefined,
  },
  removeAttachment: {
    loading: false,
    success: false,
    error: null,
    data: undefined,
  },
  markAttachmentAsSeenOnReferral: {
    loading: false,
    success: false,
    data: {},
    error: null,
  },
  activeTotalCount: 0,
  archivedTotalCount: 0,
  urgentTotalCount: 0,
};

const reducer = (state: ReferralsStore = initialState, action: BaseAction): ReferralsStore => {
  switch (action.type) {
    case CREATE_REFERRAL:
      return {
        ...state,
        loading: action.payload,
        success: false,
        draftReferrals: {
          ...state.draftReferrals,
          success: false,
        },
      };
    case CREATE_REFERRAL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        attachmentsUpload: {},
        attachments: [],
      };
    case CREATE_REFERRAL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };

    case ADD_ATTACHMENTS:
      return {
        ...state,
        attachmentsUpload: {
          ...state.attachmentsUpload,
          [action.payload?.fileName]: {
            loading: true,
            success: false,
            outgoing: action.payload?.outgoing,
            percentComplete: action.payload.percentComplete,
          },
        },
      };
    case ADD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        archivedReferralsList: addAttachment(state.archivedReferralsList),
        activeReferralsList: addAttachment(state.activeReferralsList),
        activeReferral:
          state?.activeReferral?.data?.id === action?.payload?.referralId
            ? {
                ...state.activeReferral,
                // not all cases have activeReferral e.g from inbox
                data: {
                  ...state?.activeReferral?.data,
                  ...(!action.payload.outgoing && {
                    attachments: [...state.activeReferral?.data?.attachments, action.payload],
                  }),
                  ...(action.payload.outgoing && {
                    outgoingAttachments: addToArrayUniqFaxId(
                      state.activeReferral?.data?.outgoingAttachments || [],
                      action.payload,
                    ),
                  }),
                },
              }
            : state.activeReferral,
        attachmentsUpload: {
          ...state.attachmentsUpload,
          [action.payload?.originalFileName]: {
            loading: false,
            success: true,
            error: null,
            data: action.payload,
          },
        },
        // if already has referral, do not append to this attachments object
        ...(!action?.payload?.referralId && { attachments: [...state.attachments, action.payload] }),
      };

    case UPDATE_ATTACHMENT_FAX:
      const updatedOutgoingAttachments = state.activeReferral?.data?.outgoingAttachments?.map((attachment) => {
        return attachment.faxes[0]?.id === action.payload.id ? { ...attachment, faxes: [action.payload] } : attachment;
      });
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state?.activeReferral?.data,
            outgoingAttachments: updatedOutgoingAttachments,
          },
        },
      };

    case ADD_NEW_TASK_SUCCESS: {
      const newTask: Task = { ...action.payload, isNew: true };

      let currentActiveReferral: any = state.activeReferral;

      if (action.payload?.referral?.id === state.activeReferral.data?.id) {
        // after Fax Ack, we receive a new 'Followup with Provider for Additional Documents' task and not an update with same id
        const filteredTasks = state?.activeReferral?.data?.tasks?.filter((task) => task.title !== newTask.title);
        currentActiveReferral = {
          ...state.activeReferral,
          // not all cases have activeReferral e.g from inbox
          data: {
            ...state?.activeReferral?.data,
            tasks: [...filteredTasks, newTask],
            comments: [...state?.activeReferral?.data?.comments, ...action.payload.comments],
          },
        };
      }

      return {
        ...state,
        activeReferral: currentActiveReferral,
      };
    }
    case CLEAR_ATTACHMENTS_STATE: {
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state?.activeReferral?.data,
            attachments: [],
          },
        },
        attachments: [],
      };
    }
    case ADD_ATTACHMENTS_ERROR:
      return {
        ...state,
        attachmentsUpload: {
          ...state.attachmentsUpload,
          [action.payload?.fileName]: {
            outgoing: action.payload?.outgoing,
            loading: false,
            success: false,
            error: true,
            errorMessage:
              action.payload.status === 413
                ? `File is too large, maximum should be ${MAX_FILE_SIZE.label}`
                : action.payload.errorMessage,
          },
        },
      };

    case SET_ACTIVE_REFERRAL:
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: { ...state.activeReferral.data, ...action.payload },
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList.data.map((referral: any) =>
            referral?.id === action.payload.id ? { ...referral, ...action.payload } : referral,
          ),
        },
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList.data.map((referral: any) =>
            referral?.id === action.payload.id ? { ...referral, ...action.payload } : referral,
          ),
        },
      };
    case CLEAR_ACTIVE_REFERRAL:
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state.activeReferral.data,
            attachments: [],
          },
        },
      };

    case REMOVE_ATTACHMENT:
      return {
        ...state,
        removeAttachment: {
          ...state.removeAttachment,
          loading: true,
          success: false,
          error: null,
        },
      };
    case REMOVE_ATTACHMENT_SUCCESS:
      const remainderIncomingAttachments = state.activeReferral.data.attachments.filter(
        (attachment: any) => attachment.id !== action.payload.id,
      );
      const remainderOutcomingAttachments = state.activeReferral.data.outgoingAttachments.filter(
        (attachment: any) => attachment.id !== action.payload.id,
      );
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state.activeReferral.data,
            attachments: remainderIncomingAttachments,
            outgoingAttachments: remainderOutcomingAttachments,
          },
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList?.data?.map((item: any) =>
            item.id === state.activeReferral?.data?.id
              ? {
                  ...item,
                  unseenAttachmentsCount: item?.unseenAttachmentsCount + (action.payload?.seen ? 0 : -1),
                }
              : item,
          ),
        },
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList?.data?.map((item: any) =>
            item.id === state.activeReferral?.data?.id
              ? {
                  ...item,
                  unseenAttachmentsCount: item?.unseenAttachmentsCount + (action.payload?.seen ? 0 : -1),
                }
              : item,
          ),
        },
        removeAttachment: {
          ...state.removeAttachment,
          loading: false,
          success: false,
          data: action.payload,
          error: null,
        },
      };

    case REMOVE_ATTACHMENT_ERROR:
      return {
        ...state,
        removeAttachment: {
          ...state.removeAttachment,
          loading: false,
          success: false,
          error: action.payload,
        },
      };

    case UPDATE_REFERRAL_PROVIDER_SUCCESS:
      const editedProvider =
        state.activeReferral.data?.provider?.id === action.payload?.id
          ? action.payload
          : state.activeReferral.data?.provider;
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state.activeReferral.data,
            provider: editedProvider,
          },
        },
      };
    case EDIT_PATIENT_SUCCESS:
      const editedPatient =
        state.activeReferral.data?.patient?.id === action.payload?.id
          ? action.payload
          : state.activeReferral.data?.patient;

      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state.activeReferral.data,
            patient: editedPatient,
          },
        },
      };

    case FETCH_ACTIVE_REFERRAL_LOADING:
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          loading: action.payload,
        },
      };
    case FETCH_ACTIVE_REFERRAL_FAILURE:
      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          error: action.payload,
        },
      };
    case CREATE_DRAFT_REFERRAL:
      return {
        ...state,
        success: false,
        draftReferrals: {
          ...state.draftReferrals,
          loading: action.payload,
          success: false,
        },
      };
    case CREATE_DRAFT_REFERRAL_SUCCESS:
      return {
        ...state,
        draftReferrals: {
          ...state.draftReferrals,
          data: action.payload,
          loading: false,
          success: true,
        },
        attachmentsUpload: {},
        attachments: [],
      };
    case CREATE_DRAFT_REFERRAL_ERROR:
      return {
        ...state,
        draftReferrals: {
          ...state.draftReferrals,
          error: action.payload,
          loading: false,
          success: false,
        },
      };
    case GET_ACTIVE_REFERRALS_LOADING:
      return {
        ...state,
        activeReferralsList: {
          ...state.activeReferralsList,
          loading: action.payload,
        },
      };
    case GET_ARCHIVED_REFERRALS_LOADING:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          loading: action.payload,
        },
      };
    case GET_ALL_REFERRALS_LOADING:
      return {
        ...state,
        allReferrals: {
          ...state.allReferrals,
          loading: action.payload,
        },
      };
    case CLEAR_ALL_REFERRAL_LISTS:
      return {
        ...state,
        archivedReferralsList: initialState.archivedReferralsList,
        activeReferralsList: initialState.activeReferralsList,
        allReferrals: initialState.allReferrals,
      };
    case GET_ACTIVE_REFERRALS_SUCCESS:
      return {
        ...state,
        activeReferralsList: {
          ...state.activeReferralsList,
          loading: false,
          ...action.payload,
          data:
            action.payload?.page === 1
              ? action.payload.data
              : [...state.activeReferralsList.data, ...action.payload.data],
        },
      };
    case GET_ARCHIVED_REFERRALS_SUCCESS:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          loading: false,
          ...action.payload,
          data:
            action.payload?.page === 1
              ? action.payload.data
              : [...state.archivedReferralsList.data, ...action.payload.data],
        },
      };
    case GET_ALL_REFERRALS_SUCCESS:
      return {
        ...state,
        allReferrals: {
          ...state.allReferrals,
          loading: false,
          ...action.payload,
          data: action.payload.page === 1 ? action.payload.data : [...state.allReferrals.data, ...action.payload.data],
        },
      };
    case REMOVE_REFERRAL_ITEM_FROM_LIST:
      return {
        ...state,
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList.data.filter(({ id }: any) => id !== action.payload.id),
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList.data.filter(({ id }: any) => id !== action.payload.id),
        },
      };

    case SEND_FAX_ACK_SUCCESS:
    case RESEND_FAX_ACK_SUCCESS:
    case FAX_ACK_SUCCESS:
      let currentActiveReferral: any = state.activeReferral;

      const updatedOutgoingAttachmentsWithFax = action.payload?.referral?.outgoingAttachments?.map(
        (attachment: any) => {
          return attachment.faxes[0]?.id === action.payload.fax.id
            ? { ...attachment, faxes: [{ ...attachment.faxes[0], ...action?.payload?.fax }] }
            : attachment;
        },
      );

      if (action.payload?.referral?.id === state.activeReferral.data?.id) {
        currentActiveReferral = {
          ...state.activeReferral,
          ...action.payload?.referral, // TODO: do we really need the below assignments? Check if the returned referral has all data we need
          data: {
            ...state.activeReferral.data,
            tasks: state.activeReferral.data?.tasks?.map((item: any) => {
              return item.id === action.payload.id ? action.payload : item;
            }),
            attachments: action.payload?.referral?.attachments,
            ...(action.payload?.referral?.outgoingAttachments && {
              outgoingAttachments: updatedOutgoingAttachmentsWithFax,
            }),
          },
        };
      }

      return {
        ...state,
        activeReferral: currentActiveReferral,
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList?.data?.map((item: any) =>
            item.id === currentActiveReferral?.data?.id ? { ...item, ...currentActiveReferral?.data } : item,
          ),
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList?.data?.map((item: any) =>
            item.id === currentActiveReferral?.data?.id ? { ...item, ...currentActiveReferral?.data } : item,
          ),
        },
      };
    case UPDATE_ACTIVE_REFERRAL_TASKS:
      const activeReferral: any = {
        ...state.activeReferral,
        data: {
          ...state.activeReferral.data,
          tasks: state.activeReferral.data?.tasks?.map((item: any) => {
            return item.id === action.payload.id ? action.payload : item;
          }),
        },
      };

      return {
        ...state,
        activeReferral,
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList?.data?.map((item: any) =>
            item.id === activeReferral?.data?.id ? { ...item, ...activeReferral?.data } : item,
          ),
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList?.data?.map((item: any) =>
            item.id === activeReferral?.data?.id ? { ...item, ...activeReferral?.data } : item,
          ),
        },
      };
    case GET_ACTIVE_REFERRALS_ERROR:
      return {
        ...state,
        activeReferralsList: {
          ...state.activeReferralsList,
          loading: false,
          error: action.payload,
        },
      };
    case GET_ARCHIVED_REFERRALS_ERROR:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          loading: false,
          error: action.payload,
        },
      };
    case GET_ALL_REFERRALS_ERROR:
      return {
        ...state,
        allReferrals: {
          ...state.allReferrals,
          loading: false,
          error: action.payload,
        },
      };
    case GET_REFERRAL_CATEGORIES:
      return {
        ...state,
        referralCategories: {
          ...state.referralCategories,
          loading: true,
        },
      };
    case GET_REFERRAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        referralCategories: {
          ...state.referralCategories,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_REFERRAL_CATEGORIES_ERROR:
      return {
        ...state,
        referralCategories: {
          ...state.referralCategories,
          loading: false,
          error: action.payload,
        },
      };
    case GET_REFERRAL_STATUSES_LOADING:
      return {
        ...state,
        referralStatuses: {
          ...state.referralStatuses,
          loading: action.payload,
        },
      };
    case GET_REFERRAL_STATUSES_SUCCESS:
      return {
        ...state,
        referralStatuses: {
          ...state.referralStatuses,
          loading: false,
          data: action.payload,
          error: null,
          archivedStatuses: action.payload?.filter((status: any) =>
            archivedReferralStatuses.includes(status?.title?.toLowerCase()),
          ),
          activeStatuses: action.payload?.filter((status: any) =>
            activeReferralStatuses.includes(status?.title?.toLowerCase()),
          ),
        },
      };
    case GET_REFERRAL_STATUSES_ERROR:
      return {
        ...state,
        referralStatuses: {
          ...state.referralStatuses,
          loading: false,
          error: action.payload,
        },
      };

    case EDIT_REFERRAL_LOADING:
      return {
        ...state,
        editReferral: {
          ...state.editReferral,
          loading: action.payload.isLoading,
          error: null,
          success: false,
          field: action.payload.field,
          referralId: action.payload.referralId,
        },
      };

    case EDIT_REFERRAL_SUCCESS:
      return {
        ...state,
        editReferral: {
          ...state.editReferral,
          data: action.payload,
          loading: false,
          error: null,
          success: true,
        },
        activeReferral: {
          ...state.activeReferral,
          data:
            state.activeReferral.data?.id === action.payload?.id
              ? {
                  ...state.activeReferral.data,
                  ...action.payload,
                }
              : state.activeReferral.data,
        },
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList.data.map((referral: any) => {
            if (referral.id === action.payload.id) {
              return {
                ...referral,
                ...action.payload,
              };
            }

            return referral;
          }),
        },
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList.data.map((referral: any) => {
            if (referral.id === action.payload.id) {
              return {
                ...referral,
                ...action.payload,
              };
            }

            return referral;
          }),
        },
      };

    case EDIT_ATTACHMENT_SUCCESS:
      const filteredAttachments = state.activeReferral.data.attachments.map((attachmentItem: any) => {
        return attachmentItem.id === action.payload.id ? action.payload : attachmentItem;
      });

      return {
        ...state,
        activeReferral: {
          ...state.activeReferral,
          data: {
            ...state.activeReferral.data,
            attachments: filteredAttachments,
          },
        },
      };

    case EDIT_REFERRAL_ERROR:
      return {
        ...state,
        editReferral: {
          ...state.editReferral,
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case UPDATE_REFERRAL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        activeTotalCount: 0,
        archivedTotalCount: 0,
        urgentTotalCount: 0,
      };
    case CLEAR_REFERRAL_FILTERS:
      return {
        ...state,
        filters: initialState.filters,
        activeTotalCount: 0,
        archivedTotalCount: 0,
        urgentTotalCount: 0,
      };
    case DELETE_REFERRAL_LOADING:
      return {
        ...state,
        deleteReferral: {
          ...state.deleteReferral,
          loading: action.payload,
          error: undefined,
        },
      };
    case DELETE_REFERRAL_SUCCESS:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList.data.filter((referral: any) => referral?.id !== action.payload.id),
        },
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList.data.filter((referral: any) => referral?.id !== action.payload.id),
        },
        activeTotalCount: state.activeTotalCount - 1,
        // update urgent?
      };
    case MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL:
      return {
        ...state,
        markAttachmentAsSeenOnReferral: {
          ...state.markAttachmentAsSeenOnReferral,
          loading: true,
          error: null,
          success: false,
        },
      };
    case MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_SUCCESS:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          data: state.archivedReferralsList?.data?.map((item: any) =>
            item.id === state.activeReferral?.data?.id ? action.payload : item,
          ),
        },
        activeReferralsList: {
          ...state.activeReferralsList,
          data: state.activeReferralsList?.data?.map((item: any) =>
            item.id === state.activeReferral?.data?.id ? action.payload : item,
          ),
        },
        markAttachmentAsSeenOnReferral: {
          ...state.markAttachmentAsSeenOnReferral,
          data: action.payload,
          loading: false,
          error: null,
          success: true,
        },
        activeReferral: {
          ...state.activeReferral,
          data: action.payload?.id === state.activeReferral?.data?.id ? action.payload : state.activeReferral?.data,
        },
      };
    case MARK_ATTACHMENT_AS_SEEN_ON_REFERRAL_ERROR:
      return {
        ...state,
        markAttachmentAsSeenOnReferral: {
          ...state.markAttachmentAsSeenOnReferral,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case GET_REFERRALS_COUNT:
      return {
        ...state,
        activeTotalCount: action.payload?.activeTotalCount,
        archivedTotalCount: action.payload?.archivedTotalCount,
        urgentTotalCount: action.payload?.urgentTotalCount,
      };
    case SET_ARCHIVED_REFERRALS_GLOBAL_LOADING:
      return {
        ...state,
        archivedReferralsList: {
          ...state.archivedReferralsList,
          globalLoading: action.payload,
        },
      };
    case SET_ACTIVE_REFERRALS_GLOBAL_LOADING:
      return {
        ...state,
        activeReferralsList: {
          ...state.activeReferralsList,
          globalLoading: action.payload,
        },
      };
    case SET_ALL_REFERRALS_GLOBAL_LOADING:
      return {
        ...state,
        allReferrals: {
          ...state.allReferrals,
          globalLoading: action.payload,
        },
      };
    default:
      return state;
  }

  function addAttachment(referralList: any) {
    return {
      ...referralList,
      data: referralList.data.map((referral: any) =>
        referral.id === action.payload.referralId
          ? { ...referral, attachments: [...referral.attachments, action.payload] }
          : referral,
      ),
    };
  }
};

export default reducer;
