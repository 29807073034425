import { Box, IconButton, Input, InputProps } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import search from '../../images/searchIcon.svg';
import searchClear from '../../images/clearSearch.svg';
import { useStyles } from './SearchStyles';
import blueSearchIcon from '../../images/blueSearchIcon.svg';

interface Props extends InputProps {
  icon?: ReactNode;
  showClearSearchIcon?: boolean;
  onClearSearch?: () => void;
}
export const Search: FC<Props> = ({
  onChange,
  placeholder,
  icon,
  showClearSearchIcon,
  onClearSearch,
  ...restProps
}) => {
  const classes = useStyles();
  const [isfocussed, setFocused] = useState<boolean>(false);

  return (
    <Input
      placeholder={placeholder}
      onChange={onChange}
      inputProps={{ 'aria-label': 'search' }}
      startAdornment={
        !icon ? (
          <img alt="" src={isfocussed ? blueSearchIcon : search} className={classes.searchIcon} />
        ) : (
          <Box display="flex" justifyContent="center" className={classes.customIconContainer}>
            {icon}
          </Box>
        )
      }
      disableUnderline
      className={classes.searchInput}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      endAdornment={
        <Box className={classes.adornmentContainer}>
          {showClearSearchIcon && (
            <IconButton onClick={onClearSearch} data-id="clearSearch">
              <img alt="" src={searchClear} />
            </IconButton>
          )}
        </Box>
      }
      {...restProps}
    />
  );
};
export default Search;
