import { useEffect, useState } from 'react';
import { TextMessage, TextMessageDeliveryStatusCategory } from '../text-message';
import { Patient } from '../../../redux/types';
import { MessageServer } from '../message-server/message-server';
import { userFriendlyTimestamp } from '../../../globalUtils/utils';
import { MessageTemplate, SendSmsController } from '../send-sms-controller';

export function PatientMessagesController(
  messageServer: MessageServer,
  useSendSmsController: ReturnType<typeof SendSmsController>,
) {
  return function usePatientMessagesController(patient: Patient, referralId?: string) {
    const [state, setState] = useState({
      messages: [] as TextMessage[],
      messagesLoading: true,
      templates: [] as MessageTemplate[],
    });
    const sendSms = useSendSmsController({
      onSendSuccess: appendMessage,
      templates: state.templates,
      phoneNumber: patient.mobile_phone || patient.home_phone,
    });

    useEffect(() => {
      let isMounted = true;

      messageServer
        .getPatientHistory(patient)
        .then((messages) => messages.sort((a, b) => b.dateSent.getTime() - a.dateSent.getTime()))
        .then((messages) => {
          if (isMounted) {
            setState((state) => ({ ...state, messages, messagesLoading: false }));
          }
        });

      return () => {
        isMounted = false;
      };
    }, [patient]);

    useEffect(() => {
      messageServer
        .getTemplates({ referralId })
        .then((templates) => setState((state) => ({ ...state, templates })))
        .catch(console.error);
    }, [referralId]);

    return {
      usable: patientHasPhone(),
      warningMessage: !patientHasPhone() ? `No phone number registered for ${patient.full_name}` : '',
      sendSms,
      messageView: {
        loading: state.messagesLoading,
        messages: state.messages.map((message) => ({
          ...message,
          body: htmlEntities(message.body),
          dateSent: userFriendlyTimestamp(message.dateSent),
          status: {
            text: getStatusText(message.status.category),
            color: getStatusColor(message.status.category),
            tooltip: message.status.message,
          },
        })),
      },
    };

    function getStatusColor(status: TextMessageDeliveryStatusCategory) {
      return {
        [TextMessageDeliveryStatusCategory.INFO]: 'default',
        [TextMessageDeliveryStatusCategory.ERROR]: 'error',
        [TextMessageDeliveryStatusCategory.SUCCESS]: 'success',
      }[status];
    }

    function getStatusText(status: TextMessageDeliveryStatusCategory) {
      return {
        [TextMessageDeliveryStatusCategory.INFO]: 'Queued',
        [TextMessageDeliveryStatusCategory.ERROR]: 'Failed',
        [TextMessageDeliveryStatusCategory.SUCCESS]: 'Sent',
      }[status];
    }

    function appendMessage(message: TextMessage) {
      setState((state) => ({
        ...state,
        messages: [message, ...state.messages],
      }));
    }

    function patientHasPhone() {
      return Boolean(patient.mobile_phone || patient.home_phone);
    }

    function htmlEntities(str: string) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }
  };
}

export type PatientMessagesView = Omit<TextMessage, 'dateSent'> & {
  dateSent: string;
};
