import { TaskLogic } from '../../taskTypes';
import { Task } from '../../../../redux/types';
import { DeclineReferralTaskBody } from './decline-referral-task-body';
import { FaxStatusIcon } from '../../../../components/Fax/FaxStatusIcon';
import { IconButton, Tooltip } from '@mui/material';

export function DeclineReferralTaskType(task: Task): TaskLogic {
  return {
    taskBody: <DeclineReferralTaskBody {...task} />,
    statusChangeHook: {
      allowChange: () => true,
      component: null,
      getStatusOptionDisplayState: () => ({ disabled: false }),
    },
    taskTitleIcon: task.fax && (
      <Tooltip title={task.fax.status?.name || ''}>
        <IconButton>
          <FaxStatusIcon faxStatus={task.fax.status} />
        </IconButton>
      </Tooltip>
    ),
  };
}
