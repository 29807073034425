import { FC } from 'react';
import { lazyWithRetry } from '../globalUtils/helpers';
export interface RoutesInterface {
  path: string;
  Component: FC;
  isAuthenticated: boolean;
}

const NotFound = lazyWithRetry(() => import('../views/404/NotFound'));
const Login = lazyWithRetry(() => import('../views/Login/Login'));
const Inbox = lazyWithRetry(() => import('../views/Inbox/Inbox'));
const Referrals = lazyWithRetry(() => import('../views/Referrals/Referrals'));
const Tasks = lazyWithRetry(() => import('../views/Tasks/Tasks'));
const ReleaseNotes = lazyWithRetry(() => import('../components/Header/ReleaseNotes/ReleaseNotes'));
const Omnichannel = lazyWithRetry(() =>
  import('../features/Omnichannel/view/OmnichannelPage').then((x) => ({ default: x.OmnichannelPage })),
);
const FeatureFlags = lazyWithRetry(() =>
  import('../features/FeatureFlags/view/FeatureFlagPage').then((x) => ({ default: x.FeatureFlagPage })),
);
const HtmlView = lazyWithRetry(() => import('../views/HtmlAttachment/HtmlAttachmentView'));

const TeamsNotificationRedirectPage = lazyWithRetry(
  () => import('../views/TeamsNotificationsRedirectPage/TeamsNotificationRedirectPage'),
);

const routes: RoutesInterface[] = [
  // All paths go here
  {
    path: '/',
    Component: Login,
    isAuthenticated: false,
  },
  {
    path: '/inbox',
    Component: Inbox,
    isAuthenticated: true,
  },
  {
    path: '/referrals',
    Component: Referrals,
    isAuthenticated: true,
  },
  {
    path: '/tasks',
    Component: Tasks,
    isAuthenticated: true,
  },
  {
    path: '/omnichannel',
    Component: Omnichannel,
    isAuthenticated: true,
  },
  {
    path: '/bot-notification/read/:notificationId',
    Component: TeamsNotificationRedirectPage,
    isAuthenticated: true,
  },
  {
    path: '/view-html-attachment/:attachmentId',
    Component: HtmlView,
    isAuthenticated: true,
  },
  {
    path: '/release-notes',
    Component: ReleaseNotes,
    isAuthenticated: true,
  },
  {
    path: '/feature-flags',
    Component: FeatureFlags,
    isAuthenticated: true,
  },
  {
    path: '*',
    Component: NotFound,
    isAuthenticated: true,
  },
];

export default routes;
