import { Button } from '@mui/material';
import { PriorAuthDecisionActionsView } from '../prior-auth-task-type';

export function PriorAuthDecisionActions(actions: PriorAuthDecisionActionsView) {
  return (
    <>
      {actions.buttons.map((button) => (
        <Button key={button.label} disabled={actions.processing} onClick={button.click}>
          {button.label}
        </Button>
      ))}
    </>
  );
}
