import axios, { AxiosResponse } from 'axios';

export const getMedications = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/medications
  `);
};

export const getDrugTiers = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/drug-tiers
  `);
};

export const getTaskTemplates = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/task-templates
  `);
};
