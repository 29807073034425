import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

interface Props {
  dialogProps: DialogProps;
  dialogContentProps?: DialogContentProps;
  dialogActionsProps?: DialogActionsProps;
  contentText: string;
  actionButtonText: string;
  titleText: string;
  cancelButtonText?: string;
  onActionButtonClick: () => void;
  onCancelButtonClick: () => void;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  const {
    titleText,
    dialogProps,
    dialogContentProps,
    dialogActionsProps,
    actionButtonText,
    cancelButtonText,
    onActionButtonClick,
    onCancelButtonClick,
    contentText,
  } = props;

  return (
    <Dialog {...dialogProps} data-id="confirmationModal">
      {titleText && <DialogTitle>{titleText}</DialogTitle>}
      <DialogContent style={{ width: 444 }} {...dialogContentProps}>
        {contentText && <DialogContentText id="alert-dialog-description">{contentText}</DialogContentText>}
      </DialogContent>
      <DialogActions {...dialogActionsProps}>
        <Button onClick={onCancelButtonClick}>{cancelButtonText}</Button>
        <Button onClick={onActionButtonClick} variant="contained">
          {actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
