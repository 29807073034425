import { usePatientMessagesController } from '../../index';
import { SendSmsComponent } from '../../view/SendSmsComponent';
import { Patient } from '../../../../redux/types';
import { Stack } from '@mui/material';
import { PatientMessageHistoryComponent } from './PatientMessageHistoryComponent';

export function PatientMessagesComponent({ patient, referralId }: { patient: Patient; referralId?: string }) {
  const view = usePatientMessagesController(patient, referralId);
  return (
    <Stack direction="column" gap="16px">
      {view.usable
        ? [
            <SendSmsComponent key="send" view={view.sendSms} />,
            <PatientMessageHistoryComponent key="history" {...view.messageView} />,
          ]
        : view.warningMessage}
    </Stack>
  );
}
