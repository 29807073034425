export const SEND_FAX_ACK: string = 'SEND_FAX_ACK';
export const SEND_FAX_ACK_SUCCESS: string = 'SEND_FAX_ACK_SUCCESS';
export const SEND_FAX_ACK_ERROR: string = 'SEND_FAX_ACK_ERROR';

export const RESEND_FAX_ACKNOWLEDGEMENT_LOADING: string = 'RESEND_FAX_ACKNOWLEDGEMENT_LOADING';
export const RESEND_FAX_ACK_SUCCESS: string = 'RESEND_FAX_ACK_SUCCESS';
export const RESEND_FAX_ACK_ERROR: string = 'RESEND_FAX_ACK_ERROR';

export const GET_FAX_ACK_PREVIEW: string = 'GET_FAX_ACK_PREVIEW';
export const GET_FAX_ACK_PREVIEW_SUCCESS: string = 'GET_FAX_ACK_PREVIEW_SUCCESS';
export const GET_FAX_ACK_PREVIEW_ERROR: string = 'GET_FAX_ACK_PREVIEW_ERROR';

export const FAX_ACK_SUCCESS = 'FAX_ACK_SUCCESS';

export const FETCH_FAX_TYPES = 'FETCH_FAX_TYPES';
export const FETCH_FAX_TYPES_SUCCESS = 'FETCH_FAX_TYPES_SUCCESS';
export const FETCH_FAX_TYPES_ERROR = 'FETCH_FAX_TYPES_ERROR';

export const CREATE_MEDICAL_REQUEST: string = 'CREATE_MEDICAL_REQUEST';
export const CREATE_MEDICAL_REQUEST_SUCCESS: string = 'CREATE_MEDICAL_REQUEST_SUCCESS';
export const CREATE_MEDICAL_REQUEST_ERROR: string = 'CREATE_MEDICAL_REQUEST_ERROR';

export const MEDICAL_REQUEST_OPTIONS: string = 'MEDICAL_REQUEST_OPTIONS';
export const MEDICAL_REQUEST_OPTIONS_SUCCESS: string = 'MEDICAL_REQUEST_OPTIONS_SUCCESS';
export const MEDICAL_REQUEST_OPTIONS_ERROR: string = 'MEDICAL_REQUEST_OPTIONS_ERROR';

export const SEND_MEDICAL_REQUEST_FAX: string = 'SEND_MEDICAL_FAX_REQUEST';
export const SEND_MEDICAL_REQUEST_FAX_SUCCESS: string = 'SEND_MEDICAL_FAX_REQUEST_SUCCESS';
export const SEND_MEDICAL_REQUEST_FAX_ERROR: string = 'SEND_MEDICAL_FAX_REQUEST_ERROR';
