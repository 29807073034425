import { BaseAction } from '../types';
import {
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_ERROR,
  SEARCH_PATIENTS,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_ERROR,
  CLEAR_PATIENTS_FROM_SEARCH,
  ADD_PATIENTS,
  ADD_PATIENTS_SUCCESS,
  ADD_PATIENTS_ERROR,
  EDIT_PATIENT,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_ERROR,
} from '../constants/referralTypes';

const initialState: any = {
  loading: false,
  data: [],
  patientsFromSearch: [],
  error: null,
  addPatient: {
    loading: false,
    data: {},
    error: null,
  },
  editedPatient: {
    loading: false,
    data: {},
    error: null,
  },
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case ADD_PATIENTS:
      return {
        ...state,
        addPatient: {
          ...state.addPatient,
          loading: true,
        },
      };
    case ADD_PATIENTS_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        addPatient: {
          ...state.addPatient,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_PATIENTS_ERROR:
      return {
        ...state,
        loading: false,
        addPatient: {
          ...state.addPatient,
          loading: false,
          error: action.payload,
        },
      };
    case EDIT_PATIENT:
      return {
        ...state,
        editedPatient: {
          ...state.editedPatient,
          loading: true,
        },
      };
    case EDIT_PATIENT_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        editedPatient: {
          ...state.editedPatient,
          loading: false,
          data: action.payload,
        },
        // update patients from search?
      };
    case EDIT_PATIENT_ERROR:
      return {
        ...state,
        editedPatient: {
          ...state.editedPatient,
          loading: false,
          error: action.payload,
        },
      };
    case GET_PATIENTS:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_PATIENTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SEARCH_PATIENTS:
      return {
        ...state,
        loading: action.payload,
        // clear patientsFromSearch:
        ...(action.payload && { patientsFromSearch: [] }),
      };
    case SEARCH_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsFromSearch: action.payload,
      };
    case SEARCH_PATIENTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_PATIENTS_FROM_SEARCH:
      return {
        ...state,
        patientsFromSearch: [],
      };
    default:
      return state;
  }
};

export default reducer;
