import axios, { AxiosResponse } from 'axios';

export interface HomeCityParams {
  search: string;
}

export const getHomeCity = async (params: HomeCityParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { search } = params;
  return await axios.get(`${baseUrl}/location/city?search=${search}`);
};
