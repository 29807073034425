import { Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Referral, RootState } from '../../redux/types';
import AddAttachmentsDropZone from '../AddAttachmentsModal/AddAttachmentsDropZone';
import { useStyles } from './sendFaxStyles';

interface Props {
  open: boolean;
  onCancel: () => void;
  handleUpload: (files: File[], values: any) => void;
  loading?: boolean;
  referral?: Referral;
  onSelectPublishFileToEMR?: (file: any, isSelected: boolean) => void;
}

const SendFaxAcknowledgement: FC<Props> = (props) => {
  const { open, onCancel, handleUpload, loading, referral, onSelectPublishFileToEMR } = props;
  const classes = useStyles();
  const [files, setFiles] = useState<any[]>([]);
  const [attachmentValues, setAttachmentValues] = useState([]);

  const { attachments } = useSelector((state: RootState) => state);
  const categories = attachments?.attachmentCategories?.data;

  const formatSelectedToStr = (selected: string[]) => {
    const selectedCategoriesNamesArray = selected.map((categoryId) => {
      return categories.find((category) => category.id === categoryId)?.name;
    });

    return selectedCategoriesNamesArray;
  };

  const handleRemoveFile = (fileName: string) => {
    const filteredFiles = files.filter((file: any) => file.name !== fileName);
    // remove from backend
    setFiles(filteredFiles);
  };

  const defaultCategory = categories.filter(({ name }) => name === 'Fax Acknowledgement').map(({ id }) => id);

  const handleClose = () => {
    onCancel();
    setFiles([]);
  };

  return (
    <>
      <AddAttachmentsDropZone
        open={open}
        dialogTitle={
          <Typography classes={{ root: classes.header }} className={classes.header}>
            {`Add Fax Acknowledgment attachment to Referral ${referral?.patient?.full_name || ''}`}
          </Typography>
        }
        handleClose={handleClose}
        setAttachmentValues={setAttachmentValues}
        handleDrop={(file) => setFiles((f) => [...f, ...file])}
        handleRemoveFile={handleRemoveFile}
        handleUpload={() => handleUpload(files, attachmentValues)}
        categories={categories}
        selectedFiles={files}
        initialCategories={defaultCategory}
        formatSelectedToStr={formatSelectedToStr}
        loading={loading}
        categoryRequired
        hasWeInfusePatient={!referral?.patient?.isTemporary}
        onSelectPublishToEMR={onSelectPublishFileToEMR}
      >
        <Typography style={{ marginBottom: 20 }} variant="body1">
          Upload the Fax Acknowledgement PDF file to mark this task as completed.
        </Typography>
      </AddAttachmentsDropZone>
    </>
  );
};

export default SendFaxAcknowledgement;
