import { Box, Typography } from '@mui/material';

export function TaskLine(props: { labelText: string; children: string | JSX.Element | JSX.Element[] | null | false }) {
  return (
    <Box alignItems="center" style={{ minHeight: 48 }} display="flex" {...props}>
      <Box flex={1} alignItems="center" style={{ width: '50%' }}>
        <Typography variant="body2" color="textSecondary" fontWeight={500} fontSize={14}>
          {props.labelText}
        </Typography>
      </Box>
      <Box flex={1} display="flex" alignItems="center" style={{ width: '50%', paddingLeft: '8px' }}>
        {props.children}
      </Box>
    </Box>
  );
}
