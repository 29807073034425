import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    icon: {
      fontSize: 20,
      marginRight: 34,
      fontWeight: 'bold',
    },
    popper: {
      background: 'transparent',
      marginBottom: '-12px !important',
    },
    successTooltip: {
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '14px',
      padding: '5px 8px',
    },
  };
});
