import React from 'react';
import { useStyles } from './referralViewStyles';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

interface Props {
  onClose: () => void;
}

const DeletedReferralView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { onClose } = props;

  return (
    <Box display="flex" className={classes.root} flexDirection="column" style={{ height: '100%' }}>
      <Box className={classes.deletedDraftViewContainer}>
        <Box className={classes.sticky} style={{ height: '100%' }}>
          <Box display="flex" flexDirection="column" className={classes.deletedViewDetailsContainer}>
            <Box className={classes.deletedViewCloseIconContainer}>
              <IconButton onClick={onClose}>
                <CloseIcon className={classes.deletedViewCloseIcon} />
              </IconButton>
            </Box>
            <Box className={classes.deletedViewTitle}>
              <Typography fontSize={20} fontWeight={500}>
                Referral does not exist
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.deletedViewTextContainer}
            >
              <Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <SentimentVeryDissatisfiedIcon color="inherit" fontSize="large" />
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography>This draft referral was deleted</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeletedReferralView;
