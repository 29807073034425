import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './EmptyResultsStyles';

export interface Props {
  icon: string;
  information: string;
}

const EmptyResults: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { icon, information } = props;

  return (
    <Grid
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className={classes.emptyStateContainer}
    >
      <Grid display="flex" justifyContent="center" className={classes.iconContainer}>
        <img src={icon} alt="" />
      </Grid>
      <Grid display="flex" className={classes.informationContainer} justifyContent="center">
        <Typography className={classes.informationText}>{information}</Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyResults;
