import {
  Box,
  BoxProps,
  TableSortLabel,
  TableSortLabelProps,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import defaultFilter from '../../images/Filter.svg';
import filterPrimary from '../../images/filterPrimary.svg';
import { useStyles } from './TableHeaderStyles';

interface Props {
  filterIconType?: 'primary' | 'default';
  filtersCount?: number;
  displaySort?: boolean;
  displayFilter?: boolean;
  onClickSort?: (event: React.MouseEvent<any>) => void;
  onClickFilter?: (event: React.MouseEvent<any>) => void;
  title?: string;
  sortLabelTooltipText?: string;
  titleProps?: TypographyProps;
  hideFilter?: boolean;
  hideSort?: boolean;
  sortProperties?: TableSortLabelProps;
  filtersSectionProps?: BoxProps;
}

const TableHeader: React.FC<Props> = (props) => {
  const {
    filterIconType,
    filtersCount,
    displaySort,
    displayFilter,
    onClickFilter,
    onClickSort,
    title,
    titleProps,
    hideSort,
    hideFilter,
    sortProperties,
    filtersSectionProps,
    sortLabelTooltipText,
  } = props;
  const classes = useStyles();
  const [hasCounts, setHasCounts] = useState<boolean>(false);
  const [sortHovered, setSortHovered] = useState<boolean>(false);
  const [filterHovered, setFilterHovered] = useState<boolean>(false);

  const onMouseEnter = (): void => {
    setFilterHovered(true);
    setSortHovered(true);
  };

  const onMouseLeave = (): void => {
    setFilterHovered(false);
    setSortHovered(false);
  };

  useEffect(() => {
    setHasCounts(filtersCount && filtersCount >= 1 ? true : false);
  }, [filtersCount]);

  const shouldDisplayOption = (hideOption?: boolean, optionHovered?: boolean, displayOption?: boolean): boolean =>
    !hideOption && (optionHovered || displayOption) ? true : false;

  const handlePropertyClick =
    (
      onclickFunction?: (e: React.MouseEvent<HTMLElement>) => void,
      hideOption?: boolean,
      optionHovered?: boolean,
      displayOption?: boolean,
    ) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      if (onclickFunction && shouldDisplayOption(hideOption, optionHovered, displayOption)) {
        onclickFunction(e);
      }
    };

  return (
    <>
      {title && (
        <Typography onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...titleProps}>
          {title}
        </Typography>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.filtersSection}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...filtersSectionProps}
      >
        {shouldDisplayOption(hideSort, sortHovered, displaySort) && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.sortContainer}
            onClick={handlePropertyClick(onClickSort, hideSort, sortHovered, displaySort)}
          >
            <Tooltip title={sortLabelTooltipText || ''}>
              <TableSortLabel active={true} {...sortProperties} />
            </Tooltip>
          </Box>
        )}
        <Box
          display="flex"
          className={classes.filtersContainer}
          justifyContent="flex-end"
          alignItems="center"
          style={{
            cursor: shouldDisplayOption(hideFilter, filterHovered, displayFilter) ? 'pointer' : 'default',
          }}
          onClick={handlePropertyClick(onClickFilter, hideFilter, filterHovered, displayFilter)}
        >
          {hasCounts && shouldDisplayOption(hideFilter, filterHovered, displayFilter) && (
            <Typography className={classes.counter} color={filterIconType}>
              {filtersCount}
            </Typography>
          )}
          {shouldDisplayOption(hideFilter, filterHovered, displayFilter) && (
            <img
              alt="funnel"
              src={filterIconType === 'primary' ? filterPrimary : defaultFilter}
              className={classes.filterImage}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default TableHeader;
