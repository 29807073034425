import { Box, Chip, Typography } from '@mui/material';
import { useStyles } from './FilterStyles';

export function FilterIndicatorGroup(props: {
  name: string;
  filters: { label: string; value: string; onRemove: () => void }[];
}) {
  const classes = useStyles();
  return (
    <Box>
      <Typography style={{ marginTop: 16, marginBottom: 16 }} color="textSecondary" variant="button">
        {props.name}
      </Typography>
      <Box>
        {props.filters.map((filter) => {
          return (
            <Chip onDelete={filter.onRemove} classes={{ root: classes.chip }} label={filter.label} key={filter.value} />
          );
        })}
      </Box>
    </Box>
  );
}
