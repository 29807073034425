import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useStyles } from './AddAttachmentsDropZoneStyles';
import AttachmentsDropZone from './AttachmentsDropZone';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';
import { AttachmentCategoriesParams } from '../../redux/actions/attachmentActions';

interface Props {
  open: boolean;
  handleClose: () => void;
  setAttachmentValues: (attachmentValues: any) => void;
  handleDrop: (acceptedFiles: any, error: any) => void;
  handleRemoveFile: (fileName: string) => void;
  handleUpload: () => void;
  selectedFiles?: any;
  categories: AttachmentCategoriesParams[];
  formatSelectedToStr: (selected: string[]) => string[] | any;
  dialogTitle?: ReactNode;
  dropZoneTitle?: string;
  initialCategories?: string[];
  loading?: boolean;
  categoryRequired?: boolean;
  hasWeInfusePatient?: boolean;
  hidePublishToWeInfuse?: boolean;
  onSelectPublishToEMR?: (file: any, isSelected: boolean) => void;
}
const AddAttachmentsDropZone: FC<Props> = (props) => {
  const {
    open,
    handleClose,
    setAttachmentValues,
    handleDrop,
    selectedFiles: rawSelectedFiles,
    handleRemoveFile,
    handleUpload,
    categories,
    formatSelectedToStr,
    dialogTitle,
    initialCategories,
    loading,
    categoryRequired,
    hasWeInfusePatient,
    onSelectPublishToEMR,
    hidePublishToWeInfuse,
    dropZoneTitle,
  } = props;

  const selectedFiles = rawSelectedFiles.map((rawFile: any) => {
    const filename = rawFile?.name?.substring(0, rawFile?.name?.lastIndexOf('.')) || rawFile;
    return {
      id: filename,
      rawFile: rawFile,
      filename,
      createdAt: new Date(),
      extension: rawFile?.name?.split('.').pop(),
      url: '',
    };
  });

  const classes = useStyles();
  return (
    <Dialog classes={{ paper: classes.paper }} fullWidth maxWidth="md" open={open} data-id="modalAttachFiles">
      <DialogTitle data-id="title" className={classes.dialogTitle}>
        {dialogTitle || <Typography>Attach Files</Typography>}
      </DialogTitle>
      <DialogContent data-id="content">
        {props.children}
        <Typography variant="subtitle2" style={{ marginBottom: 16 }}>
          {dropZoneTitle ? dropZoneTitle : 'Attachments'}
        </Typography>
        <AttachmentsDropZone
          setAttachmentValues={setAttachmentValues}
          handleDrop={handleDrop}
          selectedFiles={selectedFiles}
          handleRemoveFile={handleRemoveFile}
          formatSelectedToStr={formatSelectedToStr}
          categories={categories.sort(sortByNameKeyCompareFunction)}
          initialCategories={initialCategories}
          categoryRequired={categoryRequired}
          hasWeInfusePatient={hasWeInfusePatient}
          onSelectPublishToEMR={onSelectPublishToEMR}
          hidePublishToWeInfuse={hidePublishToWeInfuse}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          disabled={selectedFiles.length < 1 || loading}
          onClick={handleUpload}
          data-id="btnAttachFiles"
        >
          {selectedFiles.length ? `Attach (${selectedFiles.length}) Files` : 'Attach Files'}
          {loading && <CircularProgress size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttachmentsDropZone;
