import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMedicalRecordPreviewAction,
  resendMedicalRecordsRequestFax,
  sendMedicalRecordsRequestFax,
  serializeFormData,
} from '../../redux/actions/outgoingFilesAction';
import MedicalRecordsRequestForm from '../MedicalRecordsRequest/MedicalRecordsRequestForm';
import MedicalRecordsRequestItems from '../MedicalRecordsRequest/MedicalRequestItems';
import PDFPreviewModal from '../PDFPreview/PDFPreviewModal';
import SelectMenu from '../SelectMenu/SelectMenu';
import { RequestedItem, MedicalRequestFormValues } from './ReferralViewTypes';
//
import { RootState } from '../../redux/types';
import { stringArrayToValidCommaSentence } from '../../globalUtils/utils';
import templateSvg from '../../images/template.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  open: boolean;
  handleClose: () => void;
  referral: any;
  formData?: any;
  setOpenAddAttachmentsZone: (open: boolean) => void;
  faxModals: { name: string; open: () => void }[];
}

const validateRequestItem = (item: RequestedItem): boolean => {
  return !!(item.itemRequested && item.reasons?.length);
};

const validateRequestedItems = (items: RequestedItem[]): { isValid: boolean; validationMsg: string } => {
  return {
    isValid: items.reduce((a: boolean, b: RequestedItem) => a && validateRequestItem(b), true) && !!items.length,
    validationMsg: 'Item requested and Select reason(s)',
  };
};

const validateMedicalRequestForm = (
  formValues: MedicalRequestFormValues,
): { isValid: boolean; validationMsg: string[] } => {
  const { providerName, faxNumber, attemptsComments, requestAttempt } = formValues;
  const validFax = faxNumber?.replace(/-/g, '').length === 10;
  const isRequestAttemptsValid =
    (requestAttempt === 'Other:' && attemptsComments?.trim()) || requestAttempt !== 'Other:';

  const requestAttemptsMsg = !requestAttempt
    ? 'Request number'
    : (requestAttempt === 'Other:' && !attemptsComments?.trim() && 'Request Comments') || '';
  const providerNameMsg = (!providerName && 'Provider') || '';
  const validFaxMsg = (!validFax && 'Fax') || '';

  const isValid = !!(providerName && validFax && requestAttempt && isRequestAttemptsValid);
  return { isValid, validationMsg: [providerNameMsg, validFaxMsg, requestAttemptsMsg] };
};

const getMedicalIsDisabled = (activeStep: number, formValues: MedicalRequestFormValues): boolean => {
  switch (activeStep) {
    case 1:
      return false;
    case 2:
      return !validateMedicalRequestForm(formValues).isValid;
    case 3:
      return !validateRequestedItems(formValues.requestedItems || []).isValid;
    default:
      return false;
  }
};

const OutgoingFilesModal = (props: Props) => {
  const { open, handleClose, referral, setOpenAddAttachmentsZone, formData } = props;
  const theme = useTheme();
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState<boolean>(false);
  const { data: faxTypes } = useSelector((state: RootState) => state.faxStore.faxTypes);
  const sendMedicalRecordRequestFax = useSelector((state: RootState) => state.faxStore.sendMedicalRecordRequestFax);
  const [pdfPreview, setPdfPreview] = useState();

  const dispatch = useDispatch();

  const medicalRecordsInitialValues: Partial<MedicalRequestFormValues> =
    formData.faxId &&
    formData.provider_name &&
    serializeFormData({
      ...formData,
      referralId: referral?.id,
    });

  const medicalRequestInitialValues: MedicalRequestFormValues = {
    providerName: `${referral?.provider?.fullName}${
      referral?.provider?.credentials ? ', ' + referral?.provider?.credentials : ''
    }`,
    faxNumber: referral.providerFax || referral.provider?.officeFaxNumber,
    coverPageComments: 'See attached for Medical Records Request',
    requestAttempt: '',
    attemptsComments: '',
    requestedItems: [],
    referralId: referral?.id,
    ...medicalRecordsInitialValues,
    faxId: formData.faxId,
  };

  const [formValues, setFormValues] = useState<MedicalRequestFormValues>(medicalRequestInitialValues);
  const previewContentLoading = useSelector((state: RootState) => state.faxStore.createMedicalRequestItems.loading);

  const [activeStep, setActiveStep] = useState(1);

  const options = faxTypes.map((option: any) => ({
    ...option,
    searchString: option.name,
    value: option.id,
    label: option.name,
  }));

  const [value, setValue] = useState<any>(options.find(({ name }) => name === formData.formType));

  const isMedicalRequest = value?.name === 'Medical Records Request';

  const staticOptions = [
    getStaticOption('Upload Previously Sent Files'),
    ...props.faxModals.map((faxModal) => getStaticOption(faxModal.name)),
  ];

  function getStaticOption(name: string) {
    return {
      searchString: name,
      name: name,
      value: name,
      id: name,
      label: name,
    };
  }

  const dialogTitle = value?.name ? `Send Files: ${value?.name}` : 'Send Files';

  const onMedicalRequestSend = () => {
    if (activeStep === 3) {
      if (formData.faxId) {
        dispatch(resendMedicalRecordsRequestFax(formValues, medicalRecordsInitialValues, handleClose));
      } else {
        dispatch(sendMedicalRecordsRequestFax(formValues, handleClose));
      }
      return;
    }
    setActiveStep((v) => v + 1);
  };

  const openPreviewModal = () => {
    const onSuccess = (data: any) => {
      setPdfPreviewOpen(true);
      setPdfPreview(data);
    };
    const onError = (data: any) => {};
    dispatch(getMedicalRecordPreviewAction(formValues, onSuccess, onError));
  };

  const closePreviewModal = () => {
    setPdfPreviewOpen(false);
  };

  const onClickSend = () => {
    if (value?.name === 'Upload Previously Sent Files') {
      setOpenAddAttachmentsZone(true);
      return;
    }
    const faxModal = props.faxModals.find((x) => x.name === value?.name);
    if (faxModal) {
      open(faxModal.open);
    }
    handleClose();

    function open(openFn: () => any) {
      // wait a bit so closing Outgoing Files modal doesn't steal focus from new modal
      setTimeout(openFn, 300);
    }
  };
  const medicalRecordsHeader = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>{dialogTitle}</Box>
      {activeStep > 2 && (
        <Button disabled={getMedicalIsDisabled(3, formValues) || previewContentLoading} onClick={openPreviewModal}>
          Preview Content {previewContentLoading && <CircularProgress size={12} />}
        </Button>
      )}
    </Box>
  );

  const medicalRecordsFooter = (
    <Box display="flex">
      {activeStep > 1 && (
        <Button disabled={sendMedicalRecordRequestFax.loading} onClick={() => setActiveStep(activeStep - 1)}>
          Previous
        </Button>
      )}
      <Tooltip
        placement="bottom"
        title={
          (getMedicalIsDisabled(activeStep, formValues) &&
            `Please enter ${
              stringArrayToValidCommaSentence(validateMedicalRequestForm(formValues).validationMsg) ||
              validateRequestedItems(formValues.requestedItems || []).validationMsg
            } to proceed`) ||
          ''
        }
      >
        <span>
          <Button
            disabled={getMedicalIsDisabled(activeStep, formValues) || sendMedicalRecordRequestFax.loading}
            variant="contained"
            onClick={onMedicalRequestSend}
          >
            {activeStep < 3 ? 'Next' : 'Send'}
            {sendMedicalRecordRequestFax.loading && <CircularProgress size={12} />}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
  return (
    <>
      <PDFPreviewModal
        open={pdfPreviewOpen}
        handleClosePreview={closePreviewModal}
        pdfToPreview={pdfPreview}
        title={isMedicalRequest ? 'Medical Records Request Preview' : ''}
      />

      <Dialog fullWidth open={open}>
        <DialogTitle>{isMedicalRequest ? medicalRecordsHeader : dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent>
          {activeStep === 1 && (
            <Box style={{ minWidth: 550 }}>
              <Box alignItems="center" display="flex" justifyContent="space-between">
                <Typography width={220} color="textSecondary" variant="body2">
                  Select Type*
                </Typography>
                <Box flex={1}>
                  <SelectMenu
                    options={[...staticOptions, ...options]}
                    icon={<img src={templateSvg} alt="templateSvg" />}
                    valueIcon={<img src={templateSvg} alt="templateSvg" />}
                    value={value}
                    label={'Select Type'}
                    labelStyles={{ color: theme.palette.text.secondary, fontSize: 14 }}
                    onChange={(val) => setValue(val)}
                    expandMoreIcon={<ExpandMoreIcon style={{ color: '#1976D2', fontSize: 26, paddingRight: 10 }} />}
                    hideAddnew
                    showExpandMore
                  />
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === 2 && (
            <MedicalRecordsRequestForm
              values={formValues}
              onChange={(values) => setFormValues((v) => ({ ...v, ...values }))}
            />
          )}
          {activeStep === 3 && (
            <MedicalRecordsRequestItems
              values={formValues.requestedItems}
              onChange={(values) => setFormValues((v) => ({ ...v, requestedItems: values }))}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button disabled={sendMedicalRecordRequestFax.loading} onClick={handleClose}>
              Cancel
            </Button>
            {isMedicalRequest && medicalRecordsFooter}
            {!isMedicalRequest && (
              <Tooltip title={!value ? 'Please select a Fax type to proceed' : ''}>
                <span>
                  <Button variant="contained" disabled={!value} onClick={onClickSend}>
                    Next
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OutgoingFilesModal;
