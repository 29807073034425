import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const inboxStatusConstants = {
  UNASSIGNED: 'UNASSIGNED',
  OPEN: 'OPEN',
  ARCHIVED: 'ARCHIVED',
  DUPLICATE: 'DUPLICATE',
  FORWARDED: 'FORWARDED',
  SPAM: 'SPAM',
  FAILED_FAX: 'FAILED FAX',
  COMPLETE: 'COMPLETE',
};

export const statusOptions = [
  {
    label: 'Open',
    value: inboxStatusConstants.OPEN,
    color: 'default',
    icon: <FiberManualRecordIcon style={{ color: '#42A5F5' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.FAILED_FAX,
    label: 'Failed Fax',
    color: 'warning',
    icon: <FiberManualRecordIcon style={{ color: '#FB8C00' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.SPAM,
    label: 'Spam',
    color: 'error',
    icon: <FiberManualRecordIcon style={{ color: 'red' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.COMPLETE,
    label: 'Complete',
    color: 'success',
    icon: <CheckCircleIcon style={{ color: '#66BB6A', width: 22, height: 22 }} fontSize="large" />,
  },
  {
    label: 'Forwarded',
    value: inboxStatusConstants.FORWARDED,
    color: 'default',
    icon: <FiberManualRecordIcon style={{ color: '#BDBDBD' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.DUPLICATE,
    label: 'Duplicate',
    color: 'default',
    icon: <FiberManualRecordIcon style={{ color: '#403294' }} fontSize="large" />,
  },
];

export const statusOptionsForForwarded = [
  {
    label: 'Open',
    value: inboxStatusConstants.OPEN,
    color: 'default',
    icon: <FiberManualRecordIcon style={{ color: '#42A5F5' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.FAILED_FAX,
    label: 'Failed Fax',
    color: 'warning',
    icon: <FiberManualRecordIcon style={{ color: '#FB8C00' }} fontSize="large" />,
  },
  {
    value: 'SPAM',
    label: 'Spam',
    color: 'error',
    icon: <FiberManualRecordIcon style={{ color: 'red' }} fontSize="large" />,
  },
  {
    value: inboxStatusConstants.DUPLICATE,
    label: 'Duplicate',
    color: 'default',
    icon: <FiberManualRecordIcon style={{ color: '#403294' }} fontSize="large" />,
  },
];

export const NO_REGION_ID_SET: string | null = null;

export const referralStatusesOptions = {
  open: 'Open',
  draft: 'Draft',
  notCompleted: 'Not Completed',
  discarded: 'Discarded',
  onHold: 'On Hold',
  scheduled: 'Scheduled',
  readyToSchedule: 'Ready to Schedule',
};

export const referralCategoriesOptions = {
  active: 'Active',
  scheduled: 'Scheduled',
  notCompleted: 'Not Completed',
};

export const SocketTypes = {
  referral: 'referral',
  inbox: 'inbox',
  task: 'task',
  attachment: 'attachment',
};

export const SocketEvents = {
  newCommentReferralComment: 'referral.comment.new',
  newInboxComment: 'inbox.comment.new',
  newTaskComment: 'task.comment.new',
  newAttachmentComment: 'attachment.comment.new',
  newInboxNotification: 'inbox.notification.new',
  faxAcknowledgement: 'fax.acknowledgement',
};

export const CommentSocketEvents: string[] = [
  SocketEvents.newCommentReferralComment,
  SocketEvents.newInboxComment,
  SocketEvents.newTaskComment,
  SocketEvents.newAttachmentComment,
];

export const SocketPaths = {
  notifications: '/notifications',
  newInboxes: '/inboxes.new',
};

export const taskListTabs: { OPEN: 'OPEN'; ARCHIVE: 'ARCHIVE' } = {
  OPEN: 'OPEN',
  ARCHIVE: 'ARCHIVE',
};

export const statusesToShiftToOpen: string[] = ['Draft'];

export const statusesToExcludeFromSimpleStatusChange: string[] = ['draft', 'discarded'];

export const ironMedications = [
  'monoferric',
  'ferumoxytol',
  'ferric gluconate',
  'injectafer',
  'infed',
  'feraheme',
  'venofer',
  'ferrelcit',
];

export const STATUS_CODES = {
  NOT_FOUND: 404,
};

export const CommentTypes = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
};

export const UPLOAD_STATUSES = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  MANUALLY_ADDED: 'ADDED_MANUALLY',
};

export const FAX_STATUSES = {
  WAITING: 'WAITING',
  IN_PROGRESS: 'Delivery in progress',
  SUCCESS: 'Successful delivery',
  MANUAL_DELIVERY: 'Manual Delivery',
  FAILED: 'Failed delivery',
};

export const TASK_TYPE_ID = {
  'Upload Documents': 'BBB9D8D4-1A7E-4AB7-B9A8-91F9D1A6CD1E',
  Scheduling: 'F2A880AD-D410-4F8E-8DC7-5AC374BC6052',
  'Weinfuse Entry - Allergies & Meds': '3DABAC32-5F67-498A-AD14-F15665132900',
  'Specialty Pharmacy': '8EB38BB0-5B69-4024-B802-5A5EC470E26E',
  'Specialty Pharmacy SQ': 'F9975E5F-96B6-46D3-BB7D-F34A845A536D',
};

export const MAX_FILE_SIZE = {
  label: '25MB',
  bytes: 25 * 1000 * 1000,
};
