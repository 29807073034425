import { BaseAction } from '../types';
import { GET_CLINICS_LOADING, GET_CLINICS_SUCCESS, GET_CLINICS_ERROR } from '../constants/referralTypes';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';

const initialState: any = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_CLINICS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_CLINICS_SUCCESS:
      const newClinics = action.payload?.length > 0 ? action.payload : [];
      const allClinics = [...state.data, ...newClinics].sort(sortByNameKeyCompareFunction);
      const arrayUniqueById = [...new Map(allClinics.map((clinic: any) => [clinic.id, clinic])).values()];

      return {
        ...state,
        data: arrayUniqueById,
      };
    case GET_CLINICS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
