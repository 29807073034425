import { BaseAction, InboxItem, InboxState } from '../types';
import {
  ADD_ACTIVE_ROW_COMMENTS,
  LOAD_NEW_INBOX_ITEM_TO_STORE,
  FETCH_ARCHIVED_LOADING,
  FETCH_RECEIVED_LOADING,
  FETCH_SENT_LOADING,
  UPDATE_INBOX_ACTION_LOADING,
  INBOX_ITEM_ERROR,
  INBOX_ITEM_LOADING,
  LOAD_ARCHIVED_ERROR,
  LOAD_ARCHIVED_TO_STORE,
  LOAD_RECEIVED_ERROR,
  LOAD_RECEIVED_TO_STORE,
  LOAD_SENT_ERROR,
  LOAD_SENT_TO_STORE,
  UPDATE_INBOX_ACTION_ERROR,
  LOAD_USER_ACTION_RESPONSE_TO_STORE,
  SET_INBOX_ACTIVE_ROW,
  UPDATE_INBOX_FILTERS,
  UPDATE_GLOBAL_INBOX_LOADING,
  UPDATE_LIST_ITEMS,
  FORWARD_INBOX_TO_ASANA,
  FORWARD_INBOX_TO_ASANA_SUCCESS,
  FORWARD_INBOX_TO_ASANA_ERROR,
  CLEAR_FORWARD_TO_ASANA,
  ADD_TO_EXISTING_REFERRAL,
  ADD_TO_EXISTING_REFERRAL_SUCCESS,
  ADD_TO_EXISTING_REFERRAL_ERROR,
  CLEAR_ADD_TO_EXISTING_REFERRAL,
  SET_INBOX_RECEIVED_SORT_DIRECTION,
  SET_ARCHIVED_INBOX_SORT_DIRECTION,
  UPDATE_INBOX_RECEIVED_TOTAL_COUNT,
  UPDATE_INBOX_ARCHIVED_TOTAL_COUNT,
} from '../constants/inboxTypes';
import { ADD_ATTACHMENTS_SUCCESS, CLEAR_ATTACHMENTS_STATE, EDIT_ATTACHMENT_SUCCESS } from '../constants/referralTypes';
import { archivedInboxStatuses, mergeNewInboxItemsWithExistingInboxItems } from '../../globalUtils/helpers';

export const initialState: InboxState = {
  loading: false,
  activeRow: {},
  inboxItemLoading: false,
  inboxError: null,
  received: {
    totalCount: 0,
    loading: false,
    page: 1,
    sortDirection: 'asc',
    error: undefined,
    results: [],
  },
  sent: {
    data: [],
    loading: false,
    error: null,
  },
  archived: {
    loading: false,
    error: null,
    totalCount: 0,
    page: 1,
    sortDirection: 'desc',
    results: [],
  },
  filters: {
    search: '',
    users: [],
    regions: [],
    statuses: [],
    fromFaxReceivedDate: undefined,
    toFaxReceivedDate: undefined,
    sortDirection: 'ASC',
    sortField: 'faxReceivedDate',
  },
  forwardToAsana: {
    loading: false,
    success: false,
    error: null,
    data: {},
  },
  inboxActions: {
    assignUser: {
      data: null,
      loading: false,
      error: null,
    },
    titleUpdate: {
      data: null,
      loading: false,
      error: null,
    },
    unAssignUser: {
      data: null,
      loading: false,
      error: null,
    },
    addToExistingReferral: {
      loading: false,
      error: null,
      data: {},
    },
    statusUpdate: {
      loading: false,
      error: null,
      data: null,
    },
  },
};

const inboxReducer = (state = initialState, action: BaseAction): InboxState => {
  switch (action.type) {
    case SET_INBOX_ACTIVE_ROW:
      return {
        ...state,
        activeRow: action.payload,
      };
    case ADD_ACTIVE_ROW_COMMENTS:
      return {
        ...state,
        activeRow: {
          ...state.activeRow,
          comments: [...(state?.activeRow?.comments || []), action.payload],
        },
      };
    case ADD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        activeRow: {
          ...state.activeRow,
          // validate before appending
          attachments: [...(state?.activeRow?.attachments || []), action.payload],
        },
      };

    case EDIT_ATTACHMENT_SUCCESS:
      const updatedActiveRowAttachments = state?.activeRow?.attachments?.map((attachmentItem: any) => {
        return attachmentItem.id === action.payload.id ? action.payload : attachmentItem;
      });
      return {
        ...state,
        activeRow: {
          ...state.activeRow,
          attachments: updatedActiveRowAttachments,
        },
      };

    case CLEAR_ATTACHMENTS_STATE: {
      return {
        ...state,
        activeRow: {
          ...state.activeRow,
          attachments: [],
        },
      };
    }
    case INBOX_ITEM_LOADING:
      return {
        ...state,
        inboxItemLoading: action.payload,
      };
    case INBOX_ITEM_ERROR:
      return {
        ...state,
        inboxError: action.payload,
      };
    case FETCH_RECEIVED_LOADING:
      return {
        ...state,
        received: {
          ...state.received,
          loading: action?.payload?.loading,
        },
      };
    case UPDATE_GLOBAL_INBOX_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_RECEIVED_TO_STORE:
      return {
        ...state,
        received: {
          ...state.received,
          page: action.payload.page,
          results: mergeNewInboxItemsWithExistingInboxItems(
            state.received.results,
            action.payload.result,
            action.payload.page === 1,
          ),
          totalCount: action?.payload?.totalCount,
        },
      };
    case SET_INBOX_RECEIVED_SORT_DIRECTION:
      return {
        ...state,
        received: {
          ...state.received,
          sortDirection: action.payload.toLowerCase(),
        },
      };
    case SET_ARCHIVED_INBOX_SORT_DIRECTION:
      return {
        ...state,
        archived: {
          ...state.archived,
          sortDirection: action.payload.toLowerCase(),
        },
      };
    case LOAD_RECEIVED_ERROR:
      return {
        ...state,
        received: {
          ...state.received,
        },
      };

    case FETCH_SENT_LOADING:
      return {
        ...state,
        sent: {
          ...state.sent,
          loading: action.payload,
        },
      };
    case LOAD_SENT_TO_STORE:
      return {
        ...state,
        sent: {
          ...state.sent,
          loading: false,
          data: action.payload,
        },
      };
    case LOAD_SENT_ERROR:
      return {
        ...state,
        sent: {
          ...state.sent,
          loading: false,
          error: action.payload,
        },
      };

    case FETCH_ARCHIVED_LOADING:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: action.payload,
        },
      };
    case LOAD_ARCHIVED_TO_STORE:
      return {
        ...state,
        archived: {
          ...state.archived,
          page: action.payload.page,
          results: mergeNewInboxItemsWithExistingInboxItems(
            state.archived.results,
            action.payload.result,
            action.payload.page === 1,
          ),
          totalCount: action?.payload?.totalCount,
        },
      };
    case LOAD_ARCHIVED_ERROR:
      return {
        ...state,
        archived: {
          ...state.archived,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_INBOX_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        received: {
          ...state.received,
          totalCount: 0,
        },
        archived: {
          ...state.archived,
          totalCount: 0,
        },
      };
    case LOAD_USER_ACTION_RESPONSE_TO_STORE:
      return {
        ...state,
        inboxActions: {
          ...state.inboxActions,
          [action.key]: {
            // @ts-ignore
            ...state.inboxActions[action.key],
            data: action.payload,
          },
        },
      };
    case UPDATE_INBOX_ACTION_LOADING:
      return {
        ...state,
        inboxActions: {
          ...state.inboxActions,
          [action.key]: {
            // @ts-ignore
            ...state.inboxActions[action.key],
            loading: action.payload,
          },
        },
      };
    case UPDATE_INBOX_ACTION_ERROR:
      return {
        ...state,
        inboxActions: {
          ...state.inboxActions,
          [action.key]: {
            // @ts-ignore
            ...state.inboxActions[action.key],
            error: action.payload,
          },
        },
      };
    case UPDATE_LIST_ITEMS:
      const isArchivedStatusUpdate: boolean = archivedInboxStatuses.includes(action.payload.status?.toUpperCase());
      const itemExistsInArchivedResults: boolean = state.archived.results.find(
        (inboxItem: InboxItem) => inboxItem.id === action.payload.id,
      )
        ? true
        : false;
      const itemExistsInReceivedResults: boolean = state.received.results.find(
        (inboxItem: InboxItem) => inboxItem.id === action.payload.id,
      )
        ? true
        : false;

      return {
        ...state,
        received: {
          ...state.received,
          results: itemExistsInReceivedResults
            ? isArchivedStatusUpdate
              ? state.received.results.filter((inboxItem: InboxItem) => inboxItem.id !== action.payload?.id)
              : state.received.results.map((inboxItem: InboxItem) => {
                  return inboxItem?.id === action.payload.id ? { ...inboxItem, ...action.payload } : inboxItem;
                })
            : isArchivedStatusUpdate
            ? state.received.results
            : [...state.received.results, action.payload],
        },
        archived: {
          ...state.archived,
          results: itemExistsInArchivedResults
            ? isArchivedStatusUpdate
              ? state.archived.results.map((inboxItem: InboxItem) => {
                  return inboxItem?.id === action.payload?.id ? { ...inboxItem, ...action.payload } : inboxItem;
                })
              : state.archived.results.filter((inboxItem: InboxItem) => inboxItem.id !== action.payload?.id)
            : isArchivedStatusUpdate
            ? [...state.archived.results, action.payload]
            : state.archived.results,
        },
        sent: {
          ...state.sent,
          data:
            action?.payload?.status && isArchivedStatusUpdate
              ? state.sent.data?.filter((d) => d.id !== action?.payload?.id)
              : state.sent.data?.map((d) => (d.id === action.payload.id ? { ...d, ...action.payload } : d)),
        },
      };
    case LOAD_NEW_INBOX_ITEM_TO_STORE:
      return {
        ...state,
        received: {
          ...state.received,
          results: [...state.received.results, action.payload],
        },
      };
    case FORWARD_INBOX_TO_ASANA:
      return {
        ...state,
        forwardToAsana: {
          ...state.forwardToAsana,
          loading: true,
          error: null,
          success: false,
        },
      };
    case FORWARD_INBOX_TO_ASANA_SUCCESS:
      return {
        ...state,
        forwardToAsana: {
          ...state.forwardToAsana,
          data: action.payload,
          loading: false,
          error: null,
          success: true,
        },
        received: {
          ...state.received,
          totalCount: state?.received?.totalCount - 1,
          results: state.received.results.filter((inboxItem: InboxItem) => inboxItem.id !== action.payload),
        },
      };

    case FORWARD_INBOX_TO_ASANA_ERROR:
      return {
        ...state,
        forwardToAsana: {
          ...state.forwardToAsana,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    case CLEAR_FORWARD_TO_ASANA: {
      return {
        ...state,
        forwardToAsana: {
          ...state.forwardToAsana,
          loading: false,
          success: false,
          error: null,
          data: {},
        },
      };
    }

    case ADD_TO_EXISTING_REFERRAL:
      return {
        ...state,
        addToExistingReferral: {
          ...state.addToExistingReferral,
          loading: true,
          error: null,
          success: false,
        },
      };

    case ADD_TO_EXISTING_REFERRAL_SUCCESS:
      return {
        ...state,
        addToExistingReferral: {
          ...state.addToExistingReferral,
          data: action.payload,
          loading: false,
          error: null,
          success: true,
        },
      };

    case ADD_TO_EXISTING_REFERRAL_ERROR:
      return {
        ...state,
        addToExistingReferral: {
          ...state.addToExistingReferral,
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    case CLEAR_ADD_TO_EXISTING_REFERRAL:
      return {
        ...state,
        addToExistingReferral: {
          loading: false,
          data: {},
          error: null,
          success: false,
        },
      };
    case UPDATE_INBOX_RECEIVED_TOTAL_COUNT:
      return {
        ...state,
        received: {
          ...state.received,
          totalCount: action.payload,
        },
      };
    case UPDATE_INBOX_ARCHIVED_TOTAL_COUNT:
      return {
        ...state,
        archived: {
          ...state.archived,
          totalCount: action.payload,
        },
      };
    default:
      return state;
  }
};

export default inboxReducer;
