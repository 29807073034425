import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/system';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'relative' as 'relative',
      backgroundColor: alpha(theme.palette.common.black, 0.04),
      borderRadius: 8,
      padding: '2.4px 0px 2.4px 2.4px',
    },
    icons: {
      position: 'absolute' as 'absolute',
      right: 16,
      bottom: 5,
    },
    iconsExpandedView: {
      position: 'absolute' as 'absolute',
      right: 16,
      bottom: 48,
    },
    chipSpan: {
      marginRight: '8px !important',
      padding: '3px 4px 3px 8px',
      display: 'flex',
    },
    followUpWrapper: {
      position: 'absolute' as 'absolute',
      bottom: 18,
      left: 15,
    },
  };
});

export const styles = (theme: Theme, isExpanded?: boolean) => {
  return {
    control: {
      backgroundColor: '#fff',
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
    },

    '&multiLine': {
      control: {
        minHeight: isExpanded ? 0 : 48,
        paddingBottom: isExpanded ? 40 : 0,
      },
      highlighter: {
        padding: `14px 16px`,
        border: 'none',
      },
      input: {
        padding: `14px 16px`,
        border: 'none',
        outline: 'none',
        backgroundColor: alpha(theme.palette.common.black, 0.04),
      },
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: 'none',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },

    suggestions: {
      list: {
        fontSize: 16,
        boxShadow: theme.shadows[8],
        maxHeight: 48 * 8,
        overflowY: 'scroll',
        // zIndex: 13000,
      },
      item: {
        padding: '4px 15px 20px 15px',
        // borderBottom: '1px solid rgba(0,0,0,0.15)',
        boxSizing: 'border-box',
        height: 48,
        '&focused': {
          backgroundColor: alpha(theme.palette.common.black, 0.04),
        },
      },
    },
  } as any;
};

export const mentionStyles = (theme: Theme) => {
  return {
    position: 'relative',
    zIndex: 1,
    color: '#1976D2',
    textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
    textDecoration: 'none',
    pointerEvents: 'none',
    height: '90px',
  };
};
