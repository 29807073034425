import { useEffect, useMemo, useState } from 'react';
import { getReferralCreationParams, referralPayload } from '@/redux/actions/referralsActions';

export function ReferralAcceptanceController(api: ReferralAcceptanceApi) {
  return {
    useLiveCalculatedReferralAcceptance,
  };

  function useLiveCalculatedReferralAcceptance(referralData: referralPayload) {
    const [status, setStatus] = useState<AcceptanceStatus>(null);

    const inputHash = useMemo(() => {
      const { acceptanceStatus: _, ...data } = getReferralCreationParams(referralData).requiredParams;
      return JSON.stringify(data);
    }, [referralData]);

    useEffect(() => {
      api.calculate(JSON.parse(inputHash)).then((result) => {
        setStatus(result);
      });
    }, [inputHash, setStatus]);

    return {
      value: status,
    };
  }
}

export interface ReferralAcceptanceApi {
  calculate(referralData: referralPayload): Promise<AcceptanceStatus>;
}

export type AcceptanceStatus = string | null;
