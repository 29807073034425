import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: '#212121 !important',
    },
  };
});

export default useStyles;
