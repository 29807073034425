import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {
  return {
    parent: {
      position: 'relative',
      width: 264,
      //boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      background: '#FFFFFF',
      borderRadius: 4,
      paddingTop: 0,
      paddingBottom: 0,
    },
    tooltip: {
      backgroundColor: '#212121 !important',
    },
  };
});

export default useStyles;
