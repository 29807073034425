import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { useState } from 'react';

export function ToolsMenu() {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <IconButton title="Tools" onClick={(e) => setAnchorElement(e.currentTarget)}>
        <HandymanOutlinedIcon />
      </IconButton>
      <Menu
        id="tools-menu"
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
        anchorEl={anchorElement}
      >
        <MenuItem
          onClick={() =>
            window.open(
              'https://apps.powerapps.com/play/e/8277e003-c12e-e391-a17c-cbfc8a12ea07/a/cce4c8a5-ec68-4509-a01c-22afdab21b18?tenantId=2c006bba-da1d-46bc-87c9-05746105c880&hint=0387ef67-6584-40c3-b988-1985f3fbab83&source=sharebutton&sourcetime=1700164502295',
              '_blank',
            )
          }
        >
          Inventory Distribution Calculator
        </MenuItem>
      </Menu>
    </Box>
  );
}
