import { format } from 'date-fns';

export type WeInfuseOrderOption = {
  id: string;
  date: string;
  medicationName: string;
  isArchived: boolean;
};

export type OrderSelectViewModel = {
  open: boolean;
  placeholderText: string;
  isLoadingOrders: boolean;
  orders: OrderOptionViewModel[];
  saveButtonDisabled: boolean;
  saveButtonText: string;
  showEmptyDropdownMessage: boolean;
};

export type OrderOptionViewModel = {
  id: string;
  isSelected: boolean;
  fade: boolean;
  idLabel: string;
  dateLabel: string;
  medicationLabel: string;
};

export type SelectedOrderHash = { [id: string]: boolean };

export type OrderSelectState = {
  open: boolean;
  options: WeInfuseOrderOption[] | null;
  isSaving: boolean;
  selectedOrders: SelectedOrderHash;
};

const numberWords = ['zero', 'one', 'two', 'three', 'four', 'five'];

export function presentOrderSelect(maxLinkedOrders: number, state: OrderSelectState): OrderSelectViewModel {
  return showEmptyDropdownMessage({
    isLoadingOrders: state.options === null,
    orders: (state.options || []).map((order) => ({
      id: order.id,
      idLabel: `ID ${order.id}`,
      fade: order.isArchived,
      dateLabel: format(new Date(order.date + ' 00:00:00'), 'LLL d, yyyy') + (order.isArchived ? ' (Archived)' : ''),
      isSelected: Boolean(state.selectedOrders[order.id]),
      medicationLabel: order.medicationName,
    })),
    placeholderText: `Select up to ${numberWords[maxLinkedOrders]} orders`,
    open: state.open,
    saveButtonDisabled: state.isSaving || !Object.values(state.selectedOrders).some((x) => x),
    saveButtonText: state.isSaving ? '...' : 'Save',
  });

  function showEmptyDropdownMessage(state: any) {
    return {
      ...state,
      showEmptyDropdownMessage: state.orders.length < 1,
    };
  }
}
