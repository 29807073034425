import { Box } from '@mui/system';
import React from 'react';
import { useStyles } from './notificationsStyles';
import emptyNotificationsImage from '../../images/emptyNotificationsLogo.svg';
import { Typography } from '@mui/material';

export const EmptyNotifications: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.emptyNotificationsContainer}>
      <img src={emptyNotificationsImage} alt="" />
      <Typography paddingTop={4}>There are no new notifications at this time.</Typography>
    </Box>
  );
};
