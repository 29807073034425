import { Notification } from '../../redux/types';
import { ReactNode } from 'react';
import { formatComment } from '../../globalUtils/utils';
import { NOTIFICATION_ICON_MAP } from './icon-map';

export const getNotificationFormatting = (
  notification: Notification,
): {
  text: string;
  link: string;
  icon?: ReactNode;
} => {
  return {
    text: formatComment(notification.text),
    link: notification.link,
    icon: (NOTIFICATION_ICON_MAP[notification.icon] || (() => null))({}),
  };
};
