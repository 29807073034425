import { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Input,
  CircularProgress,
  Tooltip,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import deleteIcon from '../../images/deleteIcon.svg';
import { useStyles } from './AttachmentItemStyles';
import { Props as FileProps } from './AttachmentItemTypes';
import uploadError from '../../images/uploadError.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItemSearchComponent from '../MenuItemSearchComponent/MenuItemSearchComponent';
import { AttachmentCategoriesParams } from '../../redux/actions/attachmentActions';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NonScrollableMenu from '../Menu/NonScrollableMenu';

interface Props {
  file: FileProps;
  categories: AttachmentCategoriesParams[];
  fileUploadError?: boolean;
  fileUploadLoading?: boolean;
  fileUploadSuccess?: boolean;
  errorToolTip?: string;
  percentComplete?: number;
  initialCategories?: string[];
  handleRemoveFile: (fileName: string) => void;
  formatSelectedToStr: (selected: string[]) => string[];
  setAttachmentValues: (attachmentValues: any) => void;
  categoryRequired?: boolean;
  hasWeInfusePatient?: boolean;
  hidePublishToEMR?: boolean;
  onSelectPublishToEMR?: (file: any, isSelected: boolean) => void;
}

const EditAbleAttachmentItem: FC<Props> = (props) => {
  const {
    file,
    handleRemoveFile,
    categories,
    formatSelectedToStr,
    setAttachmentValues,
    fileUploadError,
    fileUploadLoading,
    errorToolTip,
    percentComplete,
    fileUploadSuccess,
    initialCategories,
    categoryRequired,
    hasWeInfusePatient,
    hidePublishToEMR,
    onSelectPublishToEMR,
  } = props;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selected, setSelected] = useState<any[]>(initialCategories || []);
  const [publishToEMRSelected, setPublishToEMRSelected] = useState<boolean>(false);

  const handleRemoveFiles = (name: string) => {
    handleRemoveFile(name);
  };

  const handleSelectPublishToEMR = (publishToEMRSelected: boolean, file: FileProps) => {
    setPublishToEMRSelected(!publishToEMRSelected);
    onSelectPublishToEMR && onSelectPublishToEMR(file, !publishToEMRSelected);
  };

  // SelectChangeEvent<typeof selectedCategory>
  const handleChange = (event: any, fileName: string, isNameInput: boolean) => {
    const {
      target: { value: eventValue },
    } = event;
    !isNameInput &&
      setSelected(
        // On autofill we get a stringified value.
        typeof eventValue === 'string' ? eventValue.split(',') : eventValue,
      );

    setAttachmentValues((value: any) => {
      const newValues = value.map((item: any) => {
        return item[fileName]?.fileName === fileName
          ? {
              ...item,
              [fileName]: {
                ...item[fileName],
                categories: !isNameInput
                  ? typeof eventValue === 'string'
                    ? eventValue.split(',')
                    : eventValue
                  : item[fileName].categories,
                ...(isNameInput && { newFileName: eventValue }),
                fileName,
              },
            }
          : item;
      });

      if (value?.length > 0 && value.filter((item: any) => item[fileName]?.fileName === fileName)?.length > 0) {
        return newValues;
      } else {
        return [
          ...value,
          {
            [fileName]: {
              categories: !isNameInput ? (typeof eventValue === 'string' ? eventValue.split(',') : eventValue) : [],
              ...(isNameInput && { newFileName: eventValue }),
              fileName,
            },
          },
        ];
      }
    });
  };

  const classes = useStyles();
  const categoryOptions = categories
    .map((item) => {
      const isSelected = selected.map((value) => value).includes(item.id);
      return {
        ...item,
        searchString: item.name,
        label: item.name,
        value: item.id,
        groupName: isSelected ? ' SELECTED' : 'ALL',
        disabled: initialCategories?.includes(item.id),
      };
    })
    .sort(({ groupName }) => (groupName === 'SELECTED' ? 1 : -1));

  const renderValue = formatSelectedToStr(selected)?.join(', ');

  useEffect(() => {
    if (initialCategories?.length) {
      initialCategories?.forEach((id) => {
        handleChange(
          { target: { value: id } },
          file.rawFile.name ? file.rawFile.name : file.rawFile?.rawFile.name,
          false,
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isErrored = categoryRequired && !selected.length;

  return (
    <Box
      data-id="editableAttachmentItem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.editableAttachmentItem}
      key={file.id}
      flexDirection="column"
    >
      <Grid container spacing={1}>
        <Grid item xs={6} pl={8} display="flex" alignItems="center" justifyContent="center" data-id="fileName">
          <FormControl className={classes.listedAttachment} fullWidth>
            <Input
              defaultValue={file.filename || 'no name'}
              disabled
              onChange={(event) => handleChange(event, file.rawFile.name, true)}
              disableUnderline
              classes={{ disabled: classes.input }}
              endAdornment={
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ textTransform: 'uppercase', paddingRight: 16 }}
                >
                  .{file.extension}
                </Typography>
              }
              style={{ paddingLeft: 8, WebkitTextFillColor: '' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} pl={8} display="flex" alignItems="center" justifyContent="center" data-id="tags">
          <Box style={{ position: 'relative', width: '100%' }}>
            <Button
              disableRipple
              style={{ width: '100%', padding: 0, display: 'flex' }}
              onClick={(event) => setAnchorEl(event.target)}
            >
              <Box
                flex={1}
                width="100%"
                display="flex"
                alignItems="center"
                style={{ background: isErrored ? '#FEEBEE' : 'rgba(0,0,0,0.05)' }}
                justifyContent="space-between"
              >
                <Box flex={1} className={classes.selectStyles}>
                  {renderValue}
                </Box>
                <ArrowDropDownIcon style={{ color: 'rgba(0,0,0,0.54)', marginRight: 16 }} fontSize={'large'} />
              </Box>
            </Button>
            {isErrored && (
              <Typography style={{ position: 'absolute' }} color="error" variant="caption">
                Category is required
              </Typography>
            )}
          </Box>
          <NonScrollableMenu
            style={{ height: 400, top: 8 }}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
          >
            <MenuItemSearchComponent
              showByGroup
              options={categoryOptions}
              showAvatar={false}
              onChange={(value) =>
                handleChange(
                  { target: { value } },
                  file.rawFile.name ? file.rawFile.name : file.rawFile?.rawFile.name,
                  false,
                )
              }
              menuItemsContentStyles={{
                maxHeight: 250,
                overflowY: undefined,
              }}
              initialValues={selected}
              sortByName
            />
          </NonScrollableMenu>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            {fileUploadSuccess && <CheckIcon color="success" />}
            {fileUploadError && (
              <Tooltip title={errorToolTip || ''}>
                <img alt="" src={uploadError} />
              </Tooltip>
            )}
            {fileUploadLoading && (
              <>
                <CircularProgress variant="determinate" value={percentComplete} size={40} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
                    percentComplete || 0,
                  )}%`}</Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" data-id="iconTrash">
          <Box>
            <img
              role="button"
              onClick={() => handleRemoveFiles(file?.rawFile?.rawFile?.name || file.rawFile.name)}
              height={20}
              width={18}
              alt=""
              src={deleteIcon}
            />
          </Box>
        </Grid>
      </Grid>
      {!hidePublishToEMR && (
        <Box display="flex" style={{ width: '100%' }} alignItems="center">
          <FormControlLabel
            value={publishToEMRSelected}
            disabled={!hasWeInfusePatient}
            control={
              <Checkbox
                onChange={() => {
                  handleSelectPublishToEMR(publishToEMRSelected, file);
                }}
                checked={Boolean(publishToEMRSelected)}
              />
            }
            label={
              <Typography color={!hasWeInfusePatient ? '#00000042' : ''} fontSize={16}>
                Publish to EMR
              </Typography>
            }
          />
          {!hasWeInfusePatient && (
            <Tooltip title="To publish attachment to EMR, please select a Weinfuse patient">
              <ErrorOutlineOutlinedIcon style={{ color: '#00000042', width: 20, height: 20 }} fontSize="large" />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EditAbleAttachmentItem;
