import axios, { AxiosResponse } from 'axios';
import { referralSubTypes } from '../globalUtils/sharedTypes';
import { editAttachmentProps, referralSubType } from '../redux/actions/referralsActions';
import { Referral } from '../redux/types';
const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

export interface CreateReferralParams {
  clinicId?: string;
  inboxId?: string;
  patientId?: string;
  regionId?: string;
  primaryInsuranceCarrier?: string;
  diagnosis?: string;
  canceledReason?: string;
  notCompletedReason?: string;
  onHoldReason?: string;
  providerFax?: string;
  providerId?: string;
  taskTemplateId?: string;
  medicationId?: string;
  referralType?: Referral['referralType'];
  referralSubType?: referralSubType;
  homeCity?: string;
  providerGroup?: string;
  lastPatientContactDate?: string;
  faxReceivedDate?: string;
  lastFollowupDate?: string;
  nextFollowupDate?: string;
  intakeCompletedDate?: string;
  scheduledDate?: string;
  appointmentDate?: string;
  categoryId?: string;
  statusId?: string;
  attachmentIds?: string[];
  assignedUserId?: string;
  priority?: 'Urgent' | 'Normal';
}
export const createReferral = async (params: CreateReferralParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/referrals`, params);
};

export const editAttachment = async (params: editAttachmentProps): Promise<AxiosResponse<any, any>> => {
  const { id, ...requiredParams } = params;
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.patch(`${baseUrl}/attachments/${params.id}`, requiredParams);
};

export interface AddAttachmentParams {
  taskId?: string;
  inboxId?: string;
  message?: string;
  tagUserIds?: string;
  faxId?: string;
  referralId?: string;
  attachmentsNumber?: string;
  categoryIds?: string;
  type?: string;
  file?: any;
}
export const addAttachment = async (
  params: AddAttachmentParams,
  dispatchProgress: (progress: number) => void,
): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/attachments`, params, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = percentComplete * 100;
      dispatchProgress(percentComplete);
    },
  });
};

export const createDraftReferral = async (params: CreateReferralParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/referrals/draft`, params);
};

export const getReferralApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/referrals/${id}`);
};

export const getReferralCategoriesApi = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/referral-categories/`);
};

export const getAttachmentCategoriesApi = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/attachment-category/`);
};

export const getReferralStatusesApi = async (): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.get(`${baseUrl}/referral-statuses/`);
};

export interface FetchReferralParams {
  search?: string;
  providers?: string[];
  drugTiers?: string[];
  patients?: string[];
  assignedUsers?: string[];
  completedByUsers?: string[];
  regions?: string[];
  clinics?: string[];
  medications?: string[];
  taskTemplates?: string[];
  referralTypes?: string[];
  referralSubTypes?: referralSubType[];
  statuses?: string[];
  priority?: 'Urgent' | 'Normal';
  categories?: string[];
  dateOfBirth?: string;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  fromFaxReceivedDate?: string;
  toFaxReceivedDate?: string;
  fromLastFollowupDate?: string;
  toLastFollowupDate?: string;
  fromNextFollowupDate?: string;
  toNextFollowupDate?: string;
  fromLastPatientContactDate?: string;
  toLastPatientContactDate?: string;
  sortDirection?: 'ASC' | 'DESC';
  sortField?: string;
  page?: string;
  pageSize?: string;
}
export const fetchReferralsApi = async (params?: FetchReferralParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/referrals/find`, params);
};

export interface EditReferralParams {
  id?: string;
  clinicId?: string;
  inboxId?: string;
  patientId?: string;
  regionId?: string;
  primaryInsuranceCarrier?: string;
  diagnosis?: string;
  providerFax?: string;
  providerId?: string;
  taskTemplateId?: string;
  medicationId?: string;
  referralType?: referralSubTypes;
  referralSubType?: referralSubType;
  homeCity?: string;
  providerGroup?: string;
  lastPatientContactDate?: string | Date;
  faxReceivedDate?: string | Date;
  lastFollowupDate?: string | Date;
  nextFollowupDate?: string | Date;
  intakeCompletedDate?: string | Date;
  scheduledDate?: string | Date;
  appointmentDate?: string | Date;
  assignedUserId?: string;
  priority?: 'Urgent' | 'Normal';
  completedByUserId?: string;
  message?: string;
  isExternal?: boolean;
  tagUserIds?: string[];
  referralFollowupTag?: boolean;
  patientContactTag?: boolean;
  waitingTag?: boolean;
  type?: 'PRIVATE' | 'PUBLIC';
}
export const editReferralApi = async (params: EditReferralParams): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  const { id, ...restParams } = params;
  return await axios.patch(`${baseUrl}/referrals/${id}`, restParams);
};

export interface GetReferralProviderParams {
  search?: string;
  clinicId?: string;
  isTemporary?: boolean;
  sortDirection?: 'ASC' | 'DESC';
  sortField?: string;
  page?: number;
  pageSize?: number;
}
export const getReferralProvidersApi = async (data: GetReferralProviderParams): Promise<AxiosResponse<any, any>> => {
  return await axios.post(`${BASE_URL}/referral-providers/find`, data);
};

export interface AddReferralProviderParams {
  weinfuse_id?: string;
  npi?: string;
  firstName?: string;
  lastName?: string;
  isTemporary?: boolean;
  credentials?: string;
  officeFaxNumber?: string;
  officePhoneNumber?: string;
  mobilePhoneNumber?: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  regionId?: string;
}
export const addReferralProviderApi = async (
  params: AddReferralProviderParams & { providerId?: string },
): Promise<AxiosResponse<any, any>> => {
  const { providerId, ...restParams } = params;
  return await axios.post(`${BASE_URL}/referral-providers/`, restParams);
};

export interface EditReferralProviderParams {
  id?: string;
  weinfuse_id?: string;
  npi?: string;
  firstName?: string;
  lastName?: string;
  isTemporary?: boolean;
  credentials?: string;
  officeFaxNumber?: string;
  officePhoneNumber?: string;
  mobilePhoneNumber?: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  regionId?: string;
}
export const patchReferralProviderApi = async (
  params: EditReferralProviderParams,
): Promise<AxiosResponse<any, any>> => {
  const { id, ...restParams } = params;
  return await axios.patch(`${BASE_URL}/referral-providers/${id}`, restParams);
};

export const deleteReferralProviderApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  return await axios.delete(`${BASE_URL}/referral-providers/${id}`);
};

export const getOneReferralProviderApi = async (id: string): Promise<AxiosResponse<any, any>> => {
  return await axios.get(`${BASE_URL}/referral-providers/${id}`);
};

export const updateReferralStatusesApi = async (referralId: string, payload: any) => {
  return await axios.patch(`${BASE_URL}/referrals/status/${referralId}`, payload);
};

export const markAttachmentAsSeenOnReferralApi = async (payload: {
  attachmentId: string;
  referralId: string;
  seen: boolean;
}) => {
  return await axios.patch(`${BASE_URL}/referrals/attachment/mark/`, payload);
};

export const deleteReferralApi = async (referralId: string) => {
  return await axios.delete(`${BASE_URL}/referrals/${referralId}`);
};

export const uploadAttachmentToWeInfuseApi = async (referralId: string, attachmentId: string, payload: any) => {
  return await axios.put(`${BASE_URL}/referrals/${referralId}/attachments/${attachmentId}/emr-upload`, payload);
};
