const backendErrorHandler = (error: any): string => {
  let errorMessage;
  let validationErrors;
  let validationKeys;
  // if server gets an error response, handle it

  if (error.response) {
    if (error.response.data.statusCode === 401) {
      errorMessage = 'Session expired, please login again';
      return errorMessage;
    }
    switch (typeof error.response.data.message) {
      case 'string':
        errorMessage = error.response.data.message;
        break;
      case 'object':
        validationErrors = Object.values(error.response.data.message).join(', ');
        validationKeys = Object.keys(error.response.data.message).join(', ');
        errorMessage = `${validationKeys}: ${validationErrors}`;
        break;
      default:
        errorMessage = error.response.data.message || error.response.data.error;
    }
  } else {
    //  if server is down, client won't get a response
    errorMessage = 'Possible network error, please reload the page';
    console.error('Error received is: ', error);
  }
  console.error('Error received is: ', error);
  return errorMessage || 'Possible server error';
};

export default backendErrorHandler;
