import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const STORAGE_KEY = 'releaseNotesHash';
const RELEASE_NOTES_URL = `${process.env.REACT_APP_API_URL}/release-notes`;
const EIGHT_HOURS_MS = 8 * 60 * 60 * 1000;

let releaseNotes = {} as ReleaseNotesProps;

export function useReleaseNotes() {
  const [state, setState] = useState({
    hash: '',
    updated: false,
  });
  useEffect(() => {
    if (!releaseNotes.updates) {
      return loadReleaseNotes();
    }

    function loadReleaseNotes() {
      const lastSeenHash = localStorage.getItem(STORAGE_KEY);
      axios
        .get(RELEASE_NOTES_URL)
        .then((response) => {
          const hash = response.headers['etag'];

          releaseNotes = response.data;
          setState(() => ({
            hash,
            updated: lastSeenHash !== hash,
          }));
        })
        .catch(console.error);

      const timer = setTimeout(loadReleaseNotes, EIGHT_HOURS_MS);

      return () => clearTimeout(timer);
    }
  }, [setState]);
  const navigate = useNavigate();

  return {
    updated: state.updated,
    releaseNotes,
    view: navigateToReleaseNotes,
  };

  function handleCloseReleaseNotesAlert() {
    setState((state) => ({
      ...state,
      updated: false,
    }));
    localStorage.setItem(STORAGE_KEY, state.hash);
  }

  function navigateToReleaseNotes() {
    navigate(`/release-notes`);
    handleCloseReleaseNotesAlert();
  }
}

interface Update {
  version: string;
  date: string;
  newAdditions: NewAddition[];
}

interface NewAddition {
  type: string;
  feature: string;
  image?: string;
  title: string;
  description: string;
}

interface ReleaseNotesProps {
  updates: Update[];
}
