import { Dispatch } from 'redux';
import { ClinicsParams, getClinics } from '../../api/clinicsApi';
import { GET_CLINICS_LOADING, GET_CLINICS_SUCCESS, GET_CLINICS_ERROR } from '../constants/referralTypes';

const getClinicsLoading = (payload: boolean) => ({
  type: GET_CLINICS_LOADING,
  payload,
});

const getClinicsSuccess = (payload: any) => ({
  type: GET_CLINICS_SUCCESS,
  payload,
});

const getClinicsError = (payload: any) => ({
  type: GET_CLINICS_ERROR,
  payload,
});

export const getClinicsAction =
  (data: ClinicsParams = {}) =>
  async (dispatch: Dispatch) => {
    try {
      const pageSize = 1000;
      dispatch(getClinicsLoading(true));
      const response = await getClinics({ ...data, pageSize });
      dispatch(getClinicsSuccess(response?.data?.data));
      dispatch(getClinicsLoading(false));
    } catch (e) {
      dispatch(getClinicsError(e));
      dispatch(getClinicsLoading(false));
    }
  };
