import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Socket } from 'socket.io-client';
import {
  getAllNotificationsApi,
  markAllNotificationsAsReadApi,
  markNotificationAsReadApi,
  markNotificationAsUnreadApi,
  getUnreviewedNotificationsCountApi,
} from '../../api/notificationsApi';
import socket from '../../api/socketsApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { SocketPaths } from '../../globalUtils/constants';
import {
  getNotificationsError,
  getNotificationsLoading,
  getNotificationsSuccess,
  getUnreviewedNotificationsCountuccess,
  markAllNotificationsAsReadError,
  markAllNotificationsAsReadLoading,
  markAllNotificationsAsReadSuccess,
  markNotificationAsReadError,
  markNotificationAsReadLoading,
  markNotificationAsReadSuccess,
  setNotificationsGlobalLoading,
} from '../actionCreators/notificationsActionCreators';
import { updateAttachmentFax } from '../actionCreators/referralActionCreators';
import { Notification } from '../types';
import { faxAckSuccess } from './faxActions';
import { addNewTaskSuccess } from './tasksActions';

export const getNotificationsAction = (params?: any, callBack?: () => void) => async (dispatch: Dispatch) => {
  try {
    dispatch(getNotificationsLoading(true));
    const notificationsResponse = await getAllNotificationsApi(params);
    dispatch(getNotificationsSuccess(notificationsResponse.data));
    callBack && callBack();
  } catch (error) {
    showErrorMessage(backendErrorHandler(error));
    dispatch(getNotificationsError(error));
  } finally {
    dispatch(getNotificationsLoading(false));
    dispatch(setNotificationsGlobalLoading(false));
  }
};

export const getUnreviewedNotificationsCountAction = () => async (dispatch: Dispatch) => {
  try {
    const response = await getUnreviewedNotificationsCountApi();
    dispatch(getUnreviewedNotificationsCountuccess(response.data));
  } catch (error) {
    showErrorMessage(backendErrorHandler(error));
  } finally {
  }
};

export const markNotificationAsReadAction =
  (notification: Notification, callBack?: (responseData: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(markNotificationAsReadLoading(true, notification));
      const response = await markNotificationAsReadApi(notification.id);
      dispatch(markNotificationAsReadSuccess(response.data));
      callBack && callBack(response.data);
    } catch (error) {
      dispatch(markNotificationAsReadError(error));
    } finally {
      dispatch(markNotificationAsReadLoading(false, notification));
    }
  };

export const markNotificationAsUnreadAction = (notification: Notification) => async (dispatch: Dispatch) => {
  try {
    dispatch(markNotificationAsReadLoading(true, notification));
    const response = await markNotificationAsUnreadApi(notification.id);
    dispatch(markNotificationAsReadSuccess(response.data));
  } catch (error) {
    dispatch(markNotificationAsReadError(error));
  } finally {
    dispatch(markNotificationAsReadLoading(false, notification));
  }
};

export const useGetAllSocketNotifications = (isRootePath: boolean) => {
  const [notificationsSocket, setNotificationsSocket] = useState<Socket | undefined>();
  const dispatch = useDispatch();
  const messages: string[] = useMemo(
    () => [
      'notification.new',
      'fax.confirmation',
      'inbox.comment.new',
      'referral.comment.new',
      'attachment.comment.new',
      'task.comment.new',
      'fax.acknowledgement',
      'fax.mrr',
    ],
    [],
  );

  const handleFaxAcknowledgement = useCallback(
    (data: any) => {
      const taskIds = Object.keys(data);

      taskIds.map((id) => {
        if (data[id]?.title === 'Fax Acknowledgement') {
          return dispatch(faxAckSuccess(data[id]));
        }

        if (data[id]?.title === 'Followup with Provider for Additional Documents') {
          return dispatch(addNewTaskSuccess(data[id]));
        }
        return undefined;
      });
    },
    [dispatch],
  );

  const handleGeneralNotifications = useCallback(() => {
    dispatch(getUnreviewedNotificationsCountAction());
    dispatch(getNotificationsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isRootePath) {
      console.log('Instantiating socket connection for notifications');
      setNotificationsSocket(socket(SocketPaths.notifications));
    }
  }, [isRootePath]);

  useEffect(() => {
    if (isRootePath) {
      console.log('It is root path, disconnecting notification socket');
      notificationsSocket?.close();
    }
  }, [isRootePath, notificationsSocket]);

  useEffect(() => {
    if (notificationsSocket) {
      messages.forEach((message: string) => {
        notificationsSocket.on(message, (data) => {
          console.log(`The notification received from socket for :${message} is ${data}`);
          // TODO: Ask the backend to update the socket message to avoid having this expensive dispatch => Rely on the socket message instead or at least make a single API call not all.
          // Rely on this action then: LOAD_NEW_COMMENT_FROM_SOCKET and increment total unreviewed count
          // The unique filter when loading this in redux can also be removed once this is done i.e GET_NOTIFICATIONS_SUCCESS section

          switch (message) {
            case 'fax.acknowledgement':
              handleFaxAcknowledgement(data);
              break;
            case 'fax.mrr':
              dispatch(updateAttachmentFax(data));
              break;
            default:
              handleGeneralNotifications();
          }
        });
      });
    }

    return () => {
      console.log('closing socket connection for notifications');
      notificationsSocket?.close();
    };
  }, [dispatch, handleFaxAcknowledgement, handleGeneralNotifications, messages, notificationsSocket]);
};

export const markAllNotificationsAsReadAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(markAllNotificationsAsReadLoading(true));
    const response = await markAllNotificationsAsReadApi();
    dispatch(markAllNotificationsAsReadSuccess(response.data));
  } catch (error) {
    dispatch(markAllNotificationsAsReadError(error));
  } finally {
    dispatch(markAllNotificationsAsReadLoading(false));
  }
};
