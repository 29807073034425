import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useStyles } from '../../views/Referrals/ReferralStyles';
import { Referral } from '../../redux/types';

export type ReferralType = Pick<Referral, 'referralType' | 'referralSubType'>;

export function ReferralTypeMenu({
  onChange,
  children,
  ...props
}: {
  anchorEl?: Element | null;
  onChange: (value: ReferralType) => void;
  open: boolean;
  onCancel: () => void;
  children?: ReactNode;
}) {
  const classes = useStyles();
  return (
    <Menu data-id="referral-type-menu" anchorEl={props.anchorEl} open={props.open} onClose={props.onCancel}>
      <Box className={classes.menuLabel}>
        <Typography variant="body2" color="textSecondary">
          REFERRAL
        </Typography>
      </Box>
      <MenuItem onClick={() => onChange({ referralType: 'Referral', referralSubType: 'Normal Referral' })}>
        Referral
      </MenuItem>
      <MenuItem onClick={() => onChange({ referralType: 'Referral', referralSubType: 'Drug Change' })}>
        Drug Change
      </MenuItem>
      <Box className={classes.menuLabel}>
        <Typography variant="body2" color="textSecondary">
          RENEWAL
        </Typography>
      </Box>
      <MenuItem onClick={() => onChange({ referralType: 'Renewal', referralSubType: 'Normal Renewal' })}>
        Renewal
      </MenuItem>
      <MenuItem onClick={() => onChange({ referralType: 'Renewal', referralSubType: 'Dose Change' })}>
        Dose Change
      </MenuItem>
      {children}
    </Menu>
  );
}
