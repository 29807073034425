import React, { CSSProperties, KeyboardEvent, PropsWithChildren } from 'react';
import { Box, Checkbox, MenuItem, Typography, FormControlLabel } from '@mui/material';
import { useStyles } from './MenuItemStyles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomAvatar from '../Avatar/Avatar';
import { isEmpty } from 'lodash';

interface Props {
  option: any;
  selectedOptions: any[];
  onSelectOption: (value: string, checked: boolean) => void;
  showAvatar?: boolean;
  disabled?: boolean;
  menuTextStyle?: CSSProperties;
  value?: string | number;
}

const MenuOption: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const { option, selectedOptions, onSelectOption, showAvatar, disabled, menuTextStyle, ...rest } = props;

  // this caters for values like the provider filters
  const isSelectedObjectValue = !isEmpty(selectedOptions.find((selected) => selected?.id === option?.value?.id));
  const isSelected =
    option.value && typeof option.value === 'object' ? isSelectedObjectValue : selectedOptions.includes(option.value);

  const handleSelectOptionChange = (value: boolean) => {
    onSelectOption(option.value, value);
  };

  return (
    <MenuItem
      onKeyDown={(e: KeyboardEvent<HTMLAnchorElement> | KeyboardEvent<HTMLLIElement>) => e.stopPropagation()}
      style={{
        minWidth: 300,
        borderBottom: option?.isLoggedInUser ? '1px solid #00000014' : 'none',
        textTransform: 'capitalize',
        paddingLeft: 20,
        paddingRight: 12,
        marginTop: 8,
        height: 40,
        fontSize: 16,
      }}
      key={option.value}
      {...rest}
    >
      <FormControlLabel
        classes={{
          root: classes.formLabelRoot,
          label: classes.formLabel,
        }}
        control={
          <Box className={classes.menuItemCheckbox}>
            <Checkbox
              disabled={disabled}
              onChange={(_: any, value: boolean) => handleSelectOptionChange(value)}
              checked={isSelected}
              checkedIcon={<CheckBoxIcon fontSize="large" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
            />
          </Box>
        }
        label={
          <Box display="flex" alignItems="center" style={{ width: '100%' }}>
            {showAvatar && (
              <Box className={classes.avatarBox}>
                <CustomAvatar name={`${option?.label || ''}`} />
              </Box>
            )}
            {option?.icon && (
              <Box>
                <img src={option.icon} alt="" style={{ marginTop: 5, marginRight: 18, marginLeft: 13 }} />
              </Box>
            )}
            <Box
              className={option.disabled ? classes.disabledLabel : ''}
              display="flex"
              style={{ width: '100%', opacity: !option?.archived || !option?.disabled ? 1 : 0.38 }}
            >
              <Box style={{ width: '100%' }} flex={1} display="flex" justifyContent="space-between">
                {typeof option.label === 'string' ? (
                  <Box display="flex">
                    <Typography
                      style={{
                        maxWidth: 224,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: option?.archived ? '#00000061' : '',
                        fontSize: 16,
                        ...menuTextStyle,
                      }}
                    >
                      {option.label} {option.isLoggedInUser ? '(Me)' : ''}{' '}
                    </Typography>
                    <Typography variant="body1">
                      {option?.archived && <i style={{ color: '#00000061' }}>(Inactive)</i>}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex">
                    <Typography
                      style={{
                        maxWidth: 224,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        color: option?.archived ? '#00000061' : '',
                        fontSize: 16,
                        ...menuTextStyle,
                      }}
                    >
                      {option.label} {option.isLoggedInUser ? '(Me)' : ''}
                    </Typography>
                    {option?.archived && <i style={{ color: '#00000061' }}>(Inactive)</i>}
                  </Box>
                )}
                {option?.secondaryLabel && <>{option.secondaryLabel}</>}
              </Box>
            </Box>
          </Box>
        }
      />
    </MenuItem>
  );
};

export default MenuOption;
