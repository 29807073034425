import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Input as MuiInput,
  Typography,
  CircularProgress,
} from '@mui/material';
import insuranceIconPrimary from '../../images/insuranceIconPrimary.svg';
import { useStyles } from './referralViewStyles';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import binImage from '../../images/bin.svg';
import binImageError from '../../images/binImageError.svg';
import chartImage from '../../images/chart.svg';
import insuranceIcon from '../../images/InsuranceIcon.svg';
import orangeProvider from '../../images/orangeProvider.svg';
import providerPlusIcon from '../../images/ProviderPlusIcon.svg';
import providerPlusIconErrored from '../../images/providerPlusIconErrored.svg';
import clockIcon from '../../images/clockIcon.svg';
import editIcon from '../../images/EditIcon.svg';
import chartPrimary from '../../images/chartPrimary.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ignoreTimezoneDate, userFriendlyTimestamp } from '../../globalUtils/utils';
import { format } from 'date-fns';
import EditMedication from '../EditMedicationsComponent/EditMedicationsComponent';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from 'react-redux';
import { InsuranceStore, Referral, RootState } from '../../redux/types';
import { getTaskTemplatesAction } from '../../redux/actions/taskTemplatesActions';
import { debounce, isEmpty } from 'lodash';
import { CustomizedAutocomplete } from '../CustomizedAutocomplete';
import template from '../../images/template.svg';
import templateDisabled from '../../images/templateDisabled.svg';
import templateError from '../../images/templateError.svg';
import PatientComponent from '../PatientComponent/PatientComponent';
import ProviderComponent from '../ProviderComponent/ProviderComponent';
import CustomizedTextField from '../CustomizedLocationField/CustomizedTextField';
import InsuranceSelect, { defaultInsuranceDisplayValue } from '../InsuranceSelect/InsuranceSelect';
import { FieldBody, FieldLabel } from './FieldLabel';
import { VaccinesOutlined } from '@mui/icons-material';

interface Props {
  referral: Referral;
  isEditing: boolean;
  setIsEditing: (status: boolean) => void;
  patientId: string;
  drugTier: any;
  medication?: any;
  diagnosis: string;
  provider: any;
  providerFax: string;
  lastPatientContactDate: string;
  primaryInsuranceCarrier?: string;
  assignedUser?: any;
  patient: any;
  onSave: () => void;
  onChange: (field: string, value: any) => void;
  insurance: InsuranceStore;
  errors?: any;
  status?: any;
  medicationId?: string;
  taskTemplate?: any;
  addedPatient: any;
  changedProvider: any;
  onAfterAdd: (value: any) => void;
  onAfterChangeProvider: (value: any) => void;
  forceClose?: boolean;
  isTemporaryProvider?: boolean;
  editReferralLoading?: boolean;
  patientDetailsLoading?: boolean;
  insuranceId?: number;
  dose?: string;
}
interface ListViewProps {
  icon: ReactNode;
  primaryText: string;
  secondaryText: string;
  secondaryTextProps?: any;
  secondaryAction?: any;
  style?: any;
}

export const ListView: FC<ListViewProps> = (props) => {
  return (
    <List style={{ width: '100%', ...props.style }}>
      <ListItem component="div" secondaryAction={props.secondaryAction}>
        <ListItemIcon style={{ minWidth: '40px' }}>{props.icon}</ListItemIcon>
        <ListItemText
          primary={
            props?.primaryText && (
              <Typography
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                variant="subtitle2"
              >
                {props?.primaryText || ''}
              </Typography>
            )
          }
          secondary={
            props.secondaryText && (
              <Typography color="textSecondary" variant="subtitle2" {...props.secondaryTextProps}>
                {props.secondaryText}
              </Typography>
            )
          }
        />
      </ListItem>
    </List>
  );
};
const PatientDetails: FC<Props> = (props) => {
  const {
    primaryInsuranceCarrier,
    isEditing,
    setIsEditing,
    insurance,
    medication,
    lastPatientContactDate,
    provider,
    diagnosis,
    onSave,
    onChange,
    errors,
    status,
    medicationId,
    taskTemplate,
    patient,
    addedPatient,
    changedProvider,
    onAfterAdd,
    onAfterChangeProvider,
    forceClose,
    isTemporaryProvider,
    editReferralLoading,
    patientDetailsLoading,
    referral: { region: referralRegion, referralType, dose },
  } = props;
  const classes = useStyles();
  const medications = useSelector((state: RootState) => state.medications.data) as any;
  const isTemporaryPatient = patient?.isTemporary;

  const medicationTier: any = medications.find(({ id }: any) => id === medication?.id)?.drugTier;
  const initialMedication = !isEmpty(medication)
    ? { ...medication, name: medication?.name, drugTier: medicationTier, label: medication?.name }
    : undefined;
  const [patientDetailsOpen, openPatientDetails] = useState<boolean>(true);
  const [inputDiagnosis, setInputDiagnosis] = useState(diagnosis || '');
  const [insuranceInput, setInsuranceInput] = useState(insurance || '');
  const [insuranceDetails, setInsuranceDetails] = useState(primaryInsuranceCarrier || '');
  const dispatch = useDispatch();
  const {
    taskTemplates,
    // referralProviders: {
    //   allProvidersState: { allProviders },
    // },
  } = useSelector((state: RootState) => state);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<any>(taskTemplate || {});
  const [selectedMedicationId, setSelectedMedicationId] = useState<string | null>(medicationId || null);

  const taskTemplatesData = taskTemplates.data?.map((taskTemplate) => ({
    ...taskTemplate,
    label: taskTemplate.name,
    inputId: 'task',
  }));

  const isReferral = referralType?.toLowerCase() === 'referral';

  useEffect(() => {
    dispatch(getTaskTemplatesAction());
  }, [dispatch]);

  const [medicationInputValue, setMedicationInputValue] = useState<any>(initialMedication);
  const [newChangedProvider, setNewChangedProvider] = useState<any>(changedProvider);
  const [patientData, setPatientData] = useState<any>({
    patient: { label: '', ...patient },
  });

  useEffect(() => {
    setInputDiagnosis(diagnosis || '');
  }, [diagnosis]);

  useEffect(() => {
    setInsuranceDetails(primaryInsuranceCarrier || '');
  }, [primaryInsuranceCarrier]);

  useEffect(() => {
    if (insurance.id) {
      setInsuranceInput(insurance);
    } else {
      setInsuranceInput(defaultInsuranceDisplayValue);
    }
  }, [insurance]);

  useEffect(() => {
    setSelectedTaskTemplate(taskTemplate || '');
    // using taskTemplates and not the formatted version
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskTemplate, medication, taskTemplates]);

  useEffect(() => {
    setSelectedMedicationId(medicationId || null);
  }, [medicationId]);

  useEffect(() => {
    setNewChangedProvider(changedProvider);
  }, [changedProvider]);

  const onChangeDiagnosis = (value: string) => {
    onChange('diagnosis', value);
    setInputDiagnosis(value);
  };

  const onChangeInsurance = (value: InsuranceStore) => {
    onChange('insuranceId', value.id === 'Other' ? null : value.id);
    setInsuranceInput(value);
  };

  const onChangeInsuranceDetails = (value: string) => {
    onChange('primaryInsuranceCarrier', value);
    setInsuranceDetails(value);
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setMedicationInputValue(undefined);
    setNewChangedProvider(provider || undefined);
    setInputDiagnosis(diagnosis || '');
    setInsuranceDetails(primaryInsuranceCarrier || '');
    setInsuranceInput(insurance || defaultInsuranceDisplayValue);
  };

  const onTaskTemplateChange = (value: any) => {
    setSelectedTaskTemplate(value);
    onChange('taskTemplateId', value.id);
  };

  const onMedicationChange = (value: any) => {
    const medicationId = value?.id || null;
    onChange('medicationId', medicationId);
    setSelectedMedicationId(medicationId);
    setMedicationInputValue({
      name: value?.name || '',
      drugTier: value?.drugTier || null,
      label: value?.name || '',
    });

    const task = isReferral
      ? { ...value?.referralTaskTemplate, label: value?.referralTaskTemplate?.name, inputId: 'task' }
      : { ...value?.renewalTaskTemplate, label: value?.referralTaskTemplate?.name, inputId: 'task' };

    onTaskTemplateChange(task);
  };

  const handlePatientChange = (fieldName: string, value: any) => {
    onChange('patientId', value.id);
    setPatientData((v: any) => ({
      ...v,
      first_name: value.first_name,
      last_name: value.last_name,
      date_of_birth: value.date_of_birth,
      patient: {
        ...value,
      },
    }));
  };

  const handleProviderChange = (fieldName: string, value: any) => {
    onChange('providerId', value.id);
    setNewChangedProvider(value);
  };

  const providerIcon = isTemporaryProvider ? orangeProvider : providerPlusIcon;

  const hashSign = provider?.npi ? '#' : '';

  const onClickSave = () => {
    onSave();
  };

  const dateOfBirth = patient?.date_of_birth && format(ignoreTimezoneDate(patient?.date_of_birth), 'MM/dd/yyyy');

  const doseChangeHandler = debounce(
    (event: ChangeEvent<HTMLInputElement>) => onChange('dose', Number(event.target.value)),
    500,
  );

  return (
    <Box className={classes.patientDetails} data-id="patientDetails">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2">Patient Details</Typography>
        {isEditing || editReferralLoading ? (
          <Box>
            <Button
              disabled={editReferralLoading && patientDetailsLoading}
              size="small"
              onClick={handleCancelEditing}
              disableElevation
              variant="outlined"
            >
              <Typography variant="subtitle2">Cancel</Typography>
            </Button>
            <Button
              disabled={editReferralLoading && patientDetailsLoading}
              onClick={onClickSave}
              style={{ marginLeft: 16 }}
              size="small"
              disableElevation
              variant="contained"
            >
              {editReferralLoading && patientDetailsLoading ? (
                <>
                  Saving <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={16} />
                </>
              ) : (
                <Typography variant="subtitle2">Save</Typography>
              )}
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            {editReferralLoading && isEditing && (
              <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={18} />
            )}
            {patientDetailsOpen && (
              <IconButton onClick={() => setIsEditing(true)}>
                <img alt="" src={editIcon} />
              </IconButton>
            )}
            <IconButton onClick={() => openPatientDetails(!patientDetailsOpen)}>
              {!patientDetailsOpen ? (
                <KeyboardArrowDownIcon fontSize="large" />
              ) : (
                <KeyboardArrowUpIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
      {patientDetailsOpen && (
        <Box className={classes.referralDetails}>
          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ color: isTemporaryPatient ? '#ED6C0280' : 'inherit' }}
            >
              Patient
            </Typography>
          </Box>
          {isEditing || editReferralLoading ? (
            <PatientComponent
              handleChange={handlePatientChange}
              selectedPatientValue={patientData?.patient?.id ? patientData?.patient : patient}
              addedPatient={addedPatient}
              isTemporaryPatient={isTemporaryPatient}
              onAfterAdd={onAfterAdd}
              hideAddNew={isEditing}
              forceClose={forceClose}
              referralRegion={referralRegion}
            />
          ) : (
            <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editPatient">
              <ListView
                secondaryText={dateOfBirth}
                primaryText={`${patient?.first_name || ''} ${patient?.last_name || ''}`}
                icon={
                  <AccountCircleOutlinedIcon
                    fontSize={'large'}
                    style={{ fill: isTemporaryPatient ? '#ED6C0280' : '' }}
                  />
                }
              />
            </Box>
          )}

          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography variant="subtitle2" color={errors?.medicationId ? 'red' : 'textSecondary'}>
              Medication
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.labelInput}
            data-id="editMedication"
            style={{ marginRight: 16, width: 'calc(60% - 16px)' }}
          >
            {isEditing || editReferralLoading ? (
              <EditMedication
                initialValue={medicationInputValue || initialMedication}
                onChange={(e) => onMedicationChange(e)}
                status={status}
              />
            ) : (
              <ListView
                secondaryText={medicationTier?.name}
                primaryText={medication?.name || 'No medication'}
                icon={<img alt="" src={errors?.medicationId ? binImageError : binImage} />}
              />
            )}
          </Box>
          <FieldLabel>Dose</FieldLabel>
          <FieldBody className={classes.labelInput}>
            {isEditing ? (
              <CustomizedTextField
                startAdornment={<VaccinesOutlined />}
                defaultValue={dose}
                onChange={doseChangeHandler}
                type={'number'}
              />
            ) : (
              <ListView icon={<VaccinesOutlined />} primaryText={dose || ''} secondaryText="" />
            )}
          </FieldBody>
          <Box className={classes.label}>
            <Typography variant="subtitle2" color={errors?.taskTemplateId ? 'red' : 'textSecondary'}>
              Task Template
            </Typography>
          </Box>
          <Box style={{ width: 'calc(60% - 10px)' }} className={classes.labelInput} data-id="editTaskTemplate">
            {isEditing || editReferralLoading ? (
              <Tooltip
                placement="bottom"
                classes={{ popper: classes.popper, tooltipPlacementBottom: classes.popper, tooltip: classes.tooltip }}
                title={!selectedMedicationId ? `"Medication" is required to select "Task Template"` : ''}
              >
                <span style={{ width: '100%' }}>
                  <CustomizedAutocomplete
                    options={taskTemplatesData.map((option: any) => ({
                      ...option,
                      showDoneIcon: option.id === selectedTaskTemplate?.id,
                    }))}
                    loading={taskTemplates.loading}
                    hidePopup={false}
                    isOptionEqualToValue={(option, value) => option?.name === value}
                    label={selectedTaskTemplate?.name ? '' : 'Select'}
                    onChange={(_, value) => onTaskTemplateChange(value)}
                    placeholder="Select"
                    startAdornment={<img alt="" src={selectedMedicationId ? template : templateDisabled} />}
                    disabled={
                      (isEmpty(medication) && isEmpty(medicationInputValue)) ||
                      medicationInputValue?.name?.toLowerCase() === 'no medication'
                    }
                    style={{ cursor: !selectedMedicationId ? 'not-allowed' : '', marginRight: 6 }}
                    inputstyle={{
                      cursor: !selectedMedicationId ? 'not-allowed' : '',
                      color: !selectedMedicationId ? '#616161E5' : '',
                    }}
                    labelStyle={{
                      color: !selectedMedicationId ? '#616161E5' : '#1976D2',
                      cursor: !selectedMedicationId ? 'not-allowed' : '',
                    }}
                    value={selectedTaskTemplate?.name || ''}
                    getOptionDisabled={(option) => option?.disabled || option?.name === selectedTaskTemplate?.name}
                  />
                </span>
              </Tooltip>
            ) : (
              <ListView
                primaryText={taskTemplate?.name || ''}
                icon={<img src={errors?.taskTemplateId ? templateError : templateDisabled} alt="" />}
                secondaryText=""
              />
            )}
          </Box>
          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography
              variant="subtitle2"
              style={{ color: errors?.providerId ? 'red' : isTemporaryProvider ? '#ED6C0280' : '#6b778c' }}
            >
              Provider
            </Typography>
          </Box>

          {isEditing || editReferralLoading ? (
            <ProviderComponent
              clinicId={props.referral.clinicId}
              handleChange={handleProviderChange}
              selectedProviderValue={newChangedProvider || provider}
              onAfterChangeProvider={onAfterChangeProvider}
              changedProvider={newChangedProvider}
              isTemporaryProvider={isTemporaryProvider}
              existingWeInfuseProvider={provider}
              forceClose={forceClose}
            />
          ) : (
            <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editProvider">
              <ListView
                secondaryText={`${hashSign}${provider?.npi || ''}`}
                secondaryTextProps={{ color: 'primary' }}
                primaryText={`${provider?.firstName || ''} ${provider?.lastName || ''}${
                  provider?.credentials ? `, ${provider?.credentials}` : ''
                }`}
                icon={
                  <img
                    alt=""
                    src={errors?.providerId ? providerPlusIconErrored : providerIcon}
                    height={20}
                    width={20}
                  />
                }
              />
            </Box>
          )}

          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography variant="subtitle2" color="textSecondary">
              Diagnosis
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.labelInput}>
            {isEditing || editReferralLoading ? (
              <CustomizedTextField
                startAdornment={<img alt="" src={chartPrimary} />}
                handleInputChange={(e: any) => onChangeDiagnosis(e.target.value)}
                placeholder="Enter here"
                disabled={!!diagnosis}
                defaultValue={diagnosis || ''}
              />
            ) : (
              <ListView
                secondaryText={''}
                primaryText={inputDiagnosis?.toString()}
                icon={<img alt="" src={chartImage} />}
              />
            )}
          </Box>
          <FieldLabel>
            <Typography variant="subtitle2" color="textSecondary">
              Insurance
            </Typography>
          </FieldLabel>
          <FieldBody data-id="editInsuranceDropdown">
            {isEditing || editReferralLoading ? (
              <InsuranceSelect
                onChange={(value) => onChangeInsurance(value)}
                initialValue={insuranceInput}
                referralRegion={referralRegion}
              />
            ) : (
              <ListView
                secondaryText={''}
                primaryText={insuranceInput.name || ''}
                icon={<img alt="" src={insuranceIcon} />}
              />
            )}
          </FieldBody>
          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography variant="subtitle2" color="textSecondary">
              Insurance details
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.labelInput} data-id="editInsurance">
            {isEditing || editReferralLoading ? (
              <CustomizedTextField
                startAdornment={<img alt="" src={insuranceIconPrimary} />}
                handleInputChange={(e: any) => onChangeInsuranceDetails(e.target.value)}
                placeholder="Enter here"
                defaultValue={primaryInsuranceCarrier || ''}
              />
            ) : (
              <ListView
                secondaryText={''}
                primaryText={insuranceDetails || ''}
                icon={<img alt="" src={insuranceIcon} />}
              />
            )}
          </Box>
          <Box display="flex" alignItems="center" className={classes.label}>
            <Typography variant="subtitle2" color="textSecondary">
              Last Contacted
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" className={classes.labelInput} data-id="lblLastContacted">
            <ListView
              secondaryText={''}
              primaryText={
                lastPatientContactDate ? userFriendlyTimestamp(lastPatientContactDate, { hideTime: true }) : ''
              }
              icon={<img alt="" src={clockIcon} />}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PatientDetails;
