import { Box, Button, Divider, IconButton, TextField } from '@mui/material';
import { FC, Fragment, useState } from 'react';
import InsertCommentRoundedIcon from '@mui/icons-material/InsertCommentRounded';
import Delete from '../../images/Delete.svg';
import AddIcon from '@mui/icons-material/Add';
import MenuOption from '../MenuItemSearchComponent/MenuOption';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RequestedItem } from '../ReferralView/ReferralViewTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

interface Props extends RequestedItem {
  showDelete?: boolean;
  hideComment?: boolean;
  onDelete?: () => void;
  onChange: (values: Partial<RequestedItem>) => void;
}

const MedicalRequestItem: FC<Props> = (props: Props): JSX.Element => {
  const [commentOpen, setCommentOpen] = useState<boolean>(false);
  const { showDelete, onDelete, onChange, itemRequested, reasons, comments } = props;

  const { data: medicalRequestOptions } = useSelector(
    (state: RootState) => state.faxStore.medicalRecordsRequestOptions,
  );

  const options = medicalRequestOptions.map((option) => ({
    label: option.title,
    value: option.title,
    searchString: option.title,
  }));

  const onSelectOption = (value: any, isSelected: boolean) => {
    const newReasons = isSelected ? [...reasons, value] : reasons.filter((v) => v !== value);
    onChange({ reasons: newReasons });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          name="itemRequested"
          onChange={(e) => onChange({ [e.target.name]: e.target.value })}
          size="small"
          fullWidth
          multiline
          maxRows={7}
          inputProps={{ maxLength: 400 }}
          label="Enter Item Requested*"
          sx={{ flex: 1 }}
          variant="filled"
          value={itemRequested}
        />
        <Box width={48} display="flex" alignItems="center" justifyContent="flex-end" height={48}>
          {showDelete && (
            <IconButton onClick={onDelete}>
              <img alt="delete" src={Delete} />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: 4 }} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="Select Reason(s)*"
          variant="filled"
          fullWidth
          select
          SelectProps={{
            IconComponent: ExpandMoreIcon,
            multiple: true,
            value: reasons,
            renderValue: (value) => reasons.join(', '),
          }}
        >
          {options.map((option) => (
            <MenuOption
              key={option.value}
              onSelectOption={onSelectOption}
              option={option}
              selectedOptions={reasons}
              value={option.value}
            />
          ))}
        </TextField>
        <Box width={48} display="flex" alignItems="center" justifyContent="flex-end" height={48}>
          <IconButton onClick={() => setCommentOpen((v) => !v)}>
            <InsertCommentRoundedIcon
              fontSize={'large'}
              color="primary"
              style={{ color: props.comments ? '' : '#7A7A7A' }}
            />
          </IconButton>
        </Box>
      </Box>
      {commentOpen && (
        <Box sx={{ marginTop: 4 }} display="flex" justifyContent="space-between" alignItems="center">
          <TextField
            size="small"
            multiline
            minRows={2}
            name="comments"
            value={comments}
            fullWidth
            onChange={(e) => onChange({ [e.target.name]: e.target.value })}
            label="Add Comment"
            sx={{ flex: 1 }}
            variant="filled"
            inputProps={{
              maxLength: 300,
            }}
          />
          <Box width={48} height={48} />
        </Box>
      )}
    </Box>
  );
};

const defaultItem = {
  itemRequested: '',
  reasons: [],
  comments: '',
};

interface ItemsProps {
  onChange: (value: RequestedItem[]) => void;
  values?: RequestedItem[];
}
const MedicalRecordsRequestItems: FC<ItemsProps> = (props: ItemsProps): JSX.Element => {
  const { values } = props;
  const initialList = values?.length ? values : [defaultItem];
  const [medicalRequestItems, setMedicalRequestItems] = useState<RequestedItem[]>(initialList);

  const onAddRequestItem = () => {
    const items = [...medicalRequestItems, defaultItem];
    setMedicalRequestItems(items);
    props.onChange(items);
  };

  const onChange = (value: Partial<RequestedItem>, index: number) => {
    const output = medicalRequestItems.map((v, i) => (i === index ? { ...v, ...value } : v));
    setMedicalRequestItems(output);
    props.onChange(output);
  };

  const onDelete = (index: number) => {
    const items = medicalRequestItems.filter((_, i) => i !== index);
    setMedicalRequestItems(items);
    props.onChange(items);
  };
  return (
    <Box sx={{ minWidth: 500 }}>
      {medicalRequestItems.map((item, i) => (
        <Fragment key={i}>
          <MedicalRequestItem
            hideComment={i < medicalRequestItems.length - 1}
            onChange={(value) => onChange(value, i)}
            showDelete={i > 0}
            {...item}
            onDelete={() => onDelete(i)}
            key={i}
          />
          <Divider sx={{ padding: 2, marginBottom: 4 }} />
        </Fragment>
      ))}
      <Box sx={{ marginTop: 2 }}>
        <Button disabled={medicalRequestItems?.length >= 3} onClick={onAddRequestItem}>
          <AddIcon fontSize="large" sx={{ marginRight: 2 }} /> add another requested item
        </Button>
      </Box>
    </Box>
  );
};

export default MedicalRecordsRequestItems;
