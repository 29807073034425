import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
  return {
    comments: {
      marginTop: 32,
    },
    paddingBox: {
      padding: '24px 0',
    },
    commentsTitle: {
      marginTop: 16,
    },
    iconsExpandedView: {
      position: 'absolute' as 'absolute',
      right: 16,
      bottom: 42,
    },
    followUpWrapper: {
      position: 'absolute' as 'absolute',
      bottom: 18,
      left: 15,
    },
    topLevelFollowUpWrapper: {
      paddingBottom: 16,
    },
    syncTextWrapper: {
      position: 'absolute' as 'absolute',
      bottom: 15,
      right: 24,
    },
    syncText: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.38)',
    },
    chipSpan: {
      marginRight: '8px !important',
      padding: '3px 4px 3px 8px',
      display: 'flex',
    },
  };
});
export default useStyles;
