import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    row: {
      height: 56,
      background: 'rgba(0, 0, 0, 0.04)',
      marginTop: 8,
      paddingLeft: 16,
      color: 'rgba(237, 108, 2, 0.5)',
    },
  };
});
