import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => {
  return {
    root: {
      marginRight: 32,
    },
    menu: {
      width: 320,
      boxSizing: 'border-box',
    },
    chip: {
      marginBottom: '8px !important',
      marginRight: '8px !important',
      textTransform: 'capitalize',
    },
    label: {
      paddingLeft: '4px !important',
    },
  };
});
