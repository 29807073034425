import React, { FC, useEffect, useMemo, useState } from 'react';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import { useSelector } from 'react-redux';
import region from '../../images/region.svg';
import { RootState } from '../../redux/types';
import { Typography } from '@mui/material';

interface Props extends Partial<SelectMenuProps> {
  initialValue?: any;
  onChange: (item: any) => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  valueIcon?: React.ReactNode;
  showClearIcon?: boolean;
}

const EditRegion: FC<Props> = (props) => {
  const { initialValue, onChange, ...restProps } = props;

  const regionsState = useSelector((state: RootState) => state.regions.data);
  const [value, setValue] = useState(initialValue);

  const options = useMemo(() => {
    const regions = regionsState.filter((region: any) => region.id !== null);
    const formattedRegions = regions?.map((item: any) => {
      return {
        ...item,
        label: <span>{item.name}</span>,
        searchString: item.name,
      };
    });

    return formattedRegions;
  }, [regionsState]);

  useEffect(() => {
    if (options.length && initialValue) {
      const found = options.find((item) => item?.id === initialValue?.id);
      setValue(found);
      return;
    }
    setValue(initialValue);
  }, [initialValue, options]);

  const handleChange = (item: any) => {
    onChange(item);
    setValue(item);
  };

  const isSelectedOption = (option: any, value: any): boolean => option?.id === value?.id;

  return (
    <SelectMenu
      value={value}
      onChange={handleChange}
      onAddNew={console.log}
      handleSearch={console.log}
      hideAddnew
      showClearIcon
      searchLoading={false}
      valueIcon={<img src={region} alt="region" />}
      icon={<img src={region} alt="region" />}
      labelTransform={(val) => (
        <Typography style={{ marginTop: 5 }} variant="subtitle2" color="textPrimary">
          {val?.label}
        </Typography>
      )}
      options={options.map((option) => ({
        ...option,
        disabled: option?.disabled || isSelectedOption(option, value),
        showDoneIcon: isSelectedOption(option, value),
      }))}
      {...restProps}
    />
  );
};

export default EditRegion;
