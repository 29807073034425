import { Patient } from '../../../redux/types';
import { useState } from 'react';
import { debounce } from '@mui/material';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';

export type PatientSearchView = {
  searchText: {
    value: string;
    set: (value: string) => void;
  };
  initialSearchText?: string;
  options: Patient[];
  loading: boolean;
  select: (index: number) => void;
  selectPhone: (phoneNumber: PhoneNumber) => void;
  selected?: Patient;
  selectedPhone?: PhoneNumber;
  reset(): void;
};

export type PatientSource = {
  find(searchText: string, phoneTerm?: string): Promise<Patient[]>;
};

const INITIAL_STATE = {
  searchText: '',
  options: [] as Patient[],
  loading: false,
  selected: undefined as Patient | undefined,
  selectedPhone: undefined as PhoneNumber | undefined,
};

export function PatientSearchController(patientSource: PatientSource) {
  return function usePatientSearch(
    onChange: (patient?: Patient, phoneNumber?: PhoneNumber) => void,
    initialSearchText?: string,
    searchByPhone?: boolean,
  ): PatientSearchView {
    const [state, setState] = useState({
      ...INITIAL_STATE,
      searchText: initialSearchText || '',
    });

    return {
      searchText: {
        value: state.searchText,
        set: setSearchText,
      },
      initialSearchText: initialSearchText,
      options: state.options,
      loading: state.loading,
      select: selectPatient,
      selected: state.selected,
      selectPhone: selectPhone,
      selectedPhone: state.selectedPhone,
      reset,
    };

    function reset() {
      setState(INITIAL_STATE);
    }

    function setSearchText(value: string) {
      setState((state) => ({ ...state, searchText: value, loading: true }));
      debounce(() => refreshPatientOptions(value), 500)();
    }

    async function refreshPatientOptions(term: string) {
      let searchByNameTerm = term.trim();
      let searchByPhoneTerm = undefined;

      if (searchByPhone) {
        const letters = term.replace(/[^a-zA-Z]/g, '');
        if (letters.length === 0) {
          // search by phone only if there are no letters
          searchByPhoneTerm = term.replace(/\D/g, '').trim();
          searchByNameTerm = '';
        }
      }

      const options = await patientSource.find(searchByNameTerm, searchByPhoneTerm);
      setState((state) => ({
        ...state,
        options,
        loading: false,
      }));
    }

    function selectPatient(index: number) {
      const patient = state.options[index];
      setState((state) => ({
        ...state,
        selected: patient,
        selectedPhone: parsePhoneNumber(patient.mobile_phone || patient.home_phone || '', 'US'),
      }));
      onChange(patient, undefined);
    }

    function selectPhone(phoneNumber: PhoneNumber) {
      setState((state) => ({
        ...state,
        selectedPhone: phoneNumber,
        selected: undefined as Patient | undefined,
      }));
      onChange(undefined, phoneNumber);
    }
  };
}
