import { Skeleton } from '@mui/material';
import React from 'react';
import { useStyles } from './notificationsStyles';

interface Props {
  numberOfRows: number;
}

export const NotificationsSkeleton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { numberOfRows } = props;

  return (
    <>
      {Array(numberOfRows)
        .fill(3)
        .map((row, index) => {
          return (
            <Skeleton
              key={index + row}
              variant="rectangular"
              className={classes.notificationItemContainer}
              animation="wave"
            />
          );
        })}
    </>
  );
};
