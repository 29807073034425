import { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, ListItem, Tooltip, Typography, IconButton, CircularProgress } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useStyles } from './TasklistStyles';
import hazard from '../../images/hazard.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReferralStatus, RootState, StatusReason, Task, TaskFaxType } from '../../redux/types';
import { getTaskColors, getIdsFromArray, taskFaxStatuses } from '../../globalUtils/utils';
import CustomChip from '../CustomChip/CustomChip';
import EditAssignee from '../EditAssignee/EditAssignee';
import { format } from 'date-fns';
import CustomAvatar from '../Avatar/Avatar';
import { useGetTasksOptions, useScrollIntoView } from '../../globalUtils/hooks';
import { isEmpty } from 'lodash';
import DaysInStatus from './DaysInStatus';
import { getTasksCountAction, updateTask } from '../../redux/actions/tasksActions';
import ChangeTaskStatus from '../ChangeTaskStatus/ChangeTaskStatus';
import FaxAcknowledgement from './FaxAcknowledgement';
import alertIcon from '../../images/alert.svg';
import { useSearchParams } from 'react-router-dom';
import { getCustomLogic, StatusChangeHook } from '../../features/taskTypes/taskTypes';
import { archivedReferralStatuses } from '../../globalUtils/helpers';
import { getOneReferral } from '../../redux/actions/referralsActions';
import { FaxStatus } from '../Fax/FaxStatus';
import { FaxStatusIcon } from '../Fax/FaxStatusIcon';

export interface TaskItemProps extends Task {
  status: any;
  title: string;
  assignedUser?: any;
  id: string;
  statusReasons?: StatusReason[];
  statusReasonIds?: string[];
  createdAt: string;
  referralId: string;
  updatedAt?: string;
  statusUpdatedAt?: string;
  statusUpdatedByUserId?: any;
  completedByUser?: any;
  disabled?: boolean;
  avatarLoading?: boolean;
  isNew?: boolean;
  activeTab?: string;
  totalTasksLoaded?: number;
  handleEditReasonClick: (task: any) => void;
  showMedicationChangedAlert?: boolean;
  fax?: TaskFaxType;
  faxId?: string;
  referralFaxReceivedDate?: string;
  referralStatus?: ReferralStatus;
}

const TaskItem: FC<TaskItemProps> = (props) => {
  const {
    tasks: { filters },
    taskTemplates,
  } = useSelector((state: RootState) => state);
  const { reSendFaxAck } = useSelector((state: RootState) => state.faxStore);
  const [params] = useSearchParams();
  const [open, setOpen] = useState<boolean>(props.id === params.get('taskId'));
  const [errors] = useState<any>({});
  const [openSendFaxAck, setOpenSendFaxAck] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<boolean>(false);
  const {
    status,
    title,
    assignedUser,
    statusUpdatedByUserId,
    disabled,
    avatarLoading,
    isNew,
    statusUpdatedAt,
    activeTab,
    totalTasksLoaded,
    handleEditReasonClick,
    statusReasons,
    referral,
    showMedicationChangedAlert,
    fax,
    referralStatus,
    completedByUser,
  } = props;
  const users = useSelector((state: RootState) => state.users.data);
  const statusUpdatedByUser = users.find(({ id }) => id === statusUpdatedByUserId);
  const [selectedAssignedUser, setSelectedAssignedUser] = useState<any>(
    assignedUser || { firstName: '', lastName: '' },
  );
  const isTaskForArchivedReferral: boolean = useMemo(() => {
    return archivedReferralStatuses.includes(referralStatus?.title?.toLowerCase() || '');
  }, [referralStatus?.title]);

  const [searchParams] = useSearchParams();
  const currentOpenReferral = searchParams.get('referralId');

  useEffect(() => {
    if (currentOpenReferral !== referral.id) {
      setOpenSendFaxAck(false);
    }
  }, [currentOpenReferral, referral.id]);

  useScrollIntoView('isNew');
  useEffect(() => {
    setSelectedAssignedUser(assignedUser);
  }, [assignedUser]);

  const statusOptions = useGetTasksOptions('ALL');
  const defaultStatus = statusOptions.find((option: any) => option.id === status?.id);
  const [statusValue, setStatusValue] = useState(defaultStatus);
  const dispatch = useDispatch();
  const classes = useStyles({ open, errors, disabled, statusName: statusValue?.name || defaultStatus?.name });

  const fetchRelatedReferral = () => {
    if (referral?.id) {
      dispatch(getOneReferral(referral.id, true));
    }
  };

  const onUpdateTaskSuccess = () => {
    fetchRelatedReferral();
    if (activeTab) {
      dispatch(
        getTasksCountAction(
          {
            ...filters,
            active: true,
          },
          getIdsFromArray(taskTemplates?.archivedTabTaskStatuses),
          getIdsFromArray(taskTemplates?.openTabTaskStatuses),
        ),
      );
    }
  };

  useEffect(() => {
    const defaultStatus = statusOptions.find((option: any) => option.id === status?.id);
    if (!isEmpty(defaultStatus)) {
      setStatusValue(defaultStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const [isTimeoutExpired, setIsTimeoutExpired] = useState(false);

  const changeAssignee = (value: any) => {
    const params: any = { id: props.id, assignedUserId: value?.id };
    const onError = console.log;

    dispatch(updateTask(params, (data) => onUpdateTaskSuccess(), onError));
    setSelectedAssignedUser(value);
  };

  const onClickAccordionSummaryItem = (): void => {
    setOpen((previousValue: boolean) => {
      return !previousValue;
    });
  };

  useEffect(() => {
    let timer: any;
    if (isNew) {
      timer = setTimeout(() => {
        setIsTimeoutExpired(!isTimeoutExpired);
      }, 10000);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  const handleOpenSendFax = () => {
    setOpenSendFaxAck(true);
  };

  const handleRetrySendingFax = () => {
    setOpenSendFaxAck(true);
    setIsResend(true);
  };

  const handleCancelSendFax = () => {
    setOpenSendFaxAck(false);
    setIsResend(false);
  };

  const manualDelivery = fax?.status?.name?.toLowerCase() === taskFaxStatuses.MANUAL_DELIVERY;
  const deliveryInProgress = fax?.status?.name?.toLowerCase() === taskFaxStatuses.DELIVERY_IN_PROGRESS;
  const failedDelivery = fax?.status?.name?.toLowerCase() === taskFaxStatuses.FAILED_DELIVERY;
  const successfulDelivery = fax?.status?.name?.toLowerCase() === taskFaxStatuses.SUCCESSFUL_DELIVERY;
  const showSendBtn =
    !(
      deliveryInProgress ||
      successfulDelivery ||
      failedDelivery ||
      manualDelivery ||
      status?.name?.toLowerCase() === 'completed'
    ) ||
    // when status is changed from completed to any other after successful delivery, allow sending
    (status?.name?.toLowerCase() !== 'completed' && (successfulDelivery || manualDelivery));
  const isRetryBtnEnabled = failedDelivery; // && (fax?.id || faxId);
  const showRetryBtn = fax?.status && !(successfulDelivery || manualDelivery);

  const shouldShowDaysAgo = statusUpdatedAt && (statusValue?.name || defaultStatus?.name);

  const inactiveTaskMessage = 'Inactive tasks cannot be edited. To edit this task, change the referral status.';

  const customLogic = getCustomLogic(props);
  const changeTaskHook = {
    ...customLogic.statusChangeHook,
    component: null,
  } as StatusChangeHook;

  const showUpdatedByDetails =
    ['fax acknowledgement', 'order entry'].includes(title?.toLowerCase()) &&
    status?.name?.toLowerCase() === 'completed';

  const isFaxAcknowledgementTask = title?.toLowerCase() === 'fax acknowledgement';

  return (
    <>
      {isFaxAcknowledgementTask && (
        <FaxAcknowledgement
          open={openSendFaxAck}
          isResend={isResend}
          handleCancel={handleCancelSendFax}
          referral={referral}
          retrySendingFaxData={fax}
        />
      )}
      <ListItem
        disableGutters
        alignItems="flex-start"
        data-id="taskItem"
        style={{ paddingTop: 8, paddingBottom: 8, marginLeft: open ? 16 : 0, paddingRight: open ? 16 : 0 }}
      >
        <Accordion className={classes.taskItem} expanded={open} elevation={0} id={props.id} data-id="taskDetails">
          <AccordionSummary
            id={isNew ? 'isNew' : undefined}
            classes={{
              root: isNew && !isTimeoutExpired ? classes.newTask : classes.hovered,
              disabled: classes.disabled,
              content: classes.content,
            }}
            style={{ height: 32, paddingTop: 0, paddingBottom: 0 }}
          >
            <Box className={classes.summary} style={{ width: '100%', height: 32 }} display="flex">
              <Box flex={1} onClick={onClickAccordionSummaryItem}>
                <Tooltip
                  title={disabled ? inactiveTaskMessage : ''}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    className={disabled ? classes.disabledTitle : classes.title}
                    style={{ textOverflow: 'ellipsis' }}
                  >
                    <Typography
                      display="flex"
                      color={disabled ? 'textSecondary' : 'textPrimary'}
                      data-id="taskTitle"
                      className={classes.titleText}
                    >
                      {disabled && <img alt="" src={hazard} style={{ paddingRight: 5, paddingLeft: 16 }} />}
                      {title}
                    </Typography>
                    {!open && isFaxAcknowledgementTask && (
                      <Tooltip title={fax?.status?.name || ''}>
                        <IconButton>
                          <FaxStatusIcon faxStatus={fax?.status} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!open && customLogic.taskTitleIcon}
                  </Box>
                </Tooltip>
              </Box>
              {!open && (
                <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
                  {shouldShowDaysAgo && !open && (
                    <DaysInStatus
                      onClick={onClickAccordionSummaryItem}
                      statusName={status?.name}
                      isDisabled={disabled}
                      statusUpdatedAt={statusUpdatedAt}
                      tooltipTitle={format(new Date(statusUpdatedAt), 'LLL d, yyyy')}
                    />
                  )}
                  {(statusValue?.name || defaultStatus?.name) && (
                    <Tooltip
                      title={disabled ? inactiveTaskMessage : ''}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                    >
                      <span style={{ width: 160 }}>
                        <ChangeTaskStatus
                          task={props}
                          fax={fax}
                          activeTab={activeTab}
                          totalTasksLoaded={totalTasksLoaded}
                          disabled={disabled}
                          statusChangeHook={() => changeTaskHook}
                          referral={referral}
                          labelTransform={(value) => {
                            return (
                              <CustomChip
                                data-id="status"
                                size="small"
                                className={disabled ? classes.disabledChipTitle : classes.title}
                                style={{
                                  color: getTaskColors(statusValue?.name || defaultStatus?.name).textColor,
                                  border: getTaskColors(statusValue?.name || defaultStatus?.name).border,
                                }}
                                icon={
                                  <img alt="" src={getTaskColors(statusValue?.name || defaultStatus?.name).iconImage} />
                                }
                                {...getTaskColors(statusValue?.name || defaultStatus?.name)}
                                label={(statusValue?.name || defaultStatus?.name)?.toLowerCase()}
                              />
                            );
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                  <CustomAvatar
                    loading={avatarLoading}
                    name={`${selectedAssignedUser?.firstName || ''} ${selectedAssignedUser?.lastName || ''}`}
                    style={{ width: 30, height: 30, marginLeft: 24, fontSize: 15 }}
                    onClick={onClickAccordionSummaryItem}
                  />
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {showMedicationChangedAlert && (
                <Alert color="warning" icon={<img src={alertIcon} height={20} width={20} alt="" />}>
                  Linked orders(s) are no longer valid because the Referral Medication has changed
                </Alert>
              )}
              <Box alignItems="baseline" style={{ minHeight: 48 }} display="flex">
                <Box alignItems="center" flex={1} style={{ width: '50%' }}>
                  <Typography variant="body2" color="#00000099" fontWeight={500} fontSize={14}>
                    Status
                  </Typography>
                </Box>
                <Tooltip
                  title={disabled ? inactiveTaskMessage : ''}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <Box flex={1} style={{ width: '50%' }} data-id="taskStatusDetails">
                    <ChangeTaskStatus
                      task={props}
                      fax={fax}
                      activeTab={activeTab}
                      totalTasksLoaded={totalTasksLoaded}
                      valueIcon={<img alt="" src={getTaskColors(statusValue?.name || defaultStatus?.name).iconImage} />}
                      icon={<img alt="" src={getTaskColors(statusValue?.name || defaultStatus?.name).iconImage} />}
                      disabled={disabled}
                      statusChangeHook={() => changeTaskHook}
                      hideDefaultStyles
                      spacing={4}
                      referral={referral}
                      labelTransform={(value) => (
                        <Tooltip
                          title={
                            statusUpdatedAt && shouldShowDaysAgo ? format(new Date(statusUpdatedAt), 'LLL d, yyyy') : ''
                          }
                        >
                          <Box display="flex" flexDirection="column">
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              color={disabled ? 'textSecondary' : '#000000DE'}
                              variant="body2"
                              fontWeight={500}
                              fontSize={14}
                            >
                              <span style={{ paddingRight: 10 }}>{value?.name?.toLowerCase()}</span>
                              {shouldShowDaysAgo && (
                                <DaysInStatus
                                  statusName={status?.name}
                                  statusUpdatedAt={statusUpdatedAt}
                                  isDisabled={disabled}
                                />
                              )}
                            </Typography>
                            {/* {value?.name === 'WAITING' && (
                              <Box display="flex" flexDirection="column">
                                {statusReasons?.map((reason: StatusReason) => (
                                  <Typography variant="body2" color="textSecondary" key={reason.id}>
                                    {reason.title}
                                  </Typography>
                                ))}
                                <Typography variant="caption" color="textSecondary">
                                  {statusUpdatedByUser?.firstName || ''} {statusUpdatedByUser?.lastName || ''} on{' '}
                                  {props?.updatedAt && format(new Date(props?.updatedAt), 'LLL d, yyyy')}
                                </Typography>
                              </Box>
                            )} */}
                          </Box>
                        </Tooltip>
                      )}
                    />
                    {status?.name === 'WAITING' && (
                      <Box display="flex" flexDirection="column" style={{ paddingLeft: 45 }}>
                        {statusReasons?.map((reason: StatusReason) => (
                          <Typography variant="body2" key={reason.id}>
                            {reason.title}
                          </Typography>
                        ))}
                        <Typography variant="caption" color="textSecondary">
                          {statusUpdatedByUser?.firstName || ''} {statusUpdatedByUser?.lastName || ''} on{' '}
                          {props?.updatedAt && format(new Date(props?.updatedAt), 'LLL d, yyyy')}
                        </Typography>
                      </Box>
                    )}
                    {status?.name?.toLowerCase() === 'waiting' && !isTaskForArchivedReferral && (
                      <Button onClick={handleEditReasonClick} disableRipple style={{ marginLeft: 38, marginTop: 12 }}>
                        Edit reason
                      </Button>
                    )}
                    {showUpdatedByDetails && (
                      <>
                        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 36 }}>
                          {completedByUser?.firstName || ''} {completedByUser?.lastName || ''} on{' '}
                          {props?.updatedAt && format(new Date(props?.updatedAt), 'LLL d, yyyy h:mm a')}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Tooltip>
              </Box>
              <Box alignItems="center" style={{ minHeight: 48 }} display="flex">
                <Box flex={1} alignItems="center" style={{ width: '50%' }}>
                  <Typography variant="body2" color="#00000099" fontSize={14} fontWeight={500}>
                    Assignee
                  </Typography>
                </Box>
                <Tooltip
                  title={disabled ? inactiveTaskMessage : ''}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <Box flex={1} display="flex" alignItems="center" style={{ width: '50%' }} data-id="taskAssignee">
                    <EditAssignee
                      initialValue={assignedUser}
                      label="Unassigned"
                      onChange={(item: any) => changeAssignee(item)}
                      isErrored={false}
                      disabled={disabled}
                      showClearIcon={false}
                      spacing={8}
                      filterOutInactiveAssignees
                    />
                  </Box>
                </Tooltip>
              </Box>
              {customLogic.taskBody}
              {customLogic.statusChangeHook.component}
              {isFaxAcknowledgementTask && (
                <>
                  <FaxStatus fax={fax} />
                  {(showSendBtn || showRetryBtn) && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ minHeight: 62, paddingTop: 16 }}
                    >
                      {showSendBtn && (
                        <Button disabled={disabled} onClick={handleOpenSendFax} variant="contained">
                          Send Fax
                        </Button>
                      )}
                      {showRetryBtn && (
                        <Button
                          disabled={!isRetryBtnEnabled || reSendFaxAck?.loading || disabled}
                          onClick={handleRetrySendingFax}
                          variant="contained"
                        >
                          Retry Sending Fax {reSendFaxAck?.loading && <CircularProgress size={16} />}
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </>
  );
};

export default TaskItem;
