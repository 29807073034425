import { BaseAction, drugTiersState } from '../types';
import { GET_DRUG_TIERS, GET_DRUG_TIERS_SUCCESS, GET_DRUG_TIERS_ERROR } from '../constants/referralTypes';

const initialState: drugTiersState = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_DRUG_TIERS:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_DRUG_TIERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_DRUG_TIERS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
