import { MessageServer } from './message-server';
import axios, { AxiosError } from 'axios';
import { Patient } from '../../../redux/types';
import { TextMessage } from '../text-message';
import { MessageTemplate } from '../send-sms-controller';
import { Conversation, ConversationStatus } from '@features/Omnichannel/conversation';

export function HttpMessageServer(baseUrl: string): MessageServer {
  return {
    async getPatientHistory(patient: Patient): Promise<TextMessage[]> {
      if (!patient.mobile_phone && !patient.home_phone) {
        return [];
      }
      return this.getHistoryByPhone(patient.mobile_phone || patient.home_phone || '');
    },
    async send(phoneNumber: string, message: string): Promise<TextMessage> {
      try {
        const res = await axios.post(`${baseUrl}/messages/sms`, {
          to: phoneNumber,
          body: message,
        });
        return {
          ...res.data,
          dateSent: new Date(res.data.dateSent),
        } as TextMessage;
      } catch (e) {
        const axiosError = e as AxiosError;
        if (axiosError.isAxiosError) {
          throw new Error(axiosError.response?.data?.message);
        }
        throw e;
      }
    },
    async getHistory(startDate: Date, endDate: Date): Promise<TextMessage[]> {
      const res = await axios.get(
        `${baseUrl}/messages/sms/history?dateSentAfter=${startDate.toUTCString()}&dateSentBefore=${endDate.toUTCString()}`,
      );
      return res.data;
    },
    async getHistoryByPhone(phoneNumber: string): Promise<TextMessage[]> {
      if (!phoneNumber) {
        return [];
      }
      const response = await axios.get(`${baseUrl}/messages/sms/history/${encodeURIComponent(phoneNumber)}`);

      return (response.data as TextMessage[]).map((message) => ({
        ...message,
        dateSent: new Date(message.dateSent),
      }));
    },
    async getDashboardConversations(status?: ConversationStatus, term?: string): Promise<Conversation[]> {
      const res = await axios.get(
        `${baseUrl}/messages/sms/dashboard?status=${status}&term=${encodeURIComponent(term || '')}&pageSize=200`,
      );
      return (res.data as Conversation[]).map((conversation) => ({
        ...conversation,
        lastMessage: {
          ...conversation.lastMessage,
          dateSent: new Date(conversation.lastMessage.dateSent),
        },
      }));
    },
    async getTemplates({ patientId, referralId }): Promise<MessageTemplate[]> {
      const res = await axios.get(
        `${baseUrl}/messages/templates?patientId=${patientId ?? ''}&referralId=${referralId ?? ''}`,
      );
      return res.data;
    },
  };
}
