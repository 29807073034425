import { AuthState, BaseAction } from '../types';
import {
  LOAD_TOKEN,
  LOAD_IDENTITY_INFO,
  LOAD_REFRESH_TOKEN,
  SET_AUTH_REDIRECT_ROUTE,
  LOGIN_LOADING,
} from '../constants/authTypes';
import { LOGIN_REDIRECT_ROUTE_LOCALSTORAGE_KEY } from '../../globalUtils/helpers';

const initialState: AuthState = {
  token: localStorage.getItem('token') || '',
  identityInfo: JSON.parse(localStorage.getItem('identityInfo') || '{}'),
  refreshToken: localStorage.getItem('refreshToken') || '',
  redirectRoute: localStorage.getItem(LOGIN_REDIRECT_ROUTE_LOCALSTORAGE_KEY) || '',
  loginLoading: false,
};

export const loginReducer = (state: AuthState = initialState, action: BaseAction): AuthState => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.payload,
      };
    case LOAD_REFRESH_TOKEN:
      const refreshToken = action.payload;
      localStorage.setItem('refreshToken', refreshToken);
      return {
        ...state,
        refreshToken,
      };
    case LOAD_TOKEN:
      const token = action.payload;
      localStorage.setItem('token', token);
      return {
        ...state,
        token,
      };
    case LOAD_IDENTITY_INFO:
      const identityInfo = action.payload;
      localStorage.setItem('identityInfo', JSON.stringify(identityInfo));
      return {
        ...state,
        identityInfo,
      };
    case SET_AUTH_REDIRECT_ROUTE:
      return {
        ...state,
        redirectRoute: action.payload,
      };
  }
  return state;
};
