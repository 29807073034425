import React, { FC, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useStyles } from './AddPatientStyles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { editPatientAction } from '../../redux/actions/patientsActions';
import { Props } from './AddPatientTypes';
import { validate } from 'validate.js';
import alertIcon from '../../images/alert.svg';
import SelectMenu from '../SelectMenu/SelectMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { ignoreTimezoneDate } from '../../globalUtils/utils';
import { EditPatientParams } from '../../api/patientsApi';
import TextField from '../TextField';

const AddPatient: FC<Props> = (props) => {
  const { open, onOpenChange, onAfterAdd, initialValues, isEditingTempPatient } = props;
  const classes = useStyles();
  const [values, setValues] = useState<EditPatientParams>({
    date_of_birth: undefined,
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const [errors, setErrors] = useState<any>({});

  const { addPatient, editedPatient } = useSelector((state: RootState) => state.patients);

  const validationSchema: any = {
    first_name: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    last_name: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    // date_of_birth: {
    //   presence: {
    //     allowEmpty: false,
    //     message: 'is required',
    //   },
    // },
    // sex: {
    //   presence: {
    //     allowEmpty: false,
    //     message: 'is required',
    //   },
  };

  useEffect(() => {
    if (!open) {
      setValues({
        date_of_birth: undefined,
        first_name: '',
        last_name: '',
        ...initialValues,
      });

      setErrors({});
    }
  }, [open, initialValues]);
  const dispatch = useDispatch();
  const onAddPatient = () => {
    const validationErrors: any = validate(values, validationSchema);

    setErrors(() => (validationErrors ? validationErrors : {}));

    if (!validationErrors) {
      onOpenChange(false);
      onAfterAdd({
        isTemporary: true,
        first_name: values.first_name,
        last_name: values.last_name,
        date_of_birth: values.date_of_birth,
        sex: values.sex,
      });
    }
  };

  const onEditPatient = () => {
    const validationErrors: any = validate(values, validationSchema);

    setErrors(() => (validationErrors ? validationErrors : {}));

    if (!validationErrors) {
      const onSuccess = (data: any) => {
        onOpenChange(false);
        onAfterAdd(data);
      };

      dispatch(
        editPatientAction(
          {
            isTemporary: true,
            first_name: values.first_name,
            last_name: values.last_name,
            date_of_birth: values.date_of_birth,
            sex: values.sex,
            id: values.id,
          },
          onSuccess,
        ),
      );
    }
  };

  const onChange = (name: string, value: string | Date | null) => {
    setErrors((previousValue: any) => ({
      ...previousValue,
      [name]: '',
    }));

    setValues((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const genderOptions = [
    {
      label: 'Male',
      value: 'Male',
      id: 'Male',
      searchString: '',
    },
    {
      label: 'Female',
      value: 'Female',
      id: 'Female',
      searchString: '',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
      id: 'Unknown',
      searchString: '',
    },
  ];

  const onChangeGender = (value: any) => {
    onChange('sex', value.value);
  };

  const [sexOpen, setSexOpen] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = useState<any>(null);

  return (
    <Dialog fullWidth open={open} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>Temporary Patient</DialogTitle>
      <DialogContent>
        <Alert color="error" icon={<img src={alertIcon} alt="alert" height={20} width={20} />}>
          <AlertTitle>Existing Patient Will Be Required</AlertTitle>
          Existing WeInfuse patient selected from the dropdown list will be required to change the status of this
          referral to "Ready to Schedule”.
        </Alert>

        <Typography variant="body2" style={{ padding: '16px 0' }}>
          Patient Details
        </Typography>
        <TextField
          onChange={(e) => onChange('first_name', e.target.value)}
          value={values.first_name}
          id="first-name"
          label="First Name"
          variant="filled"
          required
          fullWidth
          helperText={errors.first_name}
          error={!!errors.first_name}
          color={errors.first_name ? 'error' : 'primary'}
          FormHelperTextProps={{ error: !!errors.first_name }}
          inputProps={{ maxLength: 80 }}
          InputProps={{ disableUnderline: true }}
        />
        <Box className={classes.input} />
        <TextField
          onChange={(e) => onChange('last_name', e.target.value)}
          value={values.last_name}
          id="last-name"
          label="Last Name"
          variant="filled"
          required
          fullWidth
          inputProps={{ maxLength: 80 }}
          helperText={errors.last_name}
          error={!!errors.last_name}
          color={errors.last_name ? 'error' : 'primary'}
          FormHelperTextProps={{ error: !!errors.last_name }}
          InputProps={{ disableUnderline: true }}
        />
        <Box className={classes.input} />
        <DesktopDatePicker
          views={['year', 'month', 'day']}
          label="Date of Birth"
          inputFormat="MM/dd/yyyy"
          value={values.date_of_birth ? ignoreTimezoneDate(values.date_of_birth) : null}
          onChange={(value: any) => onChange('date_of_birth', value)}
          renderInput={(params: any) => (
            <TextField
              data-id="dateOfBirth"
              fullWidth
              {...params}
              variant="filled"
              onClick={(event) => setDateAnchorEl(event.currentTarget)}
              helperText={errors.date_of_birth}
              error={!!errors.date_of_birth}
              color={errors.date_of_birth ? 'error' : 'primary'}
              FormHelperTextProps={{ error: !!errors.date_of_birth }}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
          PopperProps={{
            anchorEl: dateAnchorEl,
          }}
        />
        <Box className={classes.input} />
        {/*<Box*/}
        {/*  data-id="gender"*/}
        {/*  display="flex"*/}
        {/*  alignItems="center"*/}
        {/*  style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', padding: 0, height: 56, paddingLeft: 12 }}*/}
        {/*>*/}
        <SelectMenu
          expandMoreIcon={<ArrowDropDownIcon style={{ color: '#6b778c', fontSize: 26, paddingRight: 10 }} />}
          showExpandMore
          label={
            <Typography data-id="lblSex" color={errors.sex ? 'error' : 'textSecondary'}>
              Sex
            </Typography>
          }
          hideAddnew
          hideSearch
          onClicking={() => setSexOpen(true)}
          onClose={() => setSexOpen(false)}
          options={genderOptions}
          value={genderOptions.find(({ value }) => value === values.sex) || {}}
          onChange={onChangeGender}
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)', height: 56, paddingLeft: 12 }}
          labelTransform={(val) => {
            return (
              <Box style={{ paddingBottom: 8 }}>
                <Typography variant="caption" color={sexOpen ? 'primary' : 'textSecondary'}>
                  Sex
                </Typography>
                <Typography style={{ lineHeight: '16px' }} color="textSecondary">
                  {val.label}
                </Typography>
              </Box>
            );
          }}
        />
        {/*</Box>*/}
        {errors.sex && (
          <Typography style={{ margin: '3px 14px' }} variant="caption" color="error">
            Sex is required
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={addPatient?.loading} onClick={() => onOpenChange(false)}>
          Cancel
        </Button>
        <Button
          disabled={addPatient?.loading || editedPatient?.loading}
          onClick={isEditingTempPatient ? onEditPatient : onAddPatient}
          variant="contained"
          data-id="btnAddPatient"
        >
          {isEditingTempPatient ? 'Save' : 'Add patient'}
          {(addPatient?.loading || editedPatient?.loading) && <CircularProgress size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPatient;
