import axios, { AxiosResponse } from 'axios';

export const loginApi = async (code: string, session: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/auth/token`, { code, session_state: session });
};

export const refreshTokenApi = async (refreshToken: string): Promise<AxiosResponse<any, any>> => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  return await axios.post(`${baseUrl}/auth/refresh`, { refreshToken });
};

export const logoutApi = async (): Promise<AxiosResponse<any, any>> => {
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${
    process.env.REACT_APP_REDIRECT_URI || ''
  }`;
  return await axios.get(url);
};
