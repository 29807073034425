import { FETCH_REGIONS_LOADING, LOAD_REGIONS_ERROR, LOAD_REGIONS_TO_STORE } from '../constants/inboxTypes';
import { Region } from '../types';
import { Dispatch } from 'redux';
import { fetchRegionsApi } from '../../api/regionsApi';

const regionsLoading = (payload: boolean) => ({
  type: FETCH_REGIONS_LOADING,
  payload,
});

const loadRegionsToStore = (payload: Region) => ({
  type: LOAD_REGIONS_TO_STORE,
  payload,
});

const loadRegionsErrorToStore = (payload: any) => ({
  type: LOAD_REGIONS_ERROR,
  payload,
});

export const fetchRegionsAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(regionsLoading(true));
    const response = await fetchRegionsApi();
    dispatch(loadRegionsToStore(response.data));
    dispatch(regionsLoading(false));
  } catch (e) {
    dispatch(loadRegionsErrorToStore(e));
    dispatch(regionsLoading(false));
  }
};
