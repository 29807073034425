import { Dispatch } from 'redux';
import { loginApi, refreshTokenApi } from '../../api/authApi';
import { logoutAction } from '../../globalUtils/utils';
import {
  LOAD_IDENTITY_INFO,
  LOAD_REFRESH_TOKEN,
  LOAD_TOKEN,
  SET_AUTH_REDIRECT_ROUTE,
  LOGIN_LOADING,
} from '../constants/authTypes';
import jwtDecode from 'jwt-decode';
import { LOGIN_REDIRECT_ROUTE_LOCALSTORAGE_KEY } from '../../globalUtils/helpers';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';

export const loadTokenToStore = (token: string) => {
  return {
    payload: token,
    type: LOAD_TOKEN,
  };
};
export const loadRefreshToStore = (token: string) => {
  return {
    payload: token,
    type: LOAD_REFRESH_TOKEN,
  };
};

export const loadIdentityInfo = (data: object) => {
  return {
    payload: data,
    type: LOAD_IDENTITY_INFO,
  };
};

export const loginLoading = (isLoading: boolean) => {
  return { payload: isLoading, type: LOGIN_LOADING };
};

export const login = (code: string, session: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginLoading(true));
    const { data } = (await loginApi(code, session)) as any;
    dispatch(loginLoading(false));
    dispatch(loadTokenToStore(data.accessToken || ''));
    dispatch(loadRefreshToStore(data.refreshToken || ''));
    const decoded = jwtDecode(data.accessToken) as any;
    dispatch(loadIdentityInfo(decoded || {}));
  } catch (error: any) {
    dispatch(loginLoading(false));
    const errorMessage = backendErrorHandler(error);
    showErrorMessage(errorMessage);
    console.log(errorMessage);
  }
};

export const refreshJWT =
  (token: string, callback?: any, onStartingRequest?: () => void) => async (dispatch: Dispatch) => {
    try {
      if (onStartingRequest) {
        onStartingRequest();
      }
      const { data } = (await refreshTokenApi(token)) as any;
      dispatch(loadTokenToStore(data.accessToken || ''));
      if (callback) {
        callback();
      }
    } catch (error: any) {
      logoutAction()(dispatch);
      if (callback) {
        callback();
      }
    }
  };

export const setAuthRedirectRoute = (redirectRoute?: string) => {
  localStorage.setItem(LOGIN_REDIRECT_ROUTE_LOCALSTORAGE_KEY, redirectRoute || '');

  return {
    type: SET_AUTH_REDIRECT_ROUTE,
    payload: redirectRoute,
  };
};
