import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    viewTransform: {
      textAlign: 'left' as 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    labelInput: {
      // marginBottom: 16, 414, 270
      display: 'flex',
      width: '60%',
      alignItems: 'center',
      paddingRight: 0,
      marginRight: 0,
      height: 56,
    },
  };
});
