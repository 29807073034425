import { Dispatch } from 'redux';
import { attachmentsProps, removeAttachmentApi } from '../../api/inboxApi';
import { getAttachmentCategoriesApi, uploadAttachmentToWeInfuseApi } from '../../api/referralsApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { setAttachmentBeingUploadedToWeInfuseLoading } from '../actionCreators/weInfuseActionCreators';
import {
  GET_ATTACHMENTS_CATEGORIES,
  GET_ATTACHMENTS_CATEGORIES_SUCCESS,
  GET_ATTACHMENTS_CATEGORIES_ERROR,
  REMOVE_ATTACHMENT,
  REMOVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_ERROR,
} from '../constants/referralTypes';

const getAttachmentCategoriesLoading = () => ({
  type: GET_ATTACHMENTS_CATEGORIES,
});

export interface AttachmentCategoriesParams {
  id: string;
  name: string;
  description: string;
  createdAt: string | Date;
  updatedAt: string | Date;
}
const getAttachmentCategoriesSuccess = (payload: AttachmentCategoriesParams[]) => ({
  type: GET_ATTACHMENTS_CATEGORIES_SUCCESS,
  payload,
});

const getAttachmentCategoriesError = (payload: any) => ({
  type: GET_ATTACHMENTS_CATEGORIES_ERROR,
  payload,
});

const removeAttachment = () => ({
  type: REMOVE_ATTACHMENT,
});

const removeAttachmentSuccess = (payload: any) => ({
  type: REMOVE_ATTACHMENT_SUCCESS,
  payload,
});

const removeAttachmentError = (payload: any) => ({
  type: REMOVE_ATTACHMENT_ERROR,
  payload,
});

export const getAttachmentCategoriesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getAttachmentCategoriesLoading());
    const response = await getAttachmentCategoriesApi();
    dispatch(getAttachmentCategoriesSuccess(response.data));
  } catch (e) {
    dispatch(getAttachmentCategoriesError(e));
  }
};

export const removeFileAction =
  (params: attachmentsProps, onSuccess?: (file: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(removeAttachment());
      const response = await removeAttachmentApi(params);
      dispatch(removeAttachmentSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(removeAttachmentError(e?.response?.data));
    }
  };

export const uploadAttachmentToWeInfuseAction =
  (referralId: string, attachmentId: string, onSuccess?: () => void) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, true));
      await uploadAttachmentToWeInfuseApi(referralId, attachmentId, {
        publish: true,
      });
      onSuccess && onSuccess();
    } catch (error) {
      const errorMessage = backendErrorHandler(error);
      showErrorMessage(errorMessage);
      dispatch(setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, false));
    } finally {
      // Removing this so that it is executed in the callback i.e when all other async callbacks are removed
      // dispatch(setAttachmentBeingUploadedToWeInfuseLoading(attachmentId, false));
    }
  };
