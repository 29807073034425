import { MessageServer } from './message-server/message-server';
import { useState } from 'react';
import { TextMessage } from './text-message';
import { useIsMounted } from '@/globalUtils/hooks';

export type SendSmsReturnType = ReturnType<ReturnType<typeof SendSmsController>>;

export type SmsControllerProps = {
  onSendSuccess?: (textMessage: TextMessage) => void;
  onSendFailure?: (errorMsg: string) => void;
  templates: MessageTemplate[];
  phoneNumber?: string;
};

export interface MessageTemplate {
  name: string;
  content: string;
}

export function SendSmsController(messageServer: MessageServer) {
  return function useSendSmsController(props: SmsControllerProps) {
    const { onSendSuccess = undefined, onSendFailure = undefined, templates = [] } = props;
    const isMounted = useIsMounted();

    const initialState = {
      message: '',
      status: '',
      error: '',
      sending: false,
      template: null as MessageTemplate | null,
    };

    const [state, setState] = useState(initialState);
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || '');

    return {
      status: {
        visible: state.status.length > 0,
        message: state.status,
      },
      error: {
        visible: state.error.length > 0,
        message: state.error,
      },
      phoneNumber: {
        value: phoneNumber,
        set: setPhoneNumber,
      },
      message: {
        value: state.message,
        set: setMessage,
      },
      sendButton: {
        click: send,
        loading: state.sending,
      },
      templates: templates.map((template) => ({
        label: template.name,
        selected: state.template === template,
        click: () => selectTemplate(template),
      })),
      clean: clean,
    };

    function send() {
      const error = getFormErrors();
      if (error) {
        setState((state) => ({
          ...state,
          error,
        }));
        onSendFailure && onSendFailure(error);
      } else {
        setState((state) => ({
          ...state,
          sending: true,
        }));

        (async () => {
          try {
            const message = await messageServer.send(phoneNumber, state.message);
            if (isMounted) {
              setState((state) => ({
                ...state,
                status: 'Your message was sent',
                message: '',
                sending: false,
                error: '',
                template: null,
              }));
            }
            onSendSuccess && onSendSuccess(message);
          } catch (e) {
            const errorMessage = getErrorMessage(e);
            if (isMounted) {
              setState((state) => ({
                ...state,
                status: errorMessage,
                sending: false,
              }));
            }
            onSendFailure && onSendFailure(errorMessage);
          }
        })();
      }

      function getErrorMessage(e: unknown) {
        if (e instanceof Error) {
          return e.message;
        }
        console.error(e);
        return 'Unknown error';
      }
    }

    function selectTemplate(template: MessageTemplate) {
      setState((state) => ({
        ...state,
        template,
        message: template.content,
      }));
    }

    function setMessage(value: string) {
      setState({
        ...state,
        message: value,
      });
    }

    function getFormErrors() {
      if (phoneNumber.length < 10) return 'Please provide a valid phone number';
      if (state.message.length === 0) return 'Please provide a message';
      if (state.message.length > 1500) return 'Message is too long';
      if (/\[([^)]+)]/.test(state.message))
        return 'Please fill in all message parameters.  They are denoted by square brackets, for example: [Name]';
      return '';
    }

    function clean() {
      setState(initialState);
    }
  };
}
