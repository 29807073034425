import { tasksSortCompareFunction } from '../../globalUtils/helpers';
import {
  BULK_UPDATE_TASKS,
  UPDATE_TASKLIST_FILTERS,
  UPDATE_TASK_LIST_ITEM,
  ADD_NEW_TASK,
  ADD_NEW_TASK_SUCCESS,
  ADD_NEW_TASK_ERROR,
  GET_TASK_TYPES_LOADING,
  GET_TASK_TYPES_SUCCESS,
  GET_TASK_TYPES_ERROR,
  CLEAR_TASK_TYPES_STATE,
  GET_TASKS_COUNT,
  GET_ARCHIVED_TASKS_SUCCESS,
  GET_OPEN_TASKS_SUCCESS,
  GET_OPEN_TASKS_LOADING,
  GET_ARCHIVED_TASKS_LOADING,
  GET_OPEN_TASKS_ERROR,
  GET_ARCHIVED_TASKS_ERROR,
  SET_OPEN_TASKS_GLOBAL_LOADING,
  SET_ARCHIVED_TASKS_GLOBAL_LOADING,
  SET_SPECIFIC_TASK_UPDATE_LOADING,
} from '../constants/tasksTypes';
import { BaseAction, Task, TasksState, TaskType } from '../types';

export const initialState: TasksState = {
  openTasks: {
    loading: false,
    data: [],
    error: undefined,
    globalLoading: true,
    hasMore: false,
    page: 0,
    pageSize: 0,
    offset: 0,
  },
  archivedTasks: {
    loading: false,
    data: [],
    error: undefined,
    globalLoading: true,
    hasMore: false,
    page: 0,
    pageSize: 0,
    offset: 0,
  },
  filters: {
    search: '',
    sortDirectionOne: 'DESC',
    sortFieldOne: 'createdAt',
    sortDirectionTwo: 'ASC',
    sortFieldTwo: 'sortOrder',
    assignedUserIds: [],
    taskStatuses: [],
    sortByReferral: true,
    active: true,
    taskTypeIds: [],
    referralStatuses: [],
  },
  addNewTask: {
    loading: false,
    data: [],
    success: false,
    error: null,
  },
  taskTypes: {
    loading: false,
    data: [],
    success: false,
    error: null,
  },
  taskBeingUpdated: {
    taskId: '',
    loading: false,
  },
  archivedTotalCount: 0,
  openTotalCount: 0,
};

const tasksReducer = (state: TasksState = initialState, action: BaseAction): TasksState => {
  switch (action.type) {
    case GET_OPEN_TASKS_LOADING:
      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          loading: action.payload,
        },
      };
    case GET_ARCHIVED_TASKS_LOADING:
      return {
        ...state,
        archivedTasks: {
          ...state.archivedTasks,
          loading: action.payload,
        },
      };
    case GET_ARCHIVED_TASKS_SUCCESS:
      const archivedTasksData: Task[] =
        action.payload?.page === 1 ? action?.payload?.data : [...state.archivedTasks.data, ...action?.payload?.data];

      return {
        ...state,
        archivedTasks: {
          ...state.archivedTasks,
          ...action.payload,
          data: archivedTasksData,
          hasMore: action.payload?.totalCount > archivedTasksData.length,
        },
      };
    case GET_OPEN_TASKS_SUCCESS:
      const openTasksData: Task[] =
        action.payload?.page === 1 ? action?.payload?.data : [...state.openTasks.data, ...action?.payload?.data];

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          ...action.payload,
          data: openTasksData,
          hasMore: action.payload?.totalCount > openTasksData.length,
        },
      };
    case GET_OPEN_TASKS_ERROR:
      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          error: action.payload,
        },
      };
    case GET_ARCHIVED_TASKS_ERROR:
      return {
        ...state,
        archivedTasks: action.payload,
      };
    case UPDATE_TASKLIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case UPDATE_TASK_LIST_ITEM:
      const openTaskInList: boolean = state?.openTasks?.data?.find((task: Task) => task?.id === action?.payload?.id)
        ? true
        : false;
      const archivedTaskInList: boolean = state?.archivedTasks?.data?.find(
        (task: Task) => task?.id === action?.payload?.id,
      )
        ? true
        : false;

      if (!openTaskInList && !archivedTaskInList && action?.payload?.active) {
        return {
          ...state,
          archivedTasks: {
            ...state.archivedTasks,
            data: [...state.archivedTasks.data, action.payload].sort((a: Task, b: Task) =>
              tasksSortCompareFunction(a, b, state.filters.sortDirectionOne),
            ),
          },
          openTasks: {
            ...state.openTasks,
            data: [...state.openTasks.data, action.payload].sort((a: Task, b: Task) =>
              tasksSortCompareFunction(a, b, state.filters.sortDirectionOne),
            ),
          },
        };
      }

      return {
        ...state,
        archivedTasks: {
          ...state.archivedTasks,
          data: state.archivedTasks?.data?.map((item: Task) => {
            if (item?.id === action?.payload?.id) {
              return {
                ...item,
                ...action.payload,
              };
            }
            return item;
          }),
        },
        openTasks: {
          ...state.openTasks,
          data: state.openTasks?.data?.map((item: Task) => {
            if (item?.id === action?.payload?.id) {
              return {
                ...item,
                ...action.payload,
              };
            }

            return item;
          }),
        },
      };
    case BULK_UPDATE_TASKS:
      let openUpdatedTasks: Task[] = state.openTasks.data;
      let archivedUpdatedTasks: Task[] = state.archivedTasks.data;
      let openTasksNotInList: Task[] = [];
      let archivedTasksNotInList: Task[] = [];

      action?.payload?.forEach((task: Task) => {
        let openFoundInList: boolean = false;
        let archivedFoundInList: boolean = false;

        openUpdatedTasks = openUpdatedTasks.map((updatedTask: Task) => {
          if (updatedTask?.id === task.id) {
            openFoundInList = true;

            return {
              ...updatedTask,
              ...task,
            };
          }

          return updatedTask;
        });

        archivedUpdatedTasks = archivedUpdatedTasks.map((updatedTask: Task) => {
          if (updatedTask?.id === task.id) {
            archivedFoundInList = true;

            return {
              ...updatedTask,
              ...task,
            };
          }

          return updatedTask;
        });

        if (!openFoundInList) {
          openTasksNotInList = [...openTasksNotInList, task];
        }

        if (!archivedFoundInList) {
          archivedTasksNotInList = [...archivedTasksNotInList, task];
        }
      });

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          data: openTasksNotInList.length
            ? [...openUpdatedTasks, ...openTasksNotInList].sort((a: Task, b: Task) =>
                tasksSortCompareFunction(a, b, state.filters.sortDirectionOne),
              )
            : openUpdatedTasks,
        },
        archivedTasks: {
          ...state.archivedTasks,
          data: archivedTasksNotInList.length
            ? [...archivedUpdatedTasks, ...archivedTasksNotInList].sort((a: Task, b: Task) =>
                tasksSortCompareFunction(a, b, state.filters.sortDirectionOne),
              )
            : archivedUpdatedTasks,
        },
      };

    case ADD_NEW_TASK: {
      return {
        ...state,
        addNewTask: {
          ...state.addNewTask,
          loading: true,
        },
      };
    }

    case ADD_NEW_TASK_SUCCESS: {
      return {
        ...state,
        addNewTask: {
          ...state.addNewTask,
          loading: false,
          success: true,
          data: action.payload,
        },
      };
    }

    case ADD_NEW_TASK_ERROR: {
      return {
        ...state,
        addNewTask: {
          ...state.addNewTask,
          loading: false,
          success: false,
        },
      };
    }

    case GET_TASK_TYPES_LOADING: {
      return {
        ...state,
        taskTypes: {
          ...state.taskTypes,
          loading: action.payload,
        },
      };
    }

    case GET_TASK_TYPES_SUCCESS: {
      const weInuseEntryNameToCheck: string = 'weinfuse entry';
      return {
        ...state,
        taskTypes: {
          ...state.taskTypes,
          loading: false,
          success: true,
          data: action.payload?.map(
            (taskType: TaskType): TaskType => ({
              ...taskType,
              isWeInfuseEntry: taskType?.name?.toLowerCase()?.includes(weInuseEntryNameToCheck?.toLowerCase()),
            }),
          ),
        },
      };
    }

    case GET_TASK_TYPES_ERROR: {
      return {
        ...state,
        taskTypes: {
          ...state.taskTypes,
          loading: false,
          success: false,
        },
      };
    }

    case CLEAR_TASK_TYPES_STATE: {
      return {
        ...state,
        addNewTask: {
          ...state.addNewTask,
          loading: false,
          data: [],
          success: false,
          error: null,
        },
      };
    }
    case GET_TASKS_COUNT:
      return {
        ...state,
        openTotalCount: action.payload?.openTotalCount,
        archivedTotalCount: action.payload?.archivedTotalCount,
      };
    case SET_OPEN_TASKS_GLOBAL_LOADING:
      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          globalLoading: action.payload,
        },
      };
    case SET_ARCHIVED_TASKS_GLOBAL_LOADING:
      return {
        ...state,
        archivedTasks: {
          ...state.archivedTasks,
          globalLoading: action.payload,
        },
      };
    case SET_SPECIFIC_TASK_UPDATE_LOADING:
      return {
        ...state,
        taskBeingUpdated: {
          ...state.taskBeingUpdated,
          loading: action.payload.isLoading,
          taskId: action.payload.taskId,
        },
      };
    default:
      return state;
  }
};

export default tasksReducer;
