import axios from 'axios';
import { OrderEntryTaskApi, TaskState } from '../presenter';
import { WeInfuseOrderOption } from '../order-select-presenter';

const URL = `${process.env.REACT_APP_API_URL}/task/order-entry`;

export class HttpOrderEntryTaskApi implements OrderEntryTaskApi {
  async getTaskState(taskId: string): Promise<TaskState> {
    try {
      const response = await axios.get(`${URL}/${taskId}`);
      return response.data as TaskState;
    } catch (e: any) {
      if (e.isAxiosError && e.response?.data?.message) {
        throw new Error(e.response.data.message);
      }
      throw e;
    }
  }
  async getOrderLinkOptions(taskId: string): Promise<WeInfuseOrderOption[]> {
    return (await axios.get(`${URL}/${taskId}/options`)).data as WeInfuseOrderOption[];
  }
  async saveLinkedOrders(taskId: string, orderIds: string[]): Promise<TaskState> {
    return (await axios.put(`${URL}/${taskId}`, orderIds)).data as TaskState;
  }
}
