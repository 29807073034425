import { FieldBody, FieldLabel } from '../ReferralView/FieldLabel';
import SelectMenu from '../SelectMenu/SelectMenu';

export function SelectedSourcing({
  recommendedSourcing,
  selectedSourcing,
  reason,
  onSourcingChange,
  onReasonChange,
}: {
  recommendedSourcing: string | null;
  selectedSourcing: string | null;
  reason: string | null;
  onSourcingChange: (value: string) => void;
  onReasonChange: (value: string) => void;
}) {
  const sourcingOptions = [
    'Buy & Bill',
    'FlexCare Specialty Services',
    '3rd Pty Specialty Pharmacy IV',
    '3rd Pty Specialty Pharmacy SQ',
  ].map((x) => ({
    showDoneIcon: selectedSourcing === x,
    label: x,
    searchString: x,
    disabled: selectedSourcing === x,
  }));

  const reasonOptions = [
    'Patient preference',
    'PBM carve out',
    'FCSS unable to service',
    'OOP cost',
    'Provider preference',
    'Not covered under medical/pharmacy benefit',
    'Referral in scheduled state',
  ].map((x) => ({
    showDoneIcon: selectedSourcing === x,
    label: x,
    searchString: x,
    disabled: selectedSourcing === x,
  }));
  return (
    <>
      <FieldLabel>Selected Sourcing</FieldLabel>
      <FieldBody>
        <SelectMenu
          value={selectedSourcing}
          onChange={(option) => onSourcingChange(option?.label || null)}
          showClearIcon
          hideAddnew
          showExpandMore
          searchLoading={false}
          icon={<></>}
          options={sourcingOptions}
          label={selectedSourcing || 'Select'}
          style={{ paddingLeft: 8 }}
        />
      </FieldBody>
      {(selectedSourcing !== recommendedSourcing || !!reason) && (
        <>
          <FieldLabel>Reason for Override</FieldLabel>
          <FieldBody>
            <SelectMenu
              value={reason}
              onChange={(option) => onReasonChange(option?.label || null)}
              showClearIcon
              hideAddnew
              showExpandMore
              searchLoading={false}
              icon={<></>}
              options={reasonOptions}
              label={reason || 'Select'}
              style={{ paddingLeft: 8 }}
            />
          </FieldBody>
        </>
      )}
    </>
  );
}
