import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    adornment: {
      width: 32,
      height: 32,
      marginRight: 24,
      paddingLeft: 10,
    },
    root: {
      background: ({ open }: any) => (open ? alpha(theme.palette.common.black, 0.04) : ''),
      marginRight: 16,
      borderRadius: 4,
      width: '100%',
      height: 48,
      '&:hover': {
        background: 'rgba(25, 118, 210, 0.04) !important',
      },
    },
    parent: {
      position: 'relative' as any,
      width: '96%',
    },
    label: {
      position: 'absolute' as any,
      left: 56,
      top: '50%',
      transform: 'translateY(-50%)',
    },
    boxIcon: {
      width: 32,
      height: 32,
    },
  };
});
