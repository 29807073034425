import { getTask } from '../api/taskApi';
import { updateActiveReferralTaskList, updateTaskListItemAction } from '../redux/actions/tasksActions';
import { Task } from '../redux/types';
import { useDispatch } from 'react-redux';

export function useTaskRefresh() {
  const dispatch = useDispatch();

  return async function refresh(taskId: Task['id']) {
    const taskState = (await getTask(taskId)).data;
    dispatch(updateActiveReferralTaskList(taskState));
    dispatch(updateTaskListItemAction(taskState));
  };
}
