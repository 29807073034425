import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { Task } from '../../redux/types';
import { CommentActions, CommentsActionProps } from '../CommentsViewComponent/CommentsViewComponent';
import EditAssignee from '../EditAssignee/EditAssignee';
import MentionComponent from '../MentionComponent/MentionComponent';
import { MentionComponentProps } from '../MentionComponent/MentionTypes';
import { useStyles } from './referralViewStyles';

interface Props {
  open: boolean;
  loading: boolean;
  taskName: string;
  taskStatus: string;
  handleCancelTransition: () => void;
  handleAcceptTransition: (assignee?: any) => void;
  commentsActionProps?: CommentsActionProps;
  mentionComponentProps?: MentionComponentProps;
  currentReferralSchedulingTask?: Task;
}

const TaskTransitionDialog: FC<Props> = (props) => {
  const {
    open,
    loading,
    handleCancelTransition,
    handleAcceptTransition,
    mentionComponentProps,
    commentsActionProps,
    currentReferralSchedulingTask,
  } = props;
  const classes = useStyles();
  const [assignee, setAssignee] = useState<any>(undefined);

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle>Set to Ready to Schedule</DialogTitle>
      <DialogContent>
        <Box alignItems="center" display="flex">
          <Box style={{ width: '50%' }} flex={1}>
            <Typography variant="body2" style={{ fontWeight: 500 }} color="textSecondary">
              Scheduling Task Assignee *
            </Typography>
          </Box>
          <Box flex={1}>
            <EditAssignee
              label={
                <Typography variant="body2" color="textPrimary">
                  Assignee
                </Typography>
              }
              onChange={(item) => setAssignee(item)}
              filterOutInactiveAssignees
              initialValue={currentReferralSchedulingTask?.assignedUser}
              showClearIcon={false}
            />
          </Box>
        </Box>
        <Box style={{ marginTop: 32 }}>
          <Typography variant="subtitle2">Comment (Optional)</Typography>
        </Box>
        {mentionComponentProps && (
          <Box className={classes.commentBox}>
            <CommentActions isTopLevel {...commentsActionProps} />
            <MentionComponent {...mentionComponentProps} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box style={{ width: '100%' }} display="flex" justifyContent="flex-end">
          <Box style={{ marginRight: 16 }}>
            <Button disabled={loading} onClick={handleCancelTransition}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              disabled={loading || !(assignee || currentReferralSchedulingTask?.assignedUserId)}
              onClick={() => handleAcceptTransition(assignee || currentReferralSchedulingTask?.assignedUserId)}
              variant="contained"
            >
              Set To ready to schedule {loading && <CircularProgress size={16} />}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TaskTransitionDialog;
