import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PriorAuthDecisionActions } from './PriorAuthDecisionActions';
import { PriorAuthTaskController } from '../prior-auth-task-controller';

export function PriorAuthDecisionDialog(
  props: Pick<ReturnType<ReturnType<typeof PriorAuthTaskController>>, 'actions' | 'dialog'>,
) {
  return (
    <Dialog open={props.dialog.isOpen} maxWidth="xl" data-id="priorAuthDecisionDialog">
      <DialogTitle>PA Decision</DialogTitle>
      <DialogContent>
        <PriorAuthDecisionActions {...props.actions} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.dialog.close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
