import { Box, Chip, ChipProps } from '@mui/material';
import useStyles from './CommentsViewComponentsStyles';

export interface CommentsActionProps {
  onFollowUpButtonClick?: () => void;
  onContactedButtonClick?: () => void;
  contactedChosen?: boolean;
  followedUpChosen?: boolean;
  hideFollowUpBtn?: boolean;
  hideContactedBtn?: boolean;
  isTopLevel?: boolean;
}

export interface CommentActionChipProps extends ChipProps {
  chosen?: boolean;
  chosenColor: string;
  chosenBackgroundColor: string;
  text: string;
}

export const CommentActionChip: React.FC<CommentActionChipProps> = (props) => {
  const { chosen, style, chosenColor, chosenBackgroundColor, text, ...restProps } = props;

  return (
    <Chip
      style={{
        ...style,
        background: chosen ? chosenBackgroundColor : '',
        color: chosen ? chosenColor : '',
        borderRadius: 4,
      }}
      label={
        <div style={{ display: 'flex' }}>
          <div># {text}</div>
        </div>
      }
      size="small"
      {...restProps}
    />
  );
};

export const PatientContactedCommentActionChip: React.FC<Partial<CommentActionChipProps>> = (props) => {
  return <CommentActionChip text="Contacted" chosenBackgroundColor="#E0F7FA" chosenColor="#00838F" {...props} />;
};

export const FollowUpCommentActionChip: React.FC<Partial<CommentActionChipProps>> = (props) => {
  return <CommentActionChip text="Follow Up" chosenBackgroundColor="#9C27B014" chosenColor="#9C27B0" {...props} />;
};

export const CommentActions: React.FC<CommentsActionProps> = (props) => {
  const classes = useStyles();
  const {
    contactedChosen,
    followedUpChosen,
    onFollowUpButtonClick,
    onContactedButtonClick,
    hideContactedBtn,
    hideFollowUpBtn,
    isTopLevel,
  } = props;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={isTopLevel ? classes.topLevelFollowUpWrapper : classes.followUpWrapper}
        data-id="commentTags"
      >
        {!hideFollowUpBtn && (
          <FollowUpCommentActionChip
            className={classes.chipSpan}
            onClick={onFollowUpButtonClick}
            chosen={followedUpChosen}
          />
        )}
        {!hideContactedBtn && (
          <PatientContactedCommentActionChip
            className={classes.chipSpan}
            onClick={onContactedButtonClick}
            chosen={contactedChosen}
          />
        )}
      </Box>
    </>
  );
};
