import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import InputMask from 'react-input-mask';
import { ignoreTimezoneDate } from '../../globalUtils/utils';
import { sendFaxAckAction, getFaxAckPreviewAction, reSendFaxAckAction } from '../../redux/actions/faxActions';
import { Referral, RootState } from '../../redux/types';
import PDFPreviewModal from '../PDFPreview/PDFPreviewModal';
import { useStyles } from './FaxAcknowledgement.styles';

interface Props {
  open: boolean;
  isResend: boolean;
  referral?: Referral;
  retrySendingFaxData?: any;
  handleCancel: () => void;
}
const FaxAcknowledgement: FC<Props> = (props) => {
  const { open, handleCancel, referral, isResend, retrySendingFaxData } = props;
  const patient = referral?.patient;
  const faxReceivedDate = referral?.faxReceivedDate;
  const classes = useStyles();

  const { faxAckPreview, sendFaxAck, reSendFaxAck } = useSelector((state: RootState) => state.faxStore);
  const { activeReferral } = useSelector((state: RootState) => state.referrals);
  const [docsRequestedChecked, setDocsRequestedChecked] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [faxAckPreviewPdf, setFaxAckPreviewPdf] = useState();
  const [formErrors, setFormErrors] = useState<any>({});

  const [ackData, setAckData] = useState({
    provider_name:
      retrySendingFaxData?.faxAckFormData?.provider_name ||
      `${referral?.provider?.fullName}${referral?.provider?.credentials ? ', ' + referral?.provider?.credentials : ''}`,
    fax_number: retrySendingFaxData?.faxAckFormData?.fax_number || referral?.provider?.officeFaxNumber,
    requested_docs: retrySendingFaxData?.faxAckFormData?.requested_docs,
  });

  useEffect(() => {
    if (!open && previewOpen) {
      setPreviewOpen(false);
    }
  }, [open, previewOpen]);

  const dispatch = useDispatch();

  const ackParamData = {
    referralId: referral?.id || '',
    provider_name:
      ackData.provider_name ||
      `${referral?.provider?.fullName}${referral?.provider?.credentials ? ', ' + referral?.provider?.credentials : ''}`,
    fax_number: ackData.fax_number?.replace(/-/g, '') || referral?.provider?.officeFaxNumber,
    current_date: referral?.createdAt || '',
    requested_docs: docsRequestedChecked ? ackData.requested_docs : '',
  };

  useEffect(() => {
    if (!retrySendingFaxData?.faxAckFormData) {
      return;
    }
    setAckData({
      ...ackData,
      provider_name: retrySendingFaxData?.faxAckFormData?.provider_name,
      fax_number: retrySendingFaxData?.faxAckFormData?.fax_number,
      requested_docs: retrySendingFaxData?.faxAckFormData?.requested_docs,
    });

    setDocsRequestedChecked(Boolean(retrySendingFaxData?.faxAckFormData?.requested_docs));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrySendingFaxData]);
  useEffect(() => {
    if (retrySendingFaxData?.faxAckFormData) {
      return;
    }
    if (ackData.provider_name !== activeReferral?.data?.provider?.fullName) {
      setAckData({
        ...ackData,
        provider_name:
          `${referral?.provider?.fullName}${
            referral?.provider?.credentials ? ', ' + referral?.provider?.credentials : ''
          }` || '',
      });
    }

    if (ackData.fax_number !== activeReferral?.data?.provider?.officeFaxNumber) {
      setAckData({ ...ackData, fax_number: activeReferral?.data?.provider?.officeFaxNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReferral]);

  const validationSchema: any = {
    fax_number: {
      format: {
        pattern: '^[0-9]{10}$',
        message: 'is required',
      },
    },
    provider_name: {
      presence: {
        allowEmpty: false,
        message: '(To) is required',
      },
    },
    ...(docsRequestedChecked && {
      requested_docs: {
        presence: {
          allowEmpty: false,
          message: ' are required',
        },
      },
    }),
  };
  const validationErrors = validate(ackData, validationSchema);
  const handleSendFaxAck = () => {
    setFormErrors(() => (validationErrors ? validationErrors : {}));
    if (validationErrors) {
      return;
    }

    const onSuccess = (data: any) => {
      handleCancel();
    };

    dispatch(sendFaxAckAction(ackParamData, onSuccess));
  };

  const handleReSendFaxAck = () => {
    setFormErrors(() => (validationErrors ? validationErrors : {}));
    if (validationErrors) {
      return;
    }

    const onSuccess = (data: any) => {
      handleCancel();
    };

    dispatch(
      reSendFaxAckAction(
        { ...ackParamData, faxId: retrySendingFaxData?.faxAckFormData?.faxId || retrySendingFaxData?.id },
        onSuccess,
      ),
    );
  };

  const handleChange = (event: any) => {
    setAckData({
      ...ackData,
      [event.target.id]: event.target.id === 'fax_number' ? event.target?.value?.replace(/-/g, '') : event.target.value,
    });
  };

  const handleOpenPreview = () => {
    setFormErrors(() => (validationErrors ? validationErrors : {}));

    if (validationErrors) {
      return;
    }

    const onSuccess = (data: any) => {
      setFaxAckPreviewPdf(data);
      setPreviewOpen(true);
    };

    dispatch(getFaxAckPreviewAction(ackParamData, onSuccess));
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleCheckBoxChange = () => {
    setDocsRequestedChecked(!docsRequestedChecked);
  };

  const areButtonsDisabled = faxAckPreview?.loading || sendFaxAck?.loading || reSendFaxAck?.loading;

  const dialogTitle = isResend ? 'Retry Sending Fax Acknowledgement' : 'Send Fax Acknowledgement';

  return (
    <>
      <PDFPreviewModal
        open={previewOpen}
        handleClosePreview={handleClosePreview}
        pdfToPreview={faxAckPreviewPdf}
        title="Fax Acknowledgement Preview"
      />
      <Dialog open={open} classes={{ paper: classes.paper }} data-id="dialogFaxAcknowledgement">
        <DialogTitle> {dialogTitle} </DialogTitle>
        <DialogContent>
          <Box>
            <Box className={classes.boxWrap} display="flex" justifyContent="space-between" alignItems="center">
              <TextField
                className={classes.halfWidth}
                id="provider_name"
                label="To"
                variant="filled"
                onChange={handleChange}
                style={{ marginRight: 24 }}
                helperText={formErrors?.provider_name}
                error={!!formErrors.provider_name}
                color={formErrors.provider_name ? 'error' : 'primary'}
                defaultValue={ackData.provider_name}
              />
              <InputMask
                maskPlaceholder=""
                mask="999-999-9999999999999"
                id="fax_number"
                onChange={handleChange}
                value={ackData.fax_number}
                disabled={false}
              >
                <TextField
                  className={classes.halfWidth}
                  id="fax_number"
                  label="Fax"
                  variant="filled"
                  error={!!formErrors?.fax_number}
                  helperText={formErrors?.fax_number}
                  color={formErrors.fax_number ? 'error' : 'primary'}
                  defaultValue={ackData.fax_number}
                />
              </InputMask>
            </Box>
            <Box className={classes.boxWrap}>
              <Divider />
            </Box>
            <Box className={classes.boxWrap}>
              <TextField
                className={classes.fullWidth}
                id="patientName"
                label="Patient Name"
                variant="filled"
                disabled
                defaultValue={patient?.full_name || ''}
              />
            </Box>
            <Box className={classes.boxWrap} display="flex" justifyContent="space-between" alignItems="center">
              <TextField
                className={classes.halfWidth}
                id="DateReceived"
                label="Date Received"
                variant="filled"
                style={{ marginRight: 24 }}
                disabled
                defaultValue={faxReceivedDate ? format(new Date(faxReceivedDate), 'LLL d, yyyy') : ''}
              />
              <TextField
                className={classes.halfWidth}
                id="DOB"
                label="DOB"
                variant="filled"
                disabled
                defaultValue={
                  patient?.date_of_birth ? format(ignoreTimezoneDate(patient?.date_of_birth), 'MM/dd/yyyy') : ''
                }
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox onChange={handleCheckBoxChange} checked={docsRequestedChecked} id="documents-requested" />
                  }
                  label="Documents requested"
                />
              </FormGroup>
              {docsRequestedChecked && (
                <TextField
                  variant="filled"
                  id="requested_docs"
                  onChange={handleChange}
                  className={classes.fullWidth}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 5000 }}
                  error={!!formErrors?.requested_docs}
                  helperText={formErrors?.requested_docs}
                  color={formErrors.requested_docs ? 'error' : 'primary'}
                  defaultValue={ackData.requested_docs}
                />
              )}
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Box style={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Box>
              <Button disabled={areButtonsDisabled} onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button disabled={areButtonsDisabled} onClick={handleOpenPreview}>
                Preview Content {faxAckPreview?.loading && <CircularProgress size={16} />}
              </Button>
              <Button
                disabled={areButtonsDisabled}
                onClick={isResend ? handleReSendFaxAck : handleSendFaxAck}
                variant="contained"
              >
                {isResend ? 'Resend' : 'Send'}{' '}
                {(sendFaxAck?.loading || reSendFaxAck?.loading) && <CircularProgress size={16} />}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FaxAcknowledgement;
