export const GET_NOTIFICATIONS_LOADING: string = 'GET_NOTIFICATIONS_LOADING';
export const GET_NOTIFICATIONS_SUCCESS: string = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR: string = 'GET_NOTIFICATIONS_ERROR';
export const MARK_NOTIFICATION_AS_READ_SUCCESS: string = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_LOADING: string = 'MARK_NOTIFICATION_AS_READ_LOADING';
export const MARK_NOTIFICATION_AS_READ_ERROR: string = 'MARK_NOTIFICATION_AS_READ_ERROR';
export const SET_NOTIFICATIONS_FILTERS: string = 'SET_NOTIFICATIONS_FILTERS';
export const MARK_ALL_NOTIFICATIONS_AS_READ_LOADING: string = 'MARK_ALL_NOTIFICATIONS_AS_READ_LOADING';
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: string = 'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_READ_ERROR: string = 'MARK_ALL_NOTIFICATIONS_AS_READ_ERROR';
export const GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS: string = 'GET_UNREVIEWED_NOTIFICATIONS_COUNT_SUCCESS';
export const SET_NOTIFICATIONS_GLOBAL_LOADING: string = 'SET_NOTIFICATIONS_GLOBAL_LOADING';
export const SET_INITIAL_NOTIFICATION_LOADING_DONE: string = 'SET_INITIAL_NOTIFICATION_LOADING_DONE';
export const LOAD_NEW_NOTIFICATION_FROM_SOCKET: string = 'LOAD_NEW_NOTIFICATION_FROM_SOCKET';
