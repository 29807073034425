import { Box, ListItemText, Typography } from '@mui/material';
import { taskFaxStatuses, userFriendlyTimestamp } from '../../globalUtils/utils';
import { TaskLine } from '../TasksList/TaskLine';
import { TaskFaxType } from '../../redux/types';
import { FaxStatusIcon } from './FaxStatusIcon';

/**
 * Extracted from legacy code
 * @see TaskItem
 */
export function FaxStatus({ fax }: { fax?: TaskFaxType }) {
  const manualDelivery = fax?.status?.name?.toLowerCase() === taskFaxStatuses.MANUAL_DELIVERY;

  return (
    <TaskLine labelText="Fax status" data-id="taskFaxStatus">
      {fax?.status?.name ? (
        <>
          {!manualDelivery && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: 32, height: 32, marginRight: 16 }}
            >
              <FaxStatusIcon faxStatus={fax.status} />
            </Box>
          )}
          <ListItemText
            primary={
              <Typography
                variant="body2"
                style={{ textTransform: 'capitalize', ...(manualDelivery && { paddingLeft: '5px' }) }}
                fontWeight={500}
                color="#000000DE"
                fontSize={14}
              >
                {fax?.status?.name}
              </Typography>
            }
            secondary={manualDelivery ? '' : userFriendlyTimestamp(fax?.statusUpdatedAt)}
          />
        </>
      ) : (
        <Box flex={1} display="flex" alignItems="center" style={{ width: '50%', paddingLeft: 16 }}>
          -
        </Box>
      )}
    </TaskLine>
  );
}
