import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import download from '../../images/download.svg';
import Dropzone from 'react-dropzone';
import { useStyles } from './DropZoneStyles';
import { MAX_FILE_SIZE } from '../../globalUtils/constants';

interface Props {
  handleDrop: (acceptedFiles: any, error: any) => void;
  accept?: any;
}

const DropZone: FC<Props> = (props) => {
  const classes = useStyles();

  const { handleDrop, ...restProps } = props;
  return (
    <Box className={classes.dropzoneWrapper}>
      <Dropzone
        onDrop={handleDrop}
        maxSize={MAX_FILE_SIZE.bytes}
        accept={{
          'application/pdf': ['.pdf'],
          'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.tiff'],
          'audio/*': ['.mp3', '.wav'],
          'text/html': ['.html', '.htm'],
        }}
        {...restProps}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <p>
              <IconButton className={classes.dropZoneDownload}>
                <img height={20} width={20} alt="" src={download} />
              </IconButton>
              <span style={{ color: '#000000', opacity: 0.3 }}>Drop files here or</span>{' '}
              <span style={{ color: '#1976D2', cursor: 'pointer' }}>browse</span>
            </p>
          </div>
        )}
      </Dropzone>
    </Box>
  );
};

export default DropZone;
