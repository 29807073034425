import { BaseAction, RegionsState } from '../types';
import { FETCH_REGIONS_LOADING, LOAD_REGIONS_ERROR, LOAD_REGIONS_TO_STORE } from '../constants/inboxTypes';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';

const defaultRegions: Array<object> = [];

const initialState: RegionsState = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: RegionsState = initialState, action: BaseAction) => {
  switch (action.type) {
    case FETCH_REGIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_REGIONS_TO_STORE:
      return {
        ...state,
        data: [...action.payload?.sort(sortByNameKeyCompareFunction), ...defaultRegions],
      };
    case LOAD_REGIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
