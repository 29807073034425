import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectMenu, { SelectMenuProps } from '../../components/SelectMenu/SelectMenu';
import WaitingDialog, { updateTaskParams } from '../../components/TasksList/WaitingDialog';
import { taskListTabs } from '../../globalUtils/constants';
import { useGetAttachmentOperations, useGetTasksOptions } from '../../globalUtils/hooks';
import { getIdsFromArray, statusDefaults, taskFaxStatuses } from '../../globalUtils/utils';
import { getOneReferral } from '../../redux/actions/referralsActions';
import { fetchTasksAction, getTasksCountAction, updateTaskStatus } from '../../redux/actions/tasksActions';
import SendFaxAcknowledgement from '../SendFaxAcknowledgement/SendFaxAcknowledgement';
import { Referral, RootState, Task, TaskFaxType } from '../../redux/types';
import { StatusChangeHook } from '../../features/taskTypes/taskTypes';
import { defaultSortCompareFunction } from '../../globalUtils/helpers';

interface Props extends Partial<SelectMenuProps> {
  task: Task;
  fax?: TaskFaxType;
  activeTab?: string;
  totalTasksLoaded?: number;
  referral?: Referral;
  statusChangeHook: () => StatusChangeHook;
}

const ChangeTaskStatus: React.FC<Props> = (props) => {
  const { task, activeTab, totalTasksLoaded, fax, referral, ...restProps } = props;
  const {
    taskTemplates,
    tasks: { filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const statusOptions = useGetTasksOptions('ALL');
  const defaultStatus = statusOptions.find((option: any) => option.id === task?.status?.id);
  const [statusValue, setStatusValue] = useState(defaultStatus);
  const [statusLoading, setStatusLoading] = useState(false);
  const [waitingDialogOpen, setWaitingDialogOpen] = useState(false);
  const [waitingStatus, setWaitingStatus] = useState(defaultStatus);
  const [waitingLoading, setWaitingLoading] = useState<any>(false);
  const [faxAcknowledgementOpen, setFaxAcknowledgementOpen] = useState<any>(false);
  const waitingStatusId = statusOptions.find((status) => status?.name?.toLowerCase() === 'waiting')?.id;
  const [shouldResetValues, setShouldResetValues] = useState(false);
  const [selectedFilesToPublishEMR, setSelectedFilesToPublishToEMR] = useState<any[]>([]);
  const { publishAttachmentToWeInfuse } = useGetAttachmentOperations();
  const isWeInfuseTask: boolean = useMemo(() => {
    return task?.title?.toLowerCase()?.startsWith('weinfuse entry') || false;
  }, [task?.title]);

  const [dataFromWaitingDialog, setDataFromWaitingDialog] = useState({});

  const isFaxAcknowledgement = task?.title === 'Fax Acknowledgement';

  const statusChangeHook = props.statusChangeHook();

  const getTasksCount = (): void => {
    dispatch(
      getTasksCountAction(
        {
          ...filters,
          active: true,
        },
        getIdsFromArray(taskTemplates?.archivedTabTaskStatuses),
        getIdsFromArray(taskTemplates?.openTabTaskStatuses),
      ),
    );
  };

  const loadNewTasksItems = () => {
    if (activeTab) {
      const isArchivedTab: boolean = activeTab !== taskListTabs.OPEN;

      dispatch(
        fetchTasksAction(
          isArchivedTab,
          {
            ...filters,
            pageSize: 26,
            active: true,
          },
          getIdsFromArray(taskTemplates?.archivedTabTaskStatuses),
          getIdsFromArray(taskTemplates?.openTabTaskStatuses),
        ),
      );

      getTasksCount();
    }
  };

  const onUpdateTaskSuccess = () => {
    if (activeTab) {
      getTasksCount();
    }
  };

  const fetchRelatedReferralData = () => {
    if (task.referralId) {
      dispatch(getOneReferral(task?.referralId, true));
    }
  };

  const onSelectPublishFileToEMR = (file: any, isSelected: boolean) => {
    isSelected
      ? setSelectedFilesToPublishToEMR((previousSelectedFiles) => [...previousSelectedFiles, file])
      : setSelectedFilesToPublishToEMR((previousSelectedFiles) =>
          previousSelectedFiles.filter((previousFile) => previousFile?.id !== file?.id),
        );
  };

  const onChangeStatus = (value: any, formData?: any, callback?: (responseData: any) => void) => {
    const onSuccess = () => {
      !callback && setStatusLoading(false);

      if (totalTasksLoaded && totalTasksLoaded <= 25) {
        loadNewTasksItems();
      } else {
        onUpdateTaskSuccess();
      }
      !callback && setFaxAcknowledgementOpen(false);
    };

    const onError = () => {
      setStatusLoading(false);
    };

    if (!statusChangeHook.allowChange(value.name)) {
      return;
    }

    if (value.name === 'COMPLETED' && isFaxAcknowledgement) {
      setStatusLoading(true);
      formData.append('statusId', value.id);
      setStatusValue(value);
      const onFaxCompletion = (data: any) => {
        onSuccess();
        callback && callback(data);
      };
      dispatch(updateTaskStatus(formData, onFaxCompletion, onError, task.id));
      return;
    }

    if (value.name !== 'WAITING' && task.id) {
      setStatusLoading(true);
      setStatusValue(value);
      dispatch(
        updateTaskStatus(
          { id: task.id, statusId: value.id },
          (data: any) => {
            onSuccess();
            callback && callback(data);
          },
          onError,
        ),
      );
    } else {
      setWaitingStatus(value);
      setWaitingDialogOpen(true);
    }
  };

  const onChangeMenu = (value: any) => {
    if (value.name === 'COMPLETED' && isFaxAcknowledgement) {
      setFaxAcknowledgementOpen(value);
    } else {
      onChangeStatus(value);
    }
  };

  const handleSubmitSetTaskToWaiting = (params: updateTaskParams, callback: () => void) => {
    const onSuccess = () => {
      setStatusValue(waitingStatus);
      setWaitingDialogOpen(false);
      fetchRelatedReferralData();
      setWaitingLoading(false);
      onUpdateTaskSuccess();
      setShouldResetValues(!shouldResetValues);
      callback && callback();
    };

    const onError = () => {
      setWaitingLoading(false);
      callback && callback();
    };
    setWaitingLoading(true);
    dispatch(updateTaskStatus(params, onSuccess, onError));
  };

  const handleWatingDialogChange = (fieldName: string, value: any) => {
    setDataFromWaitingDialog({ ...dataFromWaitingDialog, [fieldName]: value });
  };

  const isSelectedStatusOption = (option: any, value: any): boolean => option?.id === value?.id;

  const onSendFaxAcknowledgementCallback = (
    responseData: any,
    file: any,
    pendingNumberOfFilesSelectedToPublishToWeInfuse: number,
  ) => {
    const selectedFile: any = selectedFilesToPublishEMR.find(
      (selectedFile) => `${selectedFile?.fileName || selectedFile?.id}.${selectedFile.extension || ''}` === file?.name,
    );
    const selectedFileName: string = `${selectedFile?.fileName || selectedFile?.id}.${selectedFile?.extension || ''}`;

    if (selectedFile) {
      const incomingAttachments: any[] = responseData?.referral?.attachments.sort((a: any, b: any) =>
        defaultSortCompareFunction(new Date(b?.createdAt), new Date(a?.createdAt)),
      );
      const outgoingAttachments: any[] = responseData?.referral?.outgoingAttachments.sort((a: any, b: any) =>
        defaultSortCompareFunction(new Date(b?.createdAt), new Date(a?.createdAt)),
      );
      const attachments = [...incomingAttachments, ...outgoingAttachments];
      const attachment: any = attachments.find((attachment) => attachment.originalFileName === selectedFileName);

      publishAttachmentToWeInfuse(referral?.id, attachment?.id, false, () => {
        if (pendingNumberOfFilesSelectedToPublishToWeInfuse <= 1) {
          setSelectedFilesToPublishToEMR([]);
          setFaxAcknowledgementOpen(false);
          setStatusLoading(false);
        }
      });
    } else {
      setSelectedFilesToPublishToEMR([]);
      setFaxAcknowledgementOpen(false);
      setStatusLoading(false);
    }
  };

  const onSendFaxAcknowledgement = (files: File[], attachmentValues: any[]) => {
    let pendingNumberOfFilesSelectedToPublishToWeInfuse: number = selectedFilesToPublishEMR.length;

    files?.forEach((file: any) => {
      const formData = new FormData();
      const changedFile = attachmentValues.find((attachment: any) => {
        return attachment[file.name]?.fileName === file.name;
      });

      if (changedFile) {
        changedFile[file.name]?.newFileName
          ? formData.append('file', file, changedFile[file.name]?.newFileName + `.${file?.name?.split('.').pop()}`)
          : formData.append('file', file);
        changedFile[file.name]?.categories &&
          formData.append('attachmentCategoryIds', changedFile[file.name]?.categories);
      } else {
        formData.append('file', file);
      }

      const callback = (responseData: any) => {
        onSendFaxAcknowledgementCallback(responseData, file, pendingNumberOfFilesSelectedToPublishToWeInfuse);
        pendingNumberOfFilesSelectedToPublishToWeInfuse -= 1;
      };

      onChangeStatus(faxAcknowledgementOpen, formData, callback);
    });
  };

  const faxDeliveryInProgress = fax?.status?.name?.toLowerCase() === taskFaxStatuses.DELIVERY_IN_PROGRESS;

  const checkIfIsCompleteStatusAndReferralHasTemporaryPatient = (status: string, referral?: Referral): boolean => {
    return status?.toUpperCase() === statusDefaults.COMPLETED && Boolean(referral?.patient?.isTemporary);
  };

  return (
    <>
      <WaitingDialog
        id={task?.id || ''}
        title={task?.title || ''}
        loading={waitingLoading}
        waitingStatusId={waitingStatusId}
        shouldSubmitOnWaitingClick={true}
        isWaitingDialogOpen={waitingDialogOpen}
        shouldResetValues={shouldResetValues}
        handleChange={handleWatingDialogChange}
        setWaitingDialogOpen={setWaitingDialogOpen}
        handleSubmitSetTaskToWaiting={handleSubmitSetTaskToWaiting}
        task={task}
      />
      {statusChangeHook.component}
      {isFaxAcknowledgement && (
        <SendFaxAcknowledgement
          handleUpload={onSendFaxAcknowledgement}
          onCancel={() => {
            setFaxAcknowledgementOpen(false);
            setSelectedFilesToPublishToEMR([]);
          }}
          open={Boolean(faxAcknowledgementOpen)}
          loading={statusLoading}
          referral={referral}
          onSelectPublishFileToEMR={onSelectPublishFileToEMR}
        />
      )}
      <SelectMenu
        hideAddnew
        hideSearch
        loading={statusLoading}
        options={statusOptions.map((option) =>
          addStatusOptionDisplayState(option, (option, statusOptionDisplayState) => ({
            ...option,
            disabled:
              option?.disabled ||
              isSelectedStatusOption(option, statusValue || defaultStatus) ||
              faxDeliveryInProgress ||
              statusOptionDisplayState.disabled ||
              (checkIfIsCompleteStatusAndReferralHasTemporaryPatient(option?.name, referral) &&
                isWeInfuseTask &&
                !isSelectedStatusOption(option, statusValue || defaultStatus)),
            showDoneIcon: isSelectedStatusOption(option, statusValue || defaultStatus),
            tooltip:
              checkIfIsCompleteStatusAndReferralHasTemporaryPatient(option?.name, referral) &&
              isWeInfuseTask &&
              !isSelectedStatusOption(option, statusValue || defaultStatus)
                ? `To change task status to "Completed", Please update "WeInfuse Patient".`
                : statusOptionDisplayState.tooltip,
            loading: statusOptionDisplayState.loading,
          })),
        )}
        value={statusValue || defaultStatus}
        onChange={onChangeMenu}
        {...restProps}
      />
    </>
  );

  function addStatusOptionDisplayState(option: any, func: (option: any, statusOptionDisplayState: any) => any) {
    return func(option, statusChangeHook.getStatusOptionDisplayState(option.name));
  }
};

export default ChangeTaskStatus;
