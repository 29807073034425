import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      padding: 24,
      minHeight: 'calc(100vh + 200px)',
      marginBottom: 200,
    },
    endAdornment: {
      top: '0 !important',
    },
    details: {
      marginTop: 24,
    },
    viewTransform: {
      textAlign: 'left' as 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    label: {
      // marginBottom: 16, 414, 270
      display: 'flex',
      width: '40%',
      alignItems: 'center',
    },
    labelInput: {
      // marginBottom: 16, 414, 270
      display: 'flex',
      width: '60%',
      alignItems: 'center',
      paddingRight: 0,
      marginRight: 0,
      height: 56,
    },
    box: {
      marginTop: 16,
      padding: `3px 16px`,
      border: `1px solid #E0E0E0`,
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap' as 'wrap',
      paddingRight: 0,
    },
    ellipsis: {
      borderRadius: '50%',
      minWidth: 24,
      minHeight: 24,
      background: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: { background: '#BDBDBD', height: 24, width: 24, borderRadius: '50%', color: '#FFFFFF' },
    attachments: {
      marginTop: 16,
      marginBottom: 16,
      marginRight: 4,
    },
    attachmentItem: {
      background: '#FAFAFA',
      padding: 5,
      borderRadius: 4,
      borderLeft: '4px solid #651FFF',
    },
    referralLabel: {
      color: '#651FFF',
      fontSize: `10px !important`,
    },
    dropzone: {
      marginBottom: 16,
      borderRadius: 5,
    },

    dropZoneDownload: {
      paddingRight: 12,
    },
    comments: {
      marginTop: 32,
    },
    commentsTitle: {
      marginTop: 16,
    },
    paddingBox: {
      padding: '24px 0',
    },
    iconsExpandedView: {
      position: 'absolute' as 'absolute',
      right: 16,
      bottom: 42,
    },
    followUpWrapper: {
      position: 'absolute' as 'absolute',
      bottom: 18,
      left: 15,
    },
    syncTextWrapper: {
      position: 'absolute' as 'absolute',
      bottom: 15,
      right: 24,
    },
    syncText: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.38)',
    },
    chipSpan: {
      left: 4,
    },
    redColor: {
      color: 'red',
    },
    '.SnackbarItem-variantDefault': {
      backgroundColor: '#ff3d00 !important',
    },
    popper: {
      background: 'transparent',
      marginTop: '-6px !important',
    },
    adornment: {
      width: 32,
      height: 32,
      marginRight: 17,
      paddingLeft: 14,
    },
    tooltip: {
      background: '#616161 !important',
      opacity: '0.9 !important',
      color: '#FFFFFF !important',
    },
    addAttachmentTooltip: {
      fontSize: '14px !important',
    },
    addIconButton: {
      '&:hover': {
        background: '#0000000F !important',
      },
    },
    input: {
      height: 48,
      width: 'calc(100% - 16px)',
      borderRadius: 4,
      '&:hover': {
        background: 'rgba(25, 118, 210, 0.04)',
      },
    },
  };
});
