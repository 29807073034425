import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useStyles } from './AddProviderStyles';
import { Props } from './AddProviderTypes';
import { validate } from 'validate.js';
import alertIcon from '../../images/alert.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { editReferralProviderAction } from '../../redux/actions/referralProvidersAction';
import TextField from '../TextField';

const AddProvider: FC<Props> = (props) => {
  const { open, onOpenChange, onAfterChangeProvider, initialValues, isEditingTempProvider } = props;
  const classes = useStyles();
  const [values, setValues] = useState({
    npi: '',
    firstName: '',
    lastName: '',
    id: '',
  });

  const { editProvider, newProvider } = useSelector((state: RootState) => state.referralProviders);

  useEffect(() => {
    if (initialValues) {
      setValues({
        ...initialValues,
        npi: values?.npi || initialValues?.npi || '',
        firstName: values?.firstName || initialValues?.firstName || '',
        lastName: values?.lastName || initialValues?.lastName || '',
        id: initialValues?.id || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const [errors, setErrors] = useState<any>({});

  const validationSchema: any = {
    firstName: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    ...(values.npi && {
      npi: {
        format: {
          pattern: '^[0-9]{10}$',
          message: 'must be 10 digits',
        },
      },
    }),
  };

  useEffect(() => {
    if (!open) {
      setValues({
        npi: '',
        firstName: '',
        lastName: '',
        id: '',
        ...initialValues,
      });

      setErrors({});
    }
  }, [open, initialValues]);

  const dispatch = useDispatch();

  const onAddProvider = () => {
    const validationErrors: any = validate(values, validationSchema);

    setErrors(() => (validationErrors ? validationErrors : {}));

    if (!validationErrors) {
      const temporaryProvider = {
        isTemporary: true,
        firstName: values.firstName,
        lastName: values.lastName,
        npi: values.npi,
      };
      onAfterChangeProvider(temporaryProvider);
      onOpenChange(false);
    }
  };

  const onEditProvider = () => {
    const validationErrors: any = validate(values, validationSchema);

    setErrors(() => (validationErrors ? validationErrors : {}));

    if (!validationErrors) {
      const onSuccess = (data: any) => {
        const updatedData = { ...data, providerId: data?.id };
        onOpenChange(false);
        onAfterChangeProvider(updatedData);
      };

      if (!initialValues?.firstName) {
        return onAddProvider();
      }

      dispatch(
        editReferralProviderAction(
          {
            isTemporary: true,
            firstName: values.firstName,
            lastName: values.lastName,
            npi: values.npi,
            id: values.id,
          },
          onSuccess,
        ),
      );
    }
  };

  const onChange = (name: string, value: string | Date | null) => {
    setErrors((previousValue: any) => ({
      ...previousValue,
      [name]: '',
    }));

    setValues((v) => ({
      ...v,
      [name]: value,
    }));
  };

  return (
    <Dialog fullWidth open={open} classes={{ paper: classes.paper }}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>Temporary Provider</DialogTitle>
      <DialogContent>
        <Alert color="error" icon={<img src={alertIcon} alt="alert" height={20} width={20} />}>
          <AlertTitle>Existing provider will be required</AlertTitle>
          Existing provider selected from the dropdown list will be required to change the status of this referral to
          “Ready to Schedule”.
        </Alert>
        {/* )} */}
        <Typography variant="body2" style={{ padding: '16px 0' }}>
          Provider Details
        </Typography>
        <TextField
          onChange={(e) => onChange('firstName', e.target.value)}
          value={values.firstName}
          id="first-name"
          label="First Name"
          variant="filled"
          required
          fullWidth
          helperText={errors.firstName}
          error={!!errors.firstName}
          color={errors.firstName ? 'error' : 'primary'}
          FormHelperTextProps={{ error: !!errors.firstName }}
          inputProps={{ maxLength: 80 }}
          InputProps={{ disableUnderline: true }}
        />
        <Box className={classes.input} />
        <TextField
          onChange={(e) => onChange('lastName', e.target.value)}
          value={values.lastName}
          id="last-name"
          label="Last Name"
          variant="filled"
          required
          fullWidth
          helperText={errors.lastName}
          error={!!errors.lastName}
          inputProps={{ maxLength: 80 }}
          color={errors.lastName ? 'error' : 'primary'}
          FormHelperTextProps={{ error: !!errors.lastName }}
          InputProps={{ disableUnderline: true }}
        />
        <Box className={classes.input} />
        <TextField
          onChange={(e) => onChange('npi', e.target.value)}
          value={values.npi}
          id="npi-number"
          variant="filled"
          label="NPI #"
          placeholder="NPI #"
          type="text"
          fullWidth
          helperText={errors?.npi?.map?.((t: string) => t.replace('Npi', 'NPI'))}
          error={!!errors.npi}
          color={errors.npi ? 'error' : 'primary'}
          FormHelperTextProps={{ error: !!errors.npi }}
          InputProps={{ disableUnderline: true }}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={newProvider?.loading || editProvider.loading} onClick={() => onOpenChange(false)}>
          Cancel
        </Button>
        <Button
          disabled={newProvider?.loading || editProvider.loading}
          onClick={isEditingTempProvider ? onEditProvider : onAddProvider}
          variant="contained"
        >
          {editProvider.loading ? (
            <>
              Updating...
              <CircularProgress size={16} />
            </>
          ) : (
            <>
              Save
              {(newProvider?.loading || editProvider?.loading) && <CircularProgress size={16} />}
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProvider;
