// Creating this file because the tsx utils file throws an import exception for helpers to be used in redux

import { InboxItem, StatusReason, Task } from '../redux/types';
import { inboxStatusConstants } from './constants';
import lodash from 'lodash';
import { lazy, FC } from 'react';

export const defaultSortCompareFunction = (a: any, b: any, compareKey?: any): number => {
  const item1: any = compareKey ? a[compareKey] : a;
  const item2: any = compareKey ? b[compareKey] : b;

  if (item1 === item2) {
    return 0;
  }

  return item1 < item2 ? -1 : 1;
};

export const sortByNameKeyCompareFunction = (a: any, b: any): number => defaultSortCompareFunction(a.name, b.name);

export const sortOrderFunction = (a: any, b: any) => defaultSortCompareFunction(a.sortOrder, b.sortOrder);

export const taskReasonsSortFunction = (a: StatusReason, b: StatusReason) =>
  sortOrderFunction(a.taskStatus, b.taskStatus);

export const referralReasonsSortFunction = (a: StatusReason, b: StatusReason) =>
  sortOrderFunction(a.referralStatus, b.referralStatus);

export const tasksSortCompareFunction = (a: Task, b: Task, sortDirection: 'ASC' | 'DESC') => {
  if (a?.referral?.id === b?.referral?.id) {
    return sortOrderFunction(a, b);
  }

  if (sortDirection === 'ASC') {
    return defaultSortCompareFunction(new Date(a?.referral?.createdAt), new Date(b?.referral?.createdAt));
  }

  return defaultSortCompareFunction(new Date(b?.referral?.createdAt), new Date(a?.referral?.createdAt));
};

export const archivedInboxStatuses: string[] = [
  inboxStatusConstants.COMPLETE,
  inboxStatusConstants.FAILED_FAX,
  inboxStatusConstants.SPAM,
  inboxStatusConstants.DUPLICATE,
  inboxStatusConstants.FORWARDED,
];

export const receivedInboxStatuses: string[] = [inboxStatusConstants.OPEN];

export const inboxSortFunction = (a: InboxItem, b: InboxItem, sortDirection: 'asc' | 'desc') => {
  return sortDirection === 'asc'
    ? defaultSortCompareFunction(new Date(a?.faxReceivedDate), new Date(b?.faxReceivedDate))
    : defaultSortCompareFunction(new Date(b?.faxReceivedDate), new Date(a?.faxReceivedDate));
};

export enum ReferralEditFields {
  STATUS,
  ANY,
  PRIORITY,
  CLINIC,
  REGION,
  REFERRAL_TYPE,
  HOME_CITY,
}

export const archivedReferralStatuses: string[] = ['scheduled', 'discarded', 'not completed'];

export const activeReferralStatuses: string[] = ['open', 'draft', 'on hold', 'ready to schedule'];

export const mergeNewInboxItemsWithExistingInboxItems = (
  existingInboxResults: InboxItem[],
  newInboxResults: InboxItem[],
  isFirstPage: boolean,
): InboxItem[] => {
  if (isFirstPage) {
    return newInboxResults;
  }

  return lodash.unionBy([...existingInboxResults, ...newInboxResults], 'id');
};

export const LOGIN_REDIRECT_ROUTE_LOCALSTORAGE_KEY: string = 'loginRedirectRoute';

export const getTasksFromReferralResponseData = (referralResponseData: any): Task[] => {
  const tasks: Task[] | undefined = referralResponseData?.tasks;

  return (tasks ? tasks : []).map((task: Task) => ({
    ...task,
    referral: {
      ...task?.referral,
      ...referralResponseData,
    },
  }));
};

export const dynamicComponentLoaderWithRetries = (
  componentImport: () => Promise<{ default: FC }>,
  numberOfRetries: number,
): any => {
  return new Promise((resolve, reject) => {
    const localStoragePageHasBeenForceRefreshedKey: string = 'pageHasBeenForceRefreshed';
    window.localStorage.setItem(localStoragePageHasBeenForceRefreshedKey, 'false');

    componentImport()
      .then(resolve)
      .catch((error: any) => {
        console.log('Chunk error occurred during loading, a retry is being done. The error is:', error);
        setTimeout(() => {
          if (numberOfRetries === 1) {
            console.log(
              'Attemopts to reload the failed chunks did not work, a forced reload is being initiated:',
              error,
            );
            window.location.reload();

            return;
          }

          return dynamicComponentLoaderWithRetries(componentImport, numberOfRetries - 1).then(resolve, reject);
        }, 1500);
      });
  });
};

export const lazyWithRetry = (componentImport: () => Promise<{ default: FC }>) =>
  lazy(async () => {
    const numberOfLoadRetries = 3;
    const component = dynamicComponentLoaderWithRetries(componentImport, numberOfLoadRetries);

    return component;
  });
