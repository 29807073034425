import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';
import {
  editStatusAttachmentApi,
  editTaskApi,
  editTaskStatusApi,
  getTaskStatuses,
  TaskUpdate,
} from '../../api/taskApi';
import { getTasksApi, addNewTaskApi, getTaskTypesApi, AddNewTaskParams } from '../../api/tasksApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { apiErrorHandler } from '../../globalUtils/utils';
import {
  getArchivedTasksError,
  getArchivedTasksLoading,
  getArchivedTasksSuccess,
  getOpenTasksError,
  getOpenTasksLoading,
  getOpenTasksSuccess,
  getTasksCount,
  setArchivedGlobalTasksLoading,
  setOpenTasksGlobalLoading,
  setSpecificTaskUpdateLoading,
} from '../actionCreators/tasksActionCreators';
import { SET_ACTIVE_REFERRAL, UPDATE_ACTIVE_REFERRAL_TASKS, UPDATE_TASK_STATUS_LIST } from '../constants/referralTypes';
import {
  BULK_UPDATE_TASKS,
  UPDATE_TASK_LIST_ITEM,
  ADD_NEW_TASK,
  ADD_NEW_TASK_SUCCESS,
  ADD_NEW_TASK_ERROR,
  GET_TASK_TYPES_LOADING,
  GET_TASK_TYPES_SUCCESS,
  GET_TASK_TYPES_ERROR,
  CLEAR_TASK_TYPES_STATE,
} from '../constants/tasksTypes';
import { Task } from '../types';

const getTasks = async (payload: any, archivedTasksStatuses: string[], openTasksStatuses: string[]) => {
  const [archivedTasksResponse, openTasksResponse] = await Promise.all([
    getTasksApi({ ...payload, taskStatuses: archivedTasksStatuses }),
    getTasksApi({ ...payload, taskStatuses: openTasksStatuses }),
  ]);

  return { archivedTasksResponse, openTasksResponse };
};

const hasOpenOrArchivedStatuses = (taskStatuses: string[], openOrArchivedStatuses: string[]): boolean => {
  return (
    taskStatuses.length > 0 &&
    openOrArchivedStatuses.length > 0 &&
    taskStatuses.some((status: string) => openOrArchivedStatuses.includes(status))
  );
};

export const fetchTasksAction =
  (isArchivedAction: boolean, payload: any, archivedTasksStatuses: string[], openTasksStatuses: string[]) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(isArchivedAction ? getArchivedTasksLoading(true) : getOpenTasksLoading(true));
      const hasOpenStatuses: boolean = hasOpenOrArchivedStatuses(payload.taskStatuses, openTasksStatuses);
      const hasArchivedStatuses: boolean = hasOpenOrArchivedStatuses(payload.taskStatuses, archivedTasksStatuses);

      const response = await getTasksApi({
        ...payload,
        taskStatuses: isArchivedAction
          ? hasArchivedStatuses
            ? payload?.taskStatuses?.filter((status: string) => archivedTasksStatuses.includes(status))
            : archivedTasksStatuses
          : hasOpenStatuses
          ? payload.taskStatuses?.filter((status: string) => openTasksStatuses.includes(status))
          : openTasksStatuses,
      });
      const tasksList = isArchivedAction
        ? hasOpenStatuses && !hasArchivedStatuses
          ? []
          : response.data.data
        : hasArchivedStatuses && !hasOpenStatuses
        ? []
        : response.data.data;

      dispatch(
        isArchivedAction
          ? getArchivedTasksSuccess({ ...response.data, data: tasksList })
          : getOpenTasksSuccess({ ...response.data, data: tasksList }),
      );
    } catch (error) {
      dispatch(isArchivedAction ? getArchivedTasksError(error) : getOpenTasksError(error));
      const errorMessage: string = backendErrorHandler(error);
      showErrorMessage(errorMessage);
    } finally {
      dispatch(isArchivedAction ? getArchivedTasksLoading(false) : getOpenTasksLoading(false));
      dispatch(isArchivedAction ? setArchivedGlobalTasksLoading(false) : setOpenTasksGlobalLoading(false));
    }
  };

export const getTasksCountAction =
  (payload: any, archivedTasksStatuses: string[], openTasksStatuses: string[]) => async (dispatch: Dispatch) => {
    try {
      const hasOpenStatuses: boolean = hasOpenOrArchivedStatuses(payload.taskStatuses, openTasksStatuses);
      const hasArchivedStatuses: boolean = hasOpenOrArchivedStatuses(payload.taskStatuses, archivedTasksStatuses);

      const { archivedTasksResponse, openTasksResponse } = await getTasks(
        { ...payload, pageSize: 1, page: 1 },
        hasArchivedStatuses
          ? payload.taskStatuses?.filter((status: string) => archivedTasksStatuses.includes(status))
          : archivedTasksStatuses,
        hasOpenStatuses
          ? payload.taskStatuses?.filter((status: string) => openTasksStatuses.includes(status))
          : openTasksStatuses,
      );

      dispatch(
        getTasksCount({
          openTotalCount: openTasksResponse.data?.totalCount || 0,
          archivedTotalCount: archivedTasksResponse?.data?.totalCount || 0,
        }),
      );
    } catch (error) {
      const errorMessage: string = backendErrorHandler(error);
      showErrorMessage(errorMessage);
    }
  };

export const updateTaskListItemAction = (payload: any) => ({
  type: UPDATE_TASK_LIST_ITEM,
  payload,
});

const getTaskTypesLoading = (isLoading: boolean) => ({
  type: GET_TASK_TYPES_LOADING,
  payload: isLoading,
});

const getTaskTypesSuccess = (payload: any) => ({
  type: GET_TASK_TYPES_SUCCESS,
  payload,
});

const getTaskTypesError = (payload: any) => ({
  type: GET_TASK_TYPES_ERROR,
  payload,
});

const addNewTaskLoading = () => ({
  type: ADD_NEW_TASK,
});

export const clearTaskTypesState = () => ({
  type: CLEAR_TASK_TYPES_STATE,
});

export const addNewTaskSuccess = (payload: any) => ({
  type: ADD_NEW_TASK_SUCCESS,
  payload,
});

const addNewTaskError = (payload: any) => ({
  type: ADD_NEW_TASK_ERROR,
  payload,
});

export const getTaskTypesTaskAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getTaskTypesLoading(true));
    const response = await getTaskTypesApi();
    dispatch(getTaskTypesSuccess(response.data));
  } catch (e: any) {
    const errorMessage = backendErrorHandler(e);
    showErrorMessage(errorMessage);

    apiErrorHandler(e);
    dispatch(getTaskTypesError(e));
  } finally {
    dispatch(getTaskTypesLoading(false));
  }
};

export const addNewTaskAction =
  (data: AddNewTaskParams, onSuccess?: (data: any) => void) => async (dispatch: Dispatch) => {
    dispatch(addNewTaskLoading());
    try {
      const response = await addNewTaskApi(data);
      dispatch(addNewTaskSuccess(response.data));
      dispatch(updateTaskListItemAction(response.data));

      onSuccess && onSuccess(response.data);
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      apiErrorHandler(e);
      dispatch(addNewTaskError(e));
    }
  };

export const bulkUpdateTasksAction = (payload: Task[]) => ({
  type: BULK_UPDATE_TASKS,
  payload,
});

export const updateActiveReferralTaskList = (payload: any) => {
  return {
    type: UPDATE_ACTIVE_REFERRAL_TASKS,
    payload,
  };
};

export const setActiveReferral = (payload: any) => {
  return {
    type: SET_ACTIVE_REFERRAL,
    payload,
  };
};

export const updateTaskStatus =
  (params: TaskUpdate, onSuccess?: (data: any) => void, onError?: () => void, id?: string) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(setSpecificTaskUpdateLoading({ taskId: params?.id || id || '', isLoading: true }));
      const response = isEmpty(params) ? await editStatusAttachmentApi(id, params) : await editTaskStatusApi(params);
      dispatch(updateActiveReferralTaskList(response.data));
      dispatch(updateTaskListItemAction(response.data));
      // Update task success
      isEmpty(params) && dispatch(setActiveReferral(response.data?.referral));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      if (onError) {
        onError();
      }
    } finally {
      dispatch(setSpecificTaskUpdateLoading({ taskId: params?.id || id || '', isLoading: false }));
    }
  };

export const updateTask =
  (params: TaskUpdate, onSuccess?: (data: any) => void, onError?: (e: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(setSpecificTaskUpdateLoading({ taskId: params?.id || '', isLoading: true }));
      const response = await editTaskApi(params);
      dispatch(updateTaskListItemAction(response.data));

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e: any) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      if (onError) {
        onError(e?.response?.data?.message || 'An Error occurred');
      }
    } finally {
      dispatch(setSpecificTaskUpdateLoading({ taskId: params?.id || '', isLoading: false }));
    }
  };

const saveTaskStatusToStore = (payload: any[]) => {
  return {
    type: UPDATE_TASK_STATUS_LIST,
    payload,
  };
};

export const getTaskStatusListAction = () => async (dispatch: Dispatch) => {
  try {
    const response = await getTaskStatuses();
    dispatch(saveTaskStatusToStore(response.data));
  } catch (e) {}
};
