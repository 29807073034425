import { DeclineReferralServer, DeclineReferralTask } from '../decline-referral';
import axios from 'axios';

export class HttpDeclineReferralServer implements DeclineReferralServer {
  constructor(private readonly apiUrl: string) {}

  async decline(request: { taskId: DeclineReferralTask['id']; faxNumber: string }): Promise<DeclineReferralTask> {
    const response = await axios.put(`${this.apiUrl}/tasks/decline-referral`, request);
    return response.data;
  }
}
