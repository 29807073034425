export const UPDATE_TASKLIST_FILTERS: string = 'UPDATE_TASKLIST_FILTERS';
export const UPDATE_TASK_LIST_ITEM: string = 'UPDATE_TASK_LIST_ITEM';
export const BULK_UPDATE_TASKS: string = 'BULK_UPDATE_TASKS';

export const ADD_NEW_TASK: string = 'ADD_NEW_TASK';
export const ADD_NEW_TASK_SUCCESS: string = 'ADD_NEW_TASK_SUCCESS';
export const ADD_NEW_TASK_ERROR: string = 'ADD_NEW_TASK_ERROR';

export const GET_TASK_TYPES_LOADING: string = 'GET_TASK_TYPES_LOADING';
export const GET_TASK_TYPES_SUCCESS: string = 'GET_TASK_TYPES_SUCCESS';
export const GET_TASK_TYPES_ERROR: string = 'GET_TASK_TYPES_ERROR';
export const CLEAR_TASK_TYPES_STATE: string = 'CLEAR_TASK_TYPES_STATE';

export const GET_TASKS_COUNT: string = 'GET_TASKS_COUNT';

export const SET_OPEN_TASKS_GLOBAL_LOADING: string = 'SET_OPEN_TASKS_GLOBAL_LOADING';
export const SET_ARCHIVED_TASKS_GLOBAL_LOADING: string = 'SET_ARCHIVED_TASKS_GLOBAL_LOADING';

export const GET_OPEN_TASKS_LOADING: string = 'GET_OPEN_TASKS_LOADING';
export const GET_OPEN_TASKS_SUCCESS: string = 'GET_OPEN_TASKS_SUCCESS';
export const GET_OPEN_TASKS_ERROR: string = 'GET_OPEN_TASKS_ERROR';

export const GET_ARCHIVED_TASKS_LOADING: string = 'GET_ARCHIVED_TASKS_LOADING';
export const GET_ARCHIVED_TASKS_SUCCESS: string = 'GET_ARCHIVED_TASKS_SUCCESS';
export const GET_ARCHIVED_TASKS_ERROR: string = 'GET_ARCHIVED_TASKS_SUCCESS';

export const SET_SPECIFIC_TASK_UPDATE_LOADING: string = 'SET_SPECIFIC_TASK_UPDATE_LOADING';
