import { IconButton, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useStyles } from './referralViewStyles';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onClose: () => void;
}

const ReferralViewSkeleton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { onClose } = props;

  return (
    <Box display="flex" className={classes.root} flexDirection="column" style={{ height: '100%' }}>
      <Box style={{ padding: `0 24px` }}>
        <Box className={classes.sticky}>
          <Box display="flex" justifyContent="space-between">
            <IconButton onClick={onClose} data-id="btnCloseReferralModal">
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box className={classes.chips} display="flex" alignItems="center" style={{ paddingLeft: 24 }}>
        <Box display="flex" style={{ paddingRight: 12 }}>
          <Skeleton style={{ height: 50, width: 134, borderRadius: 20 }} animation="wave" />
        </Box>
        <Box display="flex" style={{ paddingRight: 12 }}>
          <Skeleton style={{ height: 50, width: 134, borderRadius: 20 }} animation="wave" />
        </Box>
        <Box display="flex">
          <Skeleton style={{ height: 50, width: 134, borderRadius: 20 }} animation="wave" />
        </Box>
      </Box>
      <Box
        style={{ paddingTop: 0, paddingBottom: 0, borderRadius: 6, marginBottom: 0, paddingLeft: 24, paddingRight: 24 }}
      >
        <Skeleton style={{ height: 50, width: '50%', borderRadius: 6 }} animation="wave" />
      </Box>
      <Box
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          marginBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Skeleton style={{ height: 498, width: '100%', marginTop: -100, borderRadius: 6 }} animation="wave" />
      </Box>
      <Box
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          marginBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Skeleton style={{ height: 498, width: '100%', marginTop: -190, borderRadius: 6 }} animation="wave" />
      </Box>
      <Box
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          marginBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Skeleton style={{ height: 120, width: '100%', marginTop: -120, borderRadius: 6 }} animation="wave" />
      </Box>
      <Box
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 6,
          marginBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Skeleton style={{ height: 50, width: '100%', borderRadius: 6 }} animation="wave" />
        <Skeleton style={{ height: 50, width: '100%', borderRadius: 6 }} animation="wave" />
        <Skeleton style={{ height: 50, width: '100%', borderRadius: 6 }} animation="wave" />
      </Box>
    </Box>
  );
};

export default ReferralViewSkeleton;
