import { FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

export function MsTeamsNotificationsSwitch() {
  const [on, setOn] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user-preferences/notifications`)
      .then((response) => setOn(!response.data.teamsBotMute))
      .then(() => setLoading(false))
      .catch(console.error);
  }, [setOn, setLoading]);

  function setOnOff(on: boolean) {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API_URL}/user-preferences/notifications`, {
        teamsBotMute: !on,
      })
      .then(() => setOn(on))
      .then(() => setLoading(false))
      .catch(console.error);
  }

  return (
    <>
      <FormControlLabel
        control={<Switch disabled={loading} checked={on} onChange={(event) => setOnOff(event.target.checked)} />}
        label="Notify in Microsoft Teams"
      />
    </>
  );
}
