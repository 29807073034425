import { BaseAction, medicationsState } from '../types';
import { GET_MEDICATIONS_LOADING, GET_MEDICATIONS_SUCCESS, GET_MEDICATIONS_ERROR } from '../constants/referralTypes';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';

const initialState: medicationsState = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: any = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_MEDICATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_MEDICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.sort(sortByNameKeyCompareFunction),
      };
    case GET_MEDICATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
