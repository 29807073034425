import { Dispatch } from 'redux';
import { getInsurances } from '../../api/insuranceApi';
import { GET_INSURANCES, GET_INSURANCES_SUCCESS, GET_INSURANCES_ERROR } from '../constants/referralTypes';

const getInsurancesLoading = (payload: boolean) => ({
  type: GET_INSURANCES,
  payload,
});

const getInsurancesSuccess = (payload: any) => ({
  type: GET_INSURANCES_SUCCESS,
  payload,
});

const getInsurancesError = (payload: any) => ({
  type: GET_INSURANCES_ERROR,
  payload,
});

export const getInsurancesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getInsurancesLoading(true));
    const response = await getInsurances();
    dispatch(getInsurancesSuccess(response.data));
    dispatch(getInsurancesLoading(false));
  } catch (e) {
    dispatch(getInsurancesError(e));
    dispatch(getInsurancesLoading(false));
  }
};
