import { BaseAction, UsersState } from '../types';
import { FETCH_USERS_LOADING, LOAD_USERS_ERROR, LOAD_USERS_TO_STORE } from '../constants/authTypes';
import { sortByNameKeyCompareFunction } from '../../globalUtils/helpers';

const initialState: UsersState = {
  loading: false,
  data: [],
  error: null,
};

const reducer = (state: UsersState = initialState, action: BaseAction) => {
  switch (action.type) {
    case FETCH_USERS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOAD_USERS_TO_STORE:
      return {
        ...state,
        data: action.payload
          .map((user: any) => ({
            ...user,
            name: user.firstName + ' ' + user.lastName,
          }))
          .sort(sortByNameKeyCompareFunction),
      };
    case LOAD_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
