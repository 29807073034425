import {
  GET_USER_PREFERENCES_ERROR,
  GET_USER_PREFERENCES_LOADING,
  GET_USER_PREFERENCES_SUCCESS,
  UPDATE_USER_PREFERENCES_ERROR,
  UPDATE_USER_PREFERENCES_LOADING,
  UPDATE_USER_PREFERENCES_SUCCESS,
} from '../constants/userPreferencesTypes';
import { UserPreferencePageName } from '../types';

export const getUserPreferencesLoading = (pageName: UserPreferencePageName, isLoading: boolean) => ({
  type: GET_USER_PREFERENCES_LOADING,
  payload: {
    pageName,
    isLoading,
  },
});

export const getUserPreferencesSuccess = (pageName: UserPreferencePageName, payload: any) => ({
  type: GET_USER_PREFERENCES_SUCCESS,
  payload: {
    ...payload,
    pageName,
  },
});

export const getUserPreferencesError = (pageName: UserPreferencePageName, payload: any) => ({
  type: GET_USER_PREFERENCES_ERROR,
  payload: {
    ...payload,
    pageName,
  },
});

export const updateUserPreferencesLoading = (pageName: UserPreferencePageName, isLoading: boolean) => ({
  type: UPDATE_USER_PREFERENCES_LOADING,
  payload: {
    pageName,
    isLoading,
  },
});

export const updateUserPreferencesSuccess = (pageName: UserPreferencePageName, payload: any) => ({
  type: UPDATE_USER_PREFERENCES_SUCCESS,
  payload: {
    ...payload,
    pageName,
  },
});

export const updateUserPreferencesError = (pageName: UserPreferencePageName, payload: any) => ({
  type: UPDATE_USER_PREFERENCES_ERROR,
  payload: {
    ...payload,
    pageName,
  },
});
