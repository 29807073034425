import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStyles, rootInputStyles } from './ReasonDialogStyles';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import { CommentActions, CommentsActionProps } from '../CommentsViewComponent/CommentsViewComponent';
import MentionComponent from '../MentionComponent/MentionComponent';
import { MentionComponentProps } from '../MentionComponent/MentionTypes';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Props as MenuItemSearchProps } from '../MenuItemSearchComponent/MenuItemSearchTypes';
import MenuItemSearchComponent from '../MenuItemSearchComponent/MenuItemSearchComponent';
import { alpha } from '@mui/material/styles';
import { useMeasure } from 'react-use';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NonScrollableMenu from '../Menu/NonScrollableMenu';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export interface ReasonDialogProps extends Partial<DialogProps> {
  title: string;
  open: boolean;
  commentsActionProps?: CommentsActionProps;
  mentionComponentProps?: MentionComponentProps;
  onCancel: () => void;
  actionButtonLabel: string;
  onSubmit: () => void;
  error?: string;
  loading?: boolean;
  bannerTitle?: string;
  bannerText?: string;
  commentLabel?: string;
  selectMenuProps?: SelectMenuProps;
  actionButtonProps?: ButtonProps;
  /**
   * @deprecated Use child elements
   */
  datePickerProps?: Partial<DatePickerProps<any, any> & React.RefAttributes<HTMLDivElement>>;
  /**
   * @deprecated Use child elements
   */
  datePickerInputProps?: TextFieldProps;
  menuItemSearchProps?: {
    onClose?: () => void;
    isErrored?: boolean;
    label?: string;
  } & MenuItemSearchProps;
}

const ReasonDialog: React.FC<ReasonDialogProps> = (props) => {
  const {
    title,
    selectMenuProps,
    commentsActionProps,
    mentionComponentProps,
    error,
    loading,
    onCancel,
    actionButtonLabel,
    onSubmit,
    bannerTitle,
    bannerText,
    commentLabel,
    children,
    actionButtonProps,
    datePickerProps,
    datePickerInputProps,
    menuItemSearchProps,
    ...restProps
  } = props;
  const classes = useStyles({});
  const [menuSearchItemAnchorElement, setMenuSearchItemAnchorElement] = useState<any>(null);
  const theme = useTheme();
  const [dialogContentRef, { width: dialogContentWidth }] = useMeasure();

  const handleCloseMenuItemSearchComponenent = useCallback(() => {
    if (menuItemSearchProps && menuItemSearchProps?.onClose) {
      menuItemSearchProps?.onClose();
    }

    setMenuSearchItemAnchorElement(null);
  }, [menuItemSearchProps]);

  const openMenuItemSearchComponent = (event: React.MouseEvent<HTMLElement>) => {
    setMenuSearchItemAnchorElement(event.currentTarget);
  };

  return (
    <Dialog fullWidth {...restProps} data-id="reasonDialog">
      <DialogTitle classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>
      <DialogContent ref={dialogContentRef}>
        <Box display="flex" className={classes.banner} data-id="banner">
          <Box>
            <ErrorOutlineIcon
              style={{ color: '#0288D1', fontSize: 20, marginTop: 5, marginRight: 16, transform: 'rotate(180deg)' }}
            />
          </Box>
          <Box flex={1}>
            {bannerTitle && (
              <Typography style={{ fontWeight: 500, color: '#013654' }} variant="subtitle1">
                {bannerTitle}
              </Typography>
            )}
            {bannerText && (
              <Typography style={{ color: '#013654' }} variant="body2">
                {bannerText}
              </Typography>
            )}
          </Box>
        </Box>
        {selectMenuProps && (
          <Box className={classes.selectMenu} data-id="reason">
            <SelectMenu {...selectMenuProps} />
          </Box>
        )}
        {menuItemSearchProps && (
          <Box className={classes.selectMenu} data-id="reasons">
            <Button
              style={{
                width: '100%',
                position: 'inherit',
                textTransform: 'none',
                background: menuItemSearchProps?.isErrored
                  ? '#FEEBEE'
                  : Boolean(menuSearchItemAnchorElement)
                  ? alpha(theme.palette.common.black, 0.04)
                  : '',
                padding: 0,
              }}
              onClick={openMenuItemSearchComponent}
              disableRipple
              endIcon={<ArrowDropDownIcon fontSize="large" style={{ marginRight: 12, color: 'black' }} />}
            >
              <Box display="flex" alignItems="center" style={{ width: '100%', height: 48 }}>
                <Box justifyContent="flex-start" style={{ textAlign: 'left', fontSize: 16, fontWeight: 500 }} flex={1}>
                  <Typography
                    color={menuItemSearchProps?.isErrored ? 'error' : 'textPrimary'}
                    style={{
                      paddingLeft: 8,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 506,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {menuItemSearchProps.label}
                  </Typography>
                </Box>
              </Box>
            </Button>
            <NonScrollableMenu
              disableAutoFocus
              anchorEl={menuSearchItemAnchorElement}
              open={Boolean(menuSearchItemAnchorElement)}
              onClose={handleCloseMenuItemSearchComponenent}
            >
              <MenuItemSearchComponent
                {...menuItemSearchProps}
                style={{
                  ...menuItemSearchProps?.style,
                  width: dialogContentWidth,
                }}
                menuTextStyle={{ maxWidth: '100%', width: '100%' }}
                hideActionButtons
                menuItemsContentStyles={{
                  maxHeight: 392,
                }}
              />
            </NonScrollableMenu>
          </Box>
        )}
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
        {commentLabel && (
          <Box style={{ marginTop: 32 }}>
            <Typography variant="subtitle2">{commentLabel}</Typography>
          </Box>
        )}
        {mentionComponentProps && (
          <Box className={classes.commentBox}>
            {mentionComponentProps && (
              <>
                <CommentActions isTopLevel {...commentsActionProps} />
                <MentionComponent {...mentionComponentProps} />
              </>
            )}
          </Box>
        )}
        {datePickerProps && (
          <Box style={{ marginTop: 16 }}>
            <DesktopDatePicker
              views={['day']}
              label={<Box style={{ paddingBottom: 16, marginTop: 0 }}>{datePickerProps.label}</Box>}
              renderInput={(startProps: any) => (
                <Box
                  style={{
                    width: '100%',
                    height: 52,
                    backgroundColor: 'rgba(0,0,0,0.04)',
                    padding: `8px 16px 16px`,
                    borderRadius: 8,
                    boxSizing: 'border-box',
                  }}
                >
                  <TextField
                    {...{ ...startProps, ...datePickerInputProps }}
                    type="date"
                    suffix={<div>Test</div>}
                    size="small"
                    multiline
                    rows={1}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabel,
                      },
                    }}
                    className={classes.input}
                    classes={{ notchedOutline: classes.input, root: classes.inputRoot, label: classes.inputLabel }}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      sx: { ...rootInputStyles, '& label': { top: -10 } },
                      classes: { notchedOutline: classes.input, root: classes.inputRoot },
                      renderSuffix: (suffixProps) => (
                        <Box display="flex" height="100%" style={{ marginTop: -16 }}>
                          <CalendarMonthIcon style={{ color: '#0000008A' }} />
                        </Box>
                      ),
                    }}
                  />
                </Box>
              )}
              onChange={() => null}
              value={null}
              {...datePickerProps}
            />
          </Box>
        )}
        {children && <Box marginTop={1}>{children}</Box>}
      </DialogContent>
      <DialogActions style={{ marginRight: 16 }}>
        <Button disabled={loading} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={onSubmit} variant="contained" {...actionButtonProps}>
          {actionButtonLabel} {loading && <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReasonDialog;
