import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    dialogTitle: {
      fontWeight: 500,
      fontSize: 20,
    },
    banner: {
      padding: 10,
      borderRadius: 4,
      border: `1px solid #0288D1`,
      color: '#0288D1',
    },
    selectMenu: {
      marginTop: 15,
      background: ({ errors }: any) =>
        errors?.waitingReason ? 'rgb(254, 235, 238)' : alpha(theme.palette.common.black, 0.06),
      borderRadius: 4,
    },
    commentBox: {
      marginTop: 10,
    },
    inputLabel: {},
    input: {
      border: 'none',
      borderColor: 'transparent !important',
      '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
        top: -8,
      },
    },
    inputRoot: {
      '&:before': {
        border: 'none !important',
      },
      '&:hover': {
        border: 'none !important',
      },
    },
  };
});

export const rootInputStyles = {
  '&:hover fieldset': {
    border: '2px solid blue!important',
    borderRadius: 0,
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    border: '4px solid red!important',
  },
};
