import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Typography,
} from '@mui/material';
import { useStyles } from './setToStatusTypesStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useGetAssigneeOptions } from '../../globalUtils/hooks';
import { updateReferralStatusesAction } from '../../redux/actions/referralsActions';
import { RootState, StatusReason } from '../../redux/types';
import ReasonDialog, { ReasonDialogProps } from '../ReasonDialog/ReasonDialog';
import { defaultSortCompareFunction } from '../../globalUtils/helpers';
import { CommentTypes } from '../../globalUtils/constants';

interface Props extends Partial<ReasonDialogProps> {
  id: string;
  statusId?: string;
  isStatusChangeDialogOpen: boolean;
  isTemporaryPatient: boolean;
  isTemporaryProvider: boolean;
  reasonOptions: StatusReason[];
  changeStatusToId: 'notCompleted' | 'discarded' | 'onHold';
  setIsStatusChangeDialogOpen: (isOpen: boolean) => void;
  onUpdateSuccess: (response?: any) => void;
  nextFollowupDate?: any;
  isEditMode?: boolean;
  currentReferral: any;
}

const SetToStatusTypeDialog = (props: Props) => {
  const {
    id,
    setIsStatusChangeDialogOpen,
    isStatusChangeDialogOpen,
    statusId,
    isTemporaryPatient,
    isTemporaryProvider,
    reasonOptions,
    changeStatusToId,
    onUpdateSuccess,
    nextFollowupDate,
    commentsActionProps,
    isEditMode,
    onCancel,
    currentReferral,
    actionButtonProps,
    ...restProps
  } = props;
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<{
    reason?: string;
    commentMessage?: string;
  }>({
    reason: '',
  });

  const referralStatusesDefaults = useMemo(() => {
    return {
      notCompleted: {
        label: 'Not Completed',
        param: 'notCompletedReason',
        selectLabel: 'Select not completed reason',
      },
      discarded: {
        label: 'Discarded',
        param: 'canceledReason',
        selectLabel: 'Select the reason for Discarding this Referral',
      },
      onHold: {
        label: 'On Hold',
        param: 'onHoldReason',
        selectLabel: 'Select a reason',
      },
    };
  }, []);

  const reasonsThatShouldNotTriggerConfirmationModal: string[] = ['canceledReason', 'onHoldReason'];
  const [isOpenRequirementsDialog, setIsOpenRequirementsDialog] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [statusChangeReason, setStatusChangeReason] = useState<any>({});
  const [contactedChosen, setContactedChosen] = useState<boolean>(false);
  const [followedUpChosen, setFollowUpChosen] = useState<boolean>(false);
  const [publishToEMRChosen, setPublishToEMRChosen] = useState<boolean>(true);
  const [commentMessage, setCommentMessage] = useState('');
  const [message, setMessage] = useState<string>('');
  const [mentions, setMentions] = useState<any[]>([]);
  const assigneeOptions: any[] = useGetAssigneeOptions();
  const classes = useStyles({});
  const loggedInAssignee = assigneeOptions.find((assignee: any) => assignee.isLoggedInUser);
  const { referrals: referralsState } = useSelector((state: RootState) => state);
  const loading = referralsState?.editReferral?.loading;

  const reasons = useMemo(() => {
    return reasonOptions.sort().map((reason: StatusReason) => {
      return {
        ...reason,
        label: reason?.title,
        searchString: reason?.title,
      };
    });
  }, [reasonOptions]);

  const onChangeComments = (comments: string, mentions: any[]) => {
    setCommentMessage(comments);
    setMentions(mentions);
  };

  const handleReasonChange = (value: any) => {
    setStatusChangeReason(value);
    if (value) {
      setErrors({ ...errors, reason: undefined });
    }
  };

  const onSuccess = (responseData: any) => {
    handleCancel();
    onUpdateSuccess(responseData);
    setPublishToEMRChosen(true);
  };

  const getPayload = (nextFollowupDate: any, params: any, statusId?: string, isEditMode?: boolean): any => {
    const updatedParams = nextFollowupDate ? { nextFollowupDate, ...params } : params;
    return !isEditMode ? { ...updatedParams, statusId: statusId || '' } : updatedParams;
  };

  const handleChangeStatus = () => {
    if (!statusChangeReason?.id) {
      setErrors({
        reason: 'Reason is required',
      });
      return;
    }

    const params = {
      statusReasonId: statusChangeReason?.id || '',
      patientContactTag: contactedChosen,
      referralFollowupTag: followedUpChosen,
      tagUserIds: mentions.map(({ id }) => id),
      message: commentMessage ? commentMessage : message,
      commentType:
        commentMessage || message ? (publishToEMRChosen ? CommentTypes.EXTERNAL : CommentTypes.INTERNAL) : undefined,
    };

    dispatch(updateReferralStatusesAction(id, getPayload(nextFollowupDate, params, statusId, isEditMode), onSuccess));
  };

  const handleCancel = () => {
    setIsStatusChangeDialogOpen(false);
    setStatusChangeReason({});
    onChangeComments('', []);
    setIsConfirmDialogOpen(false);
    setContactedChosen(false);
    setFollowUpChosen(false);
    setMentions([]);
    onCancel && onCancel();
    setPublishToEMRChosen(true);
  };

  useEffect(() => {
    if ((isTemporaryPatient || isTemporaryProvider) && isStatusChangeDialogOpen) {
      setIsOpenRequirementsDialog(true);
    }
  }, [isTemporaryPatient, isTemporaryProvider, isStatusChangeDialogOpen]);

  const handleCloseRequirementsDialog = () => {
    setIsOpenRequirementsDialog(false);
    setIsStatusChangeDialogOpen(false);
  };

  const handleOpenConfirmDialog = () => {
    if (!statusChangeReason?.id) {
      setErrors({
        reason: 'Reason is required',
      });
      return;
    }

    setIsStatusChangeDialogOpen(false);
    setIsConfirmDialogOpen(true);
  };

  useEffect(() => {
    const reason: string = statusChangeReason?.label;

    setMessage(
      reason
        ? `${loggedInAssignee?.label} changed the referral status to ${referralStatusesDefaults[changeStatusToId].label} for the reason ${reason} `
        : '',
    );
  }, [changeStatusToId, loggedInAssignee?.label, referralStatusesDefaults, statusChangeReason?.label]);

  return (
    <>
      <Dialog open={isOpenRequirementsDialog}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          Information required to change Referral Status
        </DialogTitle>
        <DialogContent>
          Please update following details before changing status:
          {isTemporaryPatient && <ListItemText primary="- Select WeInfuse Patient" />}
          {isTemporaryProvider && <ListItemText primary="- Select WeInfuse Provider" />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRequirementsDialog}>Okay</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmDialogOpen}>
        <DialogTitle
          classes={{ root: classes.dialogTitle }}
        >{`Set Referral to ${referralStatusesDefaults[changeStatusToId].label}`}</DialogTitle>
        <DialogContent>
          Please ensure the “Upload Documents” and “Weinfuse Entry” tasks are completed. Other tasks will be inactivated
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleChangeStatus} variant="contained">
            {`Confirm and ${!isEditMode ? 'Set To' : 'Edit'} ${referralStatusesDefaults[changeStatusToId].label}`}
            {loading && <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.26)' }} size={16} />}
          </Button>
        </DialogActions>
      </Dialog>
      <ReasonDialog
        open={isStatusChangeDialogOpen && !(isTemporaryPatient || isTemporaryProvider)}
        title={`${!isEditMode ? 'Set To' : 'Edit'} ${referralStatusesDefaults[changeStatusToId].label}`}
        bannerTitle="Select Reason"
        bannerText={`Please select the reason to set status to “${referralStatusesDefaults[changeStatusToId].label}”. ${
          !isEditMode
            ? 'This referral will be moved to archive after this status change.'
            : 'This referral will remain archived.'
        }`}
        selectMenuProps={{
          hideAddnew: true,
          label: (
            <Typography variant="body1" style={{ paddingLeft: 8 }} color={errors?.reason ? 'error' : 'textPrimary'}>
              {referralStatusesDefaults[changeStatusToId].selectLabel}
            </Typography>
          ),
          showExpandMore: true,
          options: reasons
            .map((reason: StatusReason) => ({
              ...reason,
              disabled: statusChangeReason?.id === reason?.id || reason?.id === currentReferral?.statusReason?.id,
              showDoneIcon:
                statusChangeReason?.id === reason?.id ||
                (!statusChangeReason?.id && reason?.id === currentReferral?.statusReason?.id),
            }))
            .sort((a, b) => defaultSortCompareFunction(a, b, 'title')),
          value: statusChangeReason?.id
            ? statusChangeReason
            : reasons.find((reason: StatusReason) => reason.id === currentReferral?.statusReason?.id),
          onChange: (value) => handleReasonChange(value),
        }}
        error={errors?.commentMessage}
        commentLabel="Comment"
        onCancel={handleCancel}
        onSubmit={
          reasonsThatShouldNotTriggerConfirmationModal.includes(referralStatusesDefaults[changeStatusToId].param) ||
          isEditMode
            ? handleChangeStatus
            : handleOpenConfirmDialog
        }
        actionButtonLabel={`${!isEditMode ? 'Set To' : 'Edit'} ${referralStatusesDefaults[changeStatusToId].label}`}
        mentionComponentProps={{
          loading: false,
          hideSend: true,
          showMentionTrigger: false,
          onSend: () => null,
          id,
          onChange: onChangeComments,
          placeholder: 'Type comment..',
          startValue: message,
          publishToEMRSelected: publishToEMRChosen,
          isExpanded: true,
          disablePublishToEMR: loading,
          onSelectPublishToEMR: () => {
            setPublishToEMRChosen(!publishToEMRChosen);
          },
        }}
        commentsActionProps={{
          contactedChosen,
          followedUpChosen,
          onContactedButtonClick: () => {
            setContactedChosen(!contactedChosen);
          },
          onFollowUpButtonClick: () => {
            setFollowUpChosen(!followedUpChosen);
          },
          ...commentsActionProps,
        }}
        loading={loading}
        actionButtonProps={{ ...actionButtonProps, disabled: loading || !statusChangeReason?.id }}
        {...restProps}
      />
    </>
  );
};

export default SetToStatusTypeDialog;
