import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Typography,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useStyles } from './TasklistStyles';
import TaskItem from './TaskItem';
import { useDispatch, useSelector } from 'react-redux';
import { sortOrderFunction } from '../../globalUtils/helpers';
import {
  addNewTaskAction,
  clearTaskTypesState,
  getTasksCountAction,
  updateTaskStatus,
} from '../../redux/actions/tasksActions';
import template from '../../images/template.svg';
import assigneeImage from '../../images/assigneePrimary.svg';
import SelectMenu from '../SelectMenu/SelectMenu';
import { Referral, ReferralStatus, RootState } from '../../redux/types';
import { getIdsFromArray, getTaskColors, shouldDisplayNATasks, statusDefaults } from '../../globalUtils/utils';
import EditAssignee from '../EditAssignee/EditAssignee';
import WaitingDialog, { updateTaskParams } from './WaitingDialog';
import validate from 'validate.js';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { getOneReferral } from '../../redux/actions/referralsActions';

interface Props {
  referralId: string;
  tasks: any[];
  referralStatus?: ReferralStatus;
  avatarLoading?: boolean;
  activeTasksTab?: string;
  totalTasksLoaded?: number;
  showMedicationChangedAlert: boolean;
  referral: Referral;
}

const TaskList: FC<Props> = (props) => {
  const shouldShowNATasksByDefault = shouldDisplayNATasks();

  const { referralId, referralStatus, activeTasksTab, totalTasksLoaded, showMedicationChangedAlert, referral } = props;
  const classes = useStyles({});
  const tasks = (props.tasks || []).sort(sortOrderFunction);
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
  const [shouldResetValues, setShouldResetValues] = useState(false);
  const [isEditingWaitingReason, setIsEditingWaitingReason] = useState(false);
  const [isLoadingStatusChange, setIsLoadingStatusChange] = useState(false);
  const [shouldShowNATasks, setShouldShowNATasks] = useState(shouldShowNATasksByDefault || false);

  const [waitingDialogOpen, setWaitingDialogOpen] = useState(false);
  const [task, setTask] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [newTaskData, setNewTaskData] = useState({
    task: { name: '', id: '' },
    status: { name: '', id: '' },
    assignee: { id: '' },
    waitingReasons: [],
    messageAndTaggedUsers: {
      taggedUsersIds: [],
      message: '',
      contactedChosen: false,
      followedUpChosen: false,
    },
  });

  const dispatch = useDispatch();

  const completed = tasks.filter((task) => task.status?.name === 'COMPLETED');
  const validTasks = tasks.filter((task) => task.status?.name !== 'NOT APPLICABLE');

  const progressValue = (completed.length / (validTasks.length || 1)) * 100;

  const taskData = useSelector((state: RootState) => state.taskTemplates);
  const { taskTypes, addNewTask, filters } = useSelector((state: RootState) => state.tasks);
  const toDoStatus = useMemo(() => {
    return taskData?.taskStatuses?.find((task) => task?.name?.toUpperCase() === statusDefaults.NOTREADY);
  }, [taskData?.taskStatuses]);
  const { taskTemplates } = useSelector((state: RootState) => state);

  const waitingStatusId = taskData?.taskStatuses?.find((status) => status?.name?.toLowerCase() === 'waiting')?.id;

  const statuses = taskData?.taskStatuses || [];

  const existingTasksIds = tasks?.map((task) => task?.taskTypeId);
  const uniqueTaskTypes = useMemo(() => {
    return taskTypes?.data?.filter((taskType: any) => !existingTasksIds.includes(taskType.id));
  }, [existingTasksIds, taskTypes?.data]);

  const taskTypesOptions = useMemo(() => {
    return uniqueTaskTypes.map((taskType: any) => {
      return { ...taskType, label: taskType.name, searchString: taskType.name };
    });
  }, [uniqueTaskTypes]);

  const validationSchema: any = {
    taskTypeId: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    statusId: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
    assignedUserId: {
      presence: {
        allowEmpty: false,
        message: 'is required',
      },
    },
  };

  const onAddNewTaskSuccess = () => {
    if (newTaskData?.messageAndTaggedUsers?.contactedChosen || newTaskData?.messageAndTaggedUsers?.followedUpChosen) {
      //refetch open referral so as to update last follow up dates
      dispatch(getOneReferral(referralId, true));
    }

    if (activeTasksTab) {
      dispatch(
        getTasksCountAction(
          {
            ...filters,
            active: true,
          },
          getIdsFromArray(taskTemplates?.archivedTabTaskStatuses),
          getIdsFromArray(taskTemplates?.openTabTaskStatuses),
        ),
      );
      setShouldResetValues(!shouldResetValues);
    }
  };

  const handleAddNewTask = () => {
    const newTaskDetails = {
      taskTypeId: newTaskData.task?.id,
      statusId: newTaskData.status?.id,
      ...(newTaskData.assignee?.id && { assignedUserId: newTaskData.assignee?.id }),
      ...(newTaskData.status.name?.toUpperCase() === 'WAITING' && { statusReasonIds: newTaskData.waitingReasons }),
      referralId,
      ...(newTaskData?.messageAndTaggedUsers?.message && { message: newTaskData?.messageAndTaggedUsers?.message }),
      ...(newTaskData?.messageAndTaggedUsers?.taggedUsersIds && {
        tagUserIds: newTaskData?.messageAndTaggedUsers?.taggedUsersIds,
      }),
      ...(newTaskData?.messageAndTaggedUsers?.contactedChosen && {
        patientContactTag: newTaskData?.messageAndTaggedUsers?.contactedChosen,
      }),
      ...(newTaskData?.messageAndTaggedUsers?.followedUpChosen && {
        referralFollowupTag: newTaskData?.messageAndTaggedUsers?.followedUpChosen,
      }),
    };

    let validationErrors: any = validate(newTaskDetails, validationSchema);
    if (newTaskData.status.name?.toUpperCase() === 'WAITING' && !newTaskData.waitingReasons.length) {
      validationErrors = { ...validationErrors, waitingReason: 'Waiting reason is required for WAITING status' };
      setErrors({ ...errors, waitingReason: ['Waiting reason is required for WAITING status'] });

      const taskSelected: any = taskTypes.data.find((task) => task?.id === newTaskDetails?.taskTypeId) || {};
      setTask({ ...taskSelected, title: taskSelected?.title || taskSelected?.name || '' });

      setWaitingDialogOpen(true);
    }

    setErrors(() => (validationErrors ? validationErrors : {}));
    if (!validationErrors) {
      dispatch(addNewTaskAction(newTaskDetails, onAddNewTaskSuccess));
      setErrors({});
    }
  };

  const handleOpenAddTask = () => {
    setAddTaskDialogOpen(true);
  };

  const handleCloseAddTask = () => {
    setAddTaskDialogOpen(false);
    dispatch(clearTaskTypesState());
    setErrors({});
    setNewTaskData({
      task: { name: '', id: '' },
      status: { name: '', id: '' },
      assignee: { id: '' },
      waitingReasons: [],
      messageAndTaggedUsers: {
        taggedUsersIds: [],
        message: '',
        contactedChosen: false,
        followedUpChosen: false,
      },
    });
    setShouldResetValues(!shouldResetValues);
  };

  const handleChange = (fieldName: string, value: any) => {
    if (value?.name?.toUpperCase() === 'WAITING') {
      setWaitingDialogOpen(true);
      // setTask({});
    }
    setNewTaskData((newTaskData) => ({ ...newTaskData, [fieldName]: value }));
  };

  useEffect(() => {
    if (addNewTask.success) {
      handleCloseAddTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewTask.success]);

  // append task to default to do
  useEffect(() => {
    if (toDoStatus?.id) {
      handleChange('status', toDoStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDoStatus, addTaskDialogOpen]);

  const statusOptions = statuses
    .map((item: any) => {
      return {
        ...item,
        label: item.name,
        searchString: item.name,
        listItemView: (
          <Box display="flex">
            <img alt={''} style={{ marginRight: 16 }} src={getTaskColors(item.name).iconImage} />
            <Typography style={{ textTransform: 'capitalize' }} variant="body1">
              {item.name?.toLowerCase()}
            </Typography>
          </Box>
        ),
      };
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);

  const handleEditReasonClick = (task: any) => {
    setTask(task);
    setWaitingDialogOpen(true);
    setIsEditingWaitingReason(true);
  };

  const handleSubmitSetTaskToWaiting = (params: updateTaskParams, callback: () => void) => {
    setIsLoadingStatusChange(true);
    const onSuccess = () => {
      onAddNewTaskSuccess();
      setWaitingDialogOpen(false);
      setIsLoadingStatusChange(false);
      dispatch(getOneReferral(referralId, true));
      setShouldResetValues(!shouldResetValues);
      setIsEditingWaitingReason(false);
      callback && callback();
    };

    const onError = () => {
      setIsLoadingStatusChange(false);
      callback && callback();
    };

    dispatch(updateTaskStatus(params, onSuccess, onError));
  };

  useEffect(() => {
    localStorage.setItem('shouldShowNATasks', shouldShowNATasks.toString());
  }, [shouldShowNATasks]);

  const handleShowOrHideNATasks = () => {
    setShouldShowNATasks(!shouldShowNATasks);
  };

  const tasksToDisplay = shouldShowNATasks ? validTasks : tasks;

  const isSelectedOption = (option: any, value: any): boolean => option?.id === value?.id;

  return (
    <Box>
      <Dialog fullWidth classes={{ paper: classes.paper }} open={addTaskDialogOpen}>
        <DialogTitle>Add new task</DialogTitle>
        <DialogContent>
          <Box className={classes.addTasksWrapper}>
            <Box display="flex" alignItems="center" className={classes.label}>
              <Typography variant="body2" color="#00000099" fontWeight={500} fontSize={14}>
                Task
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" className={classes.labelInput}>
              <SelectMenu
                label={
                  <Box>
                    <Box>
                      <Typography color={errors?.taskTypeId ? 'error' : '#00000099'} fontWeight={500} fontSize={14}>
                        Select Task
                      </Typography>{' '}
                    </Box>
                    {errors?.taskTypeId && (
                      <Box>
                        <Typography variant="caption" color="error">
                          Task is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                }
                showExpandMore={!taskTypes?.loading}
                hideAddnew
                loading={taskTypes?.loading}
                icon={<img alt="" src={template} />}
                valueIcon={<img alt="" src={template} />}
                options={taskTypesOptions
                  .map((option) => ({
                    ...option,
                    disabled: option?.disabled || isSelectedOption(option, newTaskData?.task),
                    showDoneIcon: isSelectedOption(option, newTaskData?.task),
                  }))
                  .sort((a, b) => a?.label?.localeCompare(b?.label))}
                value={newTaskData?.task}
                onChange={(value) => {
                  const taskSelected: any = taskTypes.data.find((task) => task?.id === value?.id) || {};
                  setTask({ ...taskSelected, title: taskSelected?.title || taskSelected?.name || '' });
                  handleChange('task', value);
                  if (taskSelected.defaultAssigneeId) {
                    handleChange('assignee', { id: taskSelected.defaultAssigneeId });
                  }
                }}
              />
            </Box>

            <Box display="flex" alignItems="center" className={classes.label}>
              <Typography variant="body2" color="#00000099" fontWeight={500} fontSize={14}>
                Status
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" className={classes.labelInput}>
              <SelectMenu
                hideAddnew
                showExpandMore
                valueIcon={<img alt="" src={getTaskColors(newTaskData.status?.name).iconImage} />}
                icon={<img alt="" src={getTaskColors(newTaskData?.status?.name).iconImage} />}
                options={statusOptions.map((option) => ({
                  ...option,
                  disabled: option?.disabled || isSelectedOption(option, newTaskData?.status),
                  showDoneIcon: isSelectedOption(option, newTaskData?.status),
                }))}
                onChange={(value) => handleChange('status', value)}
                value={newTaskData?.status}
                label={
                  <>
                    <Typography color={errors?.waitingReason ? 'error' : 'textPrimary'} fontWeight={500} fontSize={14}>
                      {newTaskData?.status?.name}
                    </Typography>
                  </>
                }
              />
            </Box>
            <Box display="flex" alignItems="center" className={classes.label}>
              <Typography variant="body2" color="#00000099" fontWeight={500} fontSize={14}>
                Assignee
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" className={classes.labelInput}>
              <EditAssignee
                showExpandMore
                showClearIcon={false}
                icon={<img alt="" src={assigneeImage} />}
                label={
                  <Box>
                    <Box>
                      <Typography
                        color={errors?.assignedUserId ? 'error' : 'textPrimary'}
                        fontWeight={500}
                        fontSize={14}
                      >
                        Select assignee
                      </Typography>
                    </Box>
                    {errors?.assignedUserId && (
                      <Box>
                        <Typography variant="caption" color="error">
                          Assignee is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                }
                onChange={(value) => handleChange('assignee', value)}
                filterOutInactiveAssignees
                initialValue={task.defaultAssigneeId && { id: task.defaultAssigneeId }}
                disabled={Boolean(task.defaultAssigneeId)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddTask}>Cancel</Button>
          <Button disabled={addNewTask.loading} variant="contained" onClick={handleAddNewTask}>
            Create task
            {addNewTask.loading && <CircularProgress size={16} />}
          </Button>
        </DialogActions>
      </Dialog>
      <WaitingDialog
        id={task?.id}
        loading={isLoadingStatusChange}
        handleChange={handleChange}
        waitingStatusId={waitingStatusId || ''}
        isEditing={isEditingWaitingReason}
        shouldSubmitOnWaitingClick={!!isEditingWaitingReason}
        handleSubmitSetTaskToWaiting={handleSubmitSetTaskToWaiting}
        isWaitingDialogOpen={waitingDialogOpen}
        setWaitingDialogOpen={setWaitingDialogOpen}
        shouldResetValues={shouldResetValues}
        errors={{ waitingReason: errors?.waitingReason }}
        handleCancel={() => {
          setIsEditingWaitingReason(false);
          setTask({});
        }}
        title={task?.title || ''}
        task={task}
      />
      <Box style={{ marginBottom: 16 }} display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h6">Tasks</Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={shouldShowNATasks} onChange={handleShowOrHideNATasks} />}
              label={"Hide 'Not Applicable' tasks"}
            />
          </FormGroup>
        </Box>
        {referralStatus?.title?.toLowerCase() !== 'draft' && (
          <span>
            <IconButton onClick={handleOpenAddTask} className={classes.addIconButton}>
              <AddSharpIcon fontSize="large" />
            </IconButton>
          </span>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box style={{ flex: 1 }}>
          <LinearProgress
            classes={{ root: classes.progress, bar: classes.bar, colorSecondary: classes.colorSecondary }}
            className={classes.progress}
            variant="determinate"
            color="secondary"
            value={progressValue}
          />
        </Box>
        <Box style={{ marginLeft: 16 }}>
          <Typography variant="subtitle2" color="text.secondary">{`${Math.ceil(progressValue)}%`}</Typography>
        </Box>
      </Box>
      <List style={{ marginLeft: -16 }}>
        {tasksToDisplay.map((task, i) => {
          return (
            <TaskItem
              avatarLoading={props.avatarLoading}
              key={task.id}
              {...task}
              id={task?.id}
              referral={referral}
              disabled={!task?.active}
              activeTab={activeTasksTab}
              totalTasksLoaded={totalTasksLoaded}
              handleEditReasonClick={() => handleEditReasonClick(task)}
              referralStatus={referralStatus}
              showMedicationChangedAlert={showMedicationChangedAlert && task?.title?.toLowerCase() === 'order entry'}
            />
          );
        })}
        {referralStatus?.title?.toLowerCase() !== 'draft' && (
          <ListItem alignItems="flex-start">
            <span>
              <Button
                onClick={handleOpenAddTask}
                className={classes.newTaskButton}
                variant="text"
                style={{
                  textTransform: 'none',
                }}
              >
                New task
              </Button>
            </span>
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default TaskList;
