import { GenericCommentComponent } from '../../../../components/CommentsViewComponent/GenericCommentComponent';
import { Box, Chip, ChipProps, LinearProgress, List, Tooltip, Typography } from '@mui/material';
import { usePatientMessagesController } from '../../index';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export function PatientMessageHistoryComponent({
  messages,
  loading,
}: ReturnType<typeof usePatientMessagesController>['messageView']) {
  return (
    <Box>
      <List>
        {messages.map((message) => (
          <GenericCommentComponent
            key={message.id}
            username={message.author}
            timestamp={message.dateSent}
            headings={
              message.direction === 'outbound'
                ? [
                    <Typography key="status">
                      <Tooltip title={message.status.tooltip || ''}>
                        <Chip
                          icon={message.status.tooltip ? <ErrorOutlineOutlinedIcon /> : undefined}
                          size="small"
                          label={message.status.text}
                          color={message.status.color as ChipProps['color']}
                        />
                      </Tooltip>
                    </Typography>,
                  ]
                : []
            }
          >
            <Box style={{ display: 'grid', gap: '1rem' }}>
              {message.media.map((media) => {
                return (
                  <img
                    key={media.url}
                    src={media.url}
                    style={{ maxWidth: '100%', maxHeight: '100px', cursor: 'pointer' }}
                    alt="mms-message"
                    //loading="lazy" // was causing load to fail, "connection reset"
                    onClick={() => window.open(media.url, 'tab')}
                  />
                );
              })}
              <div style={{ whiteSpace: 'pre-wrap' }}>{message.body}</div>
            </Box>
          </GenericCommentComponent>
        ))}
        {loading && <LinearProgress key="loading" />}
      </List>
    </Box>
  );
}
