import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import logger from 'redux-logger';

let middleWares: any[] = [thunk];

if (process.env.NODE_ENV === 'development') {
  middleWares = [...middleWares, logger];
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleWares)));
// @ts-ignore
window.store = store;

export default store;
