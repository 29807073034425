import { Dialog, DialogContent, DialogTitle, Typography, IconButton, Box } from '@mui/material';
import { FC, useMemo } from 'react';
import xIcon from '../../images/X.svg';

interface Props {
  handleClosePreview: () => void;
  open: boolean;
  pdfToPreview: any;
  title?: string;
}
const PDFPreviewModal: FC<Props> = (props) => {
  const { open, handleClosePreview, pdfToPreview, title } = props;

  const pdfFileURL = useMemo(() => (pdfToPreview ? URL.createObjectURL(pdfToPreview) : ''), [pdfToPreview]);

  return (
    <Dialog open={open} fullScreen data-id="pdf-preview">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {title && <Typography>{title}</Typography>}
          <IconButton onClick={handleClosePreview}>
            <img src={xIcon} alt="close" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <embed src={pdfFileURL} type="application/pdf" height="100%" width="100%"></embed>
      </DialogContent>
    </Dialog>
  );
};

export default PDFPreviewModal;
