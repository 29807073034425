import { Dispatch } from 'redux';
import { getWeInfuseConnectionApi } from '../../api/weInfuseApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import { setWeInfuseEnabledStatus } from '../actionCreators/weInfuseActionCreators';

export const getWeInfuseConnectionAction = () => async (dispatch: Dispatch) => {
  try {
    const response = await getWeInfuseConnectionApi();
    dispatch(setWeInfuseEnabledStatus(response.data?.enabled));
  } catch (error) {
    const errorMessage: string = backendErrorHandler(error);
    showErrorMessage(errorMessage);
  } finally {
  }
};
