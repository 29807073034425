import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => {
  return {
    paper: {
      width: '700px',
      minHeight: '447px',
    },
    halfWidth: {
      width: '50%',
      height: '56px',
    },
    fullWidth: {
      width: '100%',
    },
    boxWrap: {
      marginBottom: 24,
    },
  };
});
