import { referralReasonsSortFunction, taskReasonsSortFunction } from '../../globalUtils/helpers';
import {
  GET_STATUS_REASONS_ERROR,
  SET_STATUS_REASONS_LOADING,
  GET_STATUS_REASONS_SUCCESS,
} from '../constants/statusReasonsTypes';
import { BaseAction, StatusReason, StatusReasonsStore } from '../types';

const initialState: StatusReasonsStore = {
  loading: false,
  tasksStatusReasons: [],
  referralStatusReasons: [],
  reasons: [],
  error: undefined,
};

const statusReasonsReducer = (state: StatusReasonsStore = initialState, action: BaseAction): StatusReasonsStore => {
  switch (action.type) {
    case SET_STATUS_REASONS_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: undefined,
      };
    case GET_STATUS_REASONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_STATUS_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload,
        tasksStatusReasons: action.payload
          .filter((reason: StatusReason) => reason?.taskStatus?.id)
          ?.sort(taskReasonsSortFunction),
        referralStatusReasons: action.payload
          .filter((reason: StatusReason) => reason?.referralStatus?.id)
          ?.sort(referralReasonsSortFunction),
        loading: false,
      };
    default:
      return state;
  }
};

export default statusReasonsReducer;
