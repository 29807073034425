import { Task } from '../../../redux/types';
import { useEffect, useState } from 'react';

export function PriorAuthTaskController(server: PriorAuthTaskServer, isAppeal: boolean = false) {
  return function usePriorAuthTaskController(
    taskId: Task['id'],
    taskStatus: Task['statusId'],
    onTaskStatusChange: () => void,
  ) {
    const [state, setState] = useState({
      loading: true,
      actionsProcessing: false,
      taskState: null as PriorAuthTaskState | null,
      dialogOpen: false,
    });

    useEffect(() => {
      server.getStatus(taskId).then((taskState) =>
        setState((state) => ({
          ...state,
          loading: false,
          taskState,
        })),
      );
    }, [taskId, setState, taskStatus]);

    return {
      isLoading: state.loading,
      showDecision: state.loading || !state.taskState?.isComplete || typeof state.taskState?.isApproved !== 'undefined',
      result:
        !state.taskState || !state.taskState.isComplete
          ? ''
          : state.taskState.isApproved
          ? 'Approved'
          : addAppealText('Denied'),
      canChangeStatus: !state.loading && typeof state.taskState?.isApproved === 'undefined',
      dialog: {
        isOpen: state.dialogOpen,
        close: () => setState((state) => ({ ...state, dialogOpen: false })),
      },
      validateStatusChange: (statusName: string) => {
        if (statusName === 'COMPLETED') {
          setState((state) => ({ ...state, dialogOpen: true }));
          return false;
        }

        return true;
      },
      actions: {
        processing: state.actionsProcessing,
        buttons:
          state.taskState && state.taskState.isComplete
            ? [
                {
                  label: 'Undo',
                  click: () => takeAction(server.undo.bind(server)),
                },
              ]
            : [
                {
                  label: 'Approved',
                  click: () => takeAction(server.approve.bind(server)),
                },
                {
                  label: 'Denied',
                  click: () => takeAction((taskId) => server.deny(taskId).then((result) => result.originalTask)),
                },
              ],
      },
    };

    function takeAction(serverAction: (taskId: string) => Promise<PriorAuthTaskState>) {
      setState((state) => ({
        ...state,
        actionsProcessing: true,
      }));
      serverAction(taskId)
        .then((result) =>
          setState((state) => ({
            ...state,
            taskState: result,
            dialogOpen: false,
          })),
        )
        .then(onTaskStatusChange)
        .catch(console.error)
        .finally(() => setState((state) => ({ ...state, actionsProcessing: false })));
    }

    function addAppealText(label: string) {
      if (isAppeal) {
        return label;
      }
      if (state.taskState?.hasAppeal) {
        return label + ' - Appeal';
      } else {
        return label + ' - No Appeal';
      }
    }
  };
}

export type PriorAuthTaskState = { isComplete: boolean; isApproved?: boolean; hasAppeal: boolean; isActive: boolean };

export type PriorAuthDenial = { originalTask: PriorAuthTaskState; appealTask: PriorAuthTaskState };

export interface PriorAuthTaskServer {
  getStatus(taskId: Task['id']): Promise<PriorAuthTaskState>;
  approve(taskId: Task['id']): Promise<PriorAuthTaskState>;
  deny(taskId: Task['id']): Promise<PriorAuthDenial>;
  undo(taskId: Task['id']): Promise<PriorAuthTaskState>;
}
