import { TaskLogic } from '../taskTypes';
import { Task } from '../../../redux/types';

export function SchedulingTaskType(task: Task): TaskLogic {
  return {
    taskBody: null,
    statusChangeHook: {
      allowChange: isAllowed,
      component: null,
      getStatusOptionDisplayState: (statusName) => ({
        disabled: !isAllowed(statusName),
      }),
    },
    taskTitleIcon: null,
  };

  function isAllowed(statusName: string) {
    if (statusName === 'READY') {
      return task.referral.statusId === '0ED7026B-EE9E-41CE-B5C6-3ABA354CD1E8';
    }
    if (statusName === 'COMPLETED') {
      return task.referral.statusId === '3F712277-31D9-4B20-BECE-5678FB3FF04A';
    }
    return true;
  }
}
