import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { updateReferralFilters } from '../../redux/actions/referralProvidersAction';
import { format } from 'date-fns';
import { getFiltersFromSavedPreferences, ignoreTimezoneDate, saveUserPreferences } from '../../globalUtils/utils';
import { initialState as referralsInitialState } from '../../redux/reducers/referralsReducer';
import { Filter, FilterIndicator } from '../FilterIndicator/FilterIndicator';
import { Clinic } from '../../../../Referral-API/src/clinics/entities/clinic.entity';

const ReferralFilterIndicator = () => {
  const filters = useSelector((state: RootState) => state.referrals.filters);
  const users = useSelector((state: RootState) => state.users.data);
  const medications = useSelector((state: RootState) => state.medications.data);
  const statuses = useSelector((state: RootState) => state.referrals.referralStatuses.data);
  const regions = useSelector((state: RootState) => state.regions.data);
  const clinics = useSelector((state: RootState) => state.clinics.data);
  const {
    userPreferences: { referrals: savedReferralFilters },
  } = useSelector((state: RootState) => state);
  const referralSubTypes = filters?.referralSubTypes || [];

  const filteredUsers = users.filter(({ id }) => filters?.assignedUsers?.includes(id));
  const specialistFilters = users.filter(({ id }) => filters?.intakeSpecialists?.includes(id));
  const filteredMedications = medications.filter(({ id }) => filters?.medications?.includes(id));
  const statusFilters: any[] = statuses.filter(({ id }: any) => filters?.statuses?.includes(id));
  const regionFilters = regions.filter(({ id }: any) => filters?.regions?.includes(id));
  const clinicFilters = clinics.filter(({ id }: any) => filters?.clinics?.includes(id));
  const providerFilters = filters?.providers;

  const hasNullMedication = (filters?.medications?.length || 0) !== filteredMedications?.length;

  const dispatch = useDispatch();
  const hasSavedFilters: boolean = useMemo((): boolean => {
    return !!(
      (savedReferralFilters?.sort?.field && savedReferralFilters?.sort?.direction) ||
      savedReferralFilters?.filters?.length
    );
  }, [savedReferralFilters]);

  const allFilters = [
    {
      label: 'Assignee',
      key: 'assignedUsers',
      values: filteredUsers.map((user) => ({
        label: `${user?.firstName || '-'} ${user?.lastName || '-'}`,
        value: user.id,
      })),
    },
    {
      label: 'Intake Specialist',
      key: 'intakeSpecialists',
      values: specialistFilters.map((user) => ({
        label: `${user?.firstName || '-'} ${user?.lastName || '-'}`,
        value: user.id,
      })),
    },
    {
      label: 'Medication',
      key: 'medications',
      values: [
        ...(hasNullMedication ? [{ label: 'No medication', value: null }] : []),
        ...filteredMedications.map((medication) => ({
          label: medication.name,
          value: medication.id,
        })),
      ],
    },
    {
      label: 'Region',
      key: 'regions',
      values: regionFilters.map((region) => ({
        label: region.name,
        value: region.id,
      })),
    },
    {
      label: 'Status',
      key: 'statuses',
      values: statusFilters.map((status) => ({
        label: status.title,
        value: status.id,
      })),
    },
    {
      label: 'Clinic',
      key: 'clinics',
      values: clinicFilters.map((clinic: Clinic) => ({
        label: clinic.name,
        value: clinic.id,
      })),
    },
    {
      label: 'Types',
      key: 'referralSubTypes',
      values: referralSubTypes.map((key: string) => ({
        label: key,
        value: key,
      })),
    },
    {
      label: 'Providers',
      key: 'providers',
      values: providerFilters.map((provider) => ({
        label: `${provider?.firstName || '-'} ${provider?.lastName || '-'}`,
        value: provider.id,
      })),
    },
    {
      label: 'Next Follow Up',
      key: 'nextFollowUp',
      values:
        filters.toNextFollowupDate && filters.fromNextFollowupDate
          ? [
              {
                label: `${format(ignoreTimezoneDate(filters.fromNextFollowupDate), 'MM-dd-yyyy')} - ${format(
                  ignoreTimezoneDate(filters.toNextFollowupDate),
                  'MM-dd-yyyy',
                )}`,
                value: 'next-follow-up',
              },
            ]
          : [],
    },
    {
      label: 'Created At',
      key: 'createdAt',
      values:
        filters.fromCreatedAt && filters.toCreatedAt
          ? [
              {
                label: `${format(ignoreTimezoneDate(filters.fromCreatedAt), 'MM-dd-yyyy')} - ${format(
                  ignoreTimezoneDate(filters.toCreatedAt),
                  'MM-dd-yyyy',
                )}`,
                value: 'created-at',
              },
            ]
          : [],
    },
    {
      label: 'Date of Birth',
      key: 'birthDate',
      values: filters.dateOfBirth
        ? [
            {
              label: `${format(ignoreTimezoneDate(filters.dateOfBirth), 'MM-dd-yyyy')}`,
              value: 'birth-date',
            },
          ]
        : [],
    },
    {
      label: 'Primary Insurance',
      key: 'providers',
      values: filters.primaryInsuranceCarrier
        ? [
            {
              label: filters.primaryInsuranceCarrier,
              value: filters.primaryInsuranceCarrier,
            },
          ]
        : [],
    },
  ] as Filter[];

  const filteredFieldsConsideredForPreferences: string[] = allFilters.map((x) => x.key);

  const handleSaveFilters = useCallback(
    (clearFilters: boolean): void => {
      saveUserPreferences('referrals', filteredFieldsConsideredForPreferences, filters, dispatch, clearFilters);
    },
    [dispatch, filteredFieldsConsideredForPreferences, filters],
  );

  const handleRestoreFilters = useCallback(() => {
    const filtersToDispatch = getFiltersFromSavedPreferences(savedReferralFilters.filters);

    dispatch(
      updateReferralFilters({
        ...referralsInitialState.filters,
        ...filtersToDispatch,
      }),
    );
  }, [dispatch, savedReferralFilters.filters]);

  function handleFilterUpdate(newFilters: Record<string, string[]>) {
    const { nextFollowUp, birthDate, createdAt, primaryInsurance, ...normalFilters } = newFilters;

    dispatch(
      updateReferralFilters({
        ...referralsInitialState.filters,
        ...normalFilters,
        fromNextFollowupDate: emptyOrPreviouslySelected(nextFollowUp, 'fromNextFollowupDate'),
        toNextFollowupDate: emptyOrPreviouslySelected(nextFollowUp, 'toNextFollowupDate'),
        dateOfBirth: emptyOrPreviouslySelected(birthDate, 'dateOfBirth'),
        fromCreatedAt: emptyOrPreviouslySelected(createdAt, 'fromCreatedAt'),
        toCreatedAt: emptyOrPreviouslySelected(createdAt, 'toCreatedAt'),
        primaryInsuranceCarrier: emptyOrPreviouslySelected(primaryInsurance, 'primaryInsuranceCarrier'),
      }),
    );

    function emptyOrPreviouslySelected(newFilter: string[], filterKey: keyof typeof filters) {
      return newFilter && newFilter.length !== 0 ? filters[filterKey] : undefined;
    }
  }

  return (
    <FilterIndicator
      onFilterUpdate={handleFilterUpdate}
      filters={allFilters}
      onRestoreSavedFilters={handleRestoreFilters}
      onSaveFilters={() => handleSaveFilters(false)}
      onDeleteSavedFilters={() => handleSaveFilters(true)}
      hasSavedFilters={hasSavedFilters}
    />
  );
};

export default ReferralFilterIndicator;
