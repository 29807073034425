import { Dispatch } from 'redux';
import {
  getReferralProvidersApi,
  addReferralProviderApi,
  patchReferralProviderApi,
  deleteReferralProviderApi,
  getOneReferralProviderApi,
  GetReferralProviderParams,
  EditReferralProviderParams,
  AddReferralProviderParams,
} from '../../api/referralsApi';
import { showErrorMessage } from '../../components/NotificationToast/NotificationToast';
import backendErrorHandler from '../../globalUtils/backendErrorHandler';
import {
  GET_ALL_REFERRALS_PROVIDERS_SUCCESS,
  GET_ALL_REFERRALS_PROVIDERS_ERROR,
  GET_REFERRAL_PROVIDERS_ERROR,
  GET_REFERRAL_PROVIDERS_LOADING,
  GET_REFERRAL_PROVIDERS_SUCCESS,
  UPDATE_REFERRAL_FILTERS,
  UPDATE_REFERRAL_PROVIDER_SUCCESS,
  UPDATE_REFERRAL_PROVIDER_LOADING,
  UPDATE_REFERRAL_PROVIDER_ERROR,
  DELETE_REFERRAL_PROVIDER_SUCCESS,
  DELETE_REFERRAL_PROVIDER_LOADING,
  DELETE_REFERRAL_PROVIDER_ERROR,
  CLEAR_PROVIDERS_FROM_SEARCH,
  ADD_REFERRAL_PROVIDER_LOADING,
  ADD_REFERRAL_PROVIDER_SUCCESS,
  ADD_REFERRAL_PROVIDER_ERROR,
  GET_ONE_REFERRAL_PROVIDER,
  GET_ONE_REFERRAL_PROVIDER_SUCCESS,
  GET_ONE_REFERRAL_PROVIDER_ERROR,
} from '../constants/referralTypes';

export const getReferralProvidersLoading = (isLoading: boolean) => ({
  type: GET_REFERRAL_PROVIDERS_LOADING,
  payload: isLoading,
});

export const getReferralProvidersSuccess = (payload: any) => ({
  type: GET_REFERRAL_PROVIDERS_SUCCESS,
  payload,
});

export const getReferralProvidersError = (error: any) => ({
  type: GET_REFERRAL_PROVIDERS_ERROR,
  payload: error,
});

export const getAllReferralProvidersSuccess = (payload: any) => ({
  type: GET_ALL_REFERRALS_PROVIDERS_SUCCESS,
  payload,
});

export const getAllReferralProvidersError = (error: any) => ({
  type: GET_ALL_REFERRALS_PROVIDERS_ERROR,
  payload: error,
});

export const clearProvidersFromSearch = () => ({
  type: CLEAR_PROVIDERS_FROM_SEARCH,
});

export const updateReferralFilters = (payload: any) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_REFERRAL_FILTERS,
    payload,
  });
};

export const editReferralProviderLoading = () => ({
  type: UPDATE_REFERRAL_PROVIDER_LOADING,
});

export const editReferralProviderSuccess = (payload: any) => ({
  type: UPDATE_REFERRAL_PROVIDER_SUCCESS,
  payload,
});

export const editReferralProviderError = (error: any) => ({
  type: UPDATE_REFERRAL_PROVIDER_ERROR,
  error,
});

export const deleteReferralProviderLoading = () => ({
  type: DELETE_REFERRAL_PROVIDER_LOADING,
});

export const deleteReferralProviderSuccess = (payload: any) => ({
  type: DELETE_REFERRAL_PROVIDER_SUCCESS,
  payload,
});

export const deleteReferralProviderError = (error: any) => ({
  type: DELETE_REFERRAL_PROVIDER_ERROR,
  error,
});

export const getOneReferralProviderLoading = () => ({
  type: GET_ONE_REFERRAL_PROVIDER,
});

export const getOneReferralProviderSuccess = (payload: any) => ({
  type: GET_ONE_REFERRAL_PROVIDER_SUCCESS,
  payload,
});

export const getOneReferralProviderError = (error: any) => ({
  type: GET_ONE_REFERRAL_PROVIDER_ERROR,
  error,
});

export const addReferralProviderLoading = () => ({
  type: ADD_REFERRAL_PROVIDER_LOADING,
});

export const addReferralProviderSuccess = (payload: any) => ({
  type: ADD_REFERRAL_PROVIDER_SUCCESS,
  payload,
});

export const addReferralProviderError = (error: any) => ({
  type: ADD_REFERRAL_PROVIDER_ERROR,
  error,
});

export const getReferralProviders = (data: GetReferralProviderParams) => async (dispatch: Dispatch) => {
  try {
    dispatch(getReferralProvidersLoading(true));
    const response = await getReferralProvidersApi({
      pageSize: 20,
      sortField: 'firstName',
      sortDirection: 'ASC',
      ...data,
    });

    const noOfPages = Math.ceil(response.data?.totalCount / 20);
    for (let index = 2; index <= noOfPages; index++) {
      await getReferralProvidersApi({
        pageSize: 20,
        sortField: 'firstName',
        sortDirection: 'ASC',
        page: index,
        ...data,
      }).then((results) => {
        dispatch(
          getReferralProvidersSuccess({
            ...results.data,
            isLastPage: results.data?.data?.length === 0,
          }),
        );
      });
    }
    dispatch(
      getReferralProvidersSuccess({
        ...response.data,
        isLastPage: response.data?.data?.length === 0,
      }),
    );
    dispatch(getReferralProvidersLoading(false));
  } catch (e) {
    dispatch(getReferralProvidersError(e));
    dispatch(getReferralProvidersLoading(false));
  }
};

export const editReferralProviderAction =
  (data: EditReferralProviderParams, onSuccess?: (data?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(editReferralProviderLoading());
      const response = await patchReferralProviderApi(data);
      dispatch(editReferralProviderSuccess(response.data));
      if (onSuccess) {
        onSuccess(data);
      }
    } catch (e) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(editReferralProviderError(e));
    }
  };

export const deleteReferralProviderAction =
  (id: string, onSuccess?: (data?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(deleteReferralProviderLoading());
      const response = await deleteReferralProviderApi(id);
      dispatch(deleteReferralProviderSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(deleteReferralProviderError(e));
    }
  };

export const addReferralProviderAction =
  (data: AddReferralProviderParams, onSuccess?: (data: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(addReferralProviderLoading());
      const response = await addReferralProviderApi(data);
      dispatch(addReferralProviderSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(addReferralProviderError(e));
    }
  };

export const getOneReferralProviderAction =
  (id: string, onSuccess?: (data?: any) => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(getOneReferralProviderLoading());
      const response = await getOneReferralProviderApi(id);
      dispatch(getOneReferralProviderSuccess(response.data));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (e) {
      const errorMessage = backendErrorHandler(e);
      showErrorMessage(errorMessage);

      dispatch(getOneReferralProviderError(e));
    }
  };
