import axios, { AxiosResponse } from 'axios';
const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

export const getTasksApi = async (payload?: any): Promise<AxiosResponse<any, any>> => {
  return axios.post(`${BASE_URL}/tasks/find`, payload);
};

export const getTaskTypesApi = async (): Promise<AxiosResponse<any, any>> => {
  return axios.get(`${BASE_URL}/task-types`);
};

// export interface AddNewTaskParams {
//   search?: string;
//   active?: boolean;
//   statuses?: string[];
//   assignedUserIds?: string[];
//   taskTypeIds?: string[];
//   sortDirectionOne?: 'ASC' | 'DESC';
//   sortFieldOne?: 'createdAt' | string;
//   sortDirectionTwo?: 'ASC' | ' DESC';
//   sortFieldTwo?: 'sortOrder' | string;
//   sortByReferral?: boolean;
//   page?: number;
//   pageSize?: number;
// }
export interface AddNewTaskParams {
  referralId?: string;
  taskTypeId?: string;
  statusId?: string;
  statusReasonIds?: string[];
  message?: string;
  isExternal?: boolean;
  tagUserIds?: string[];
  referralFollowupTag?: boolean;
  patientContactTag?: boolean;
  waitingTag?: boolean;
  type?: 'PRIVATE' | 'PUBLIC';
}
export const addNewTaskApi = async (payload?: AddNewTaskParams): Promise<AxiosResponse<any, any>> => {
  return axios.post(`${BASE_URL}/tasks`, payload);
};
