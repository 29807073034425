import { BaseAction, FaxState } from '../types';
import {
  SEND_FAX_ACK,
  SEND_FAX_ACK_SUCCESS,
  SEND_FAX_ACK_ERROR,
  RESEND_FAX_ACKNOWLEDGEMENT_LOADING,
  RESEND_FAX_ACK_SUCCESS,
  RESEND_FAX_ACK_ERROR,
  GET_FAX_ACK_PREVIEW,
  GET_FAX_ACK_PREVIEW_SUCCESS,
  GET_FAX_ACK_PREVIEW_ERROR,
  FETCH_FAX_TYPES,
  FETCH_FAX_TYPES_SUCCESS,
  FETCH_FAX_TYPES_ERROR,
  CREATE_MEDICAL_REQUEST,
  CREATE_MEDICAL_REQUEST_ERROR,
  CREATE_MEDICAL_REQUEST_SUCCESS,
  MEDICAL_REQUEST_OPTIONS,
  MEDICAL_REQUEST_OPTIONS_ERROR,
  MEDICAL_REQUEST_OPTIONS_SUCCESS,
  SEND_MEDICAL_REQUEST_FAX,
  SEND_MEDICAL_REQUEST_FAX_ERROR,
  SEND_MEDICAL_REQUEST_FAX_SUCCESS,
} from '../constants/faxTypes';

const initialState: FaxState = {
  faxAckPreview: {
    loading: false,
    data: [],
    error: null,
  },
  sendFaxAck: {
    loading: false,
    data: [],
    error: null,
  },
  reSendFaxAck: {
    loading: false,
    data: [],
    error: null,
  },
  faxTypes: {
    loading: false,
    data: [],
    error: null,
  },
  medicalRecordsRequestOptions: {
    loading: false,
    data: [
      {
        id: '0B4744A1-7EA0-48E9-88D6-1565F1D5AA1B',
        title: 'Required to Complete PA',
        description: null,
        sortOrder: 1,
      },
      {
        id: '529C2B40-BD1E-4A77-AD7A-20366B6EC56B',
        title: 'Insurance Request',
        description: null,
        sortOrder: 2,
      },
      {
        id: 'D304FA3E-2897-49FF-9FB0-FA7686742218',
        title: 'Order Clarification',
        description: null,
        sortOrder: 3,
      },
      {
        id: '8C06FBF2-DAEC-4360-973F-A3A904632B4C',
        title: 'FlexCare Request',
        description: null,
        sortOrder: 4,
      },
      {
        id: '02E92AB5-5481-4387-BEF5-F4DB7C0A1F64',
        title: 'Other',
        description: null,
        sortOrder: 5,
      },
    ],
    error: null,
  },
  createMedicalRequestItems: {
    loading: false,
    data: null,
    error: null,
  },
  sendMedicalRecordRequestFax: {
    loading: false,
    data: null,
    error: null,
  },
};

const reducer = (state: FaxState = initialState, action: BaseAction) => {
  switch (action.type) {
    case GET_FAX_ACK_PREVIEW:
      return {
        ...state,
        faxAckPreview: {
          ...state.faxAckPreview,
          loading: true,
        },
      };
    case GET_FAX_ACK_PREVIEW_SUCCESS:
      return {
        ...state,
        faxAckPreview: {
          ...state.faxAckPreview,
          loading: false,
          data: action.payload,
        },
      };
    case GET_FAX_ACK_PREVIEW_ERROR:
      return {
        ...state,
        faxAckPreview: {
          ...state.faxAckPreview,
          loading: false,
          data: action.payload,
        },
      };
    case SEND_FAX_ACK:
      return {
        ...state,
        sendFaxAck: {
          ...state.sendFaxAck,
          loading: true,
        },
      };
    case SEND_FAX_ACK_SUCCESS:
      return {
        ...state,
        sendFaxAck: {
          ...state.sendFaxAck,
          loading: false,
          data: action.payload,
        },
      };
    case SEND_FAX_ACK_ERROR:
      return {
        ...state,
        sendFaxAck: {
          ...state.sendFaxAck,
          loading: false,
          error: action.payload,
        },
      };
    case RESEND_FAX_ACKNOWLEDGEMENT_LOADING:
      return {
        ...state,
        reSendFaxAck: {
          ...state.reSendFaxAck,
          loading: true,
        },
      };
    case RESEND_FAX_ACK_SUCCESS:
      return {
        ...state,
        reSendFaxAck: {
          ...state.reSendFaxAck,
          loading: false,
          data: action.payload,
        },
        sendFaxAck: {
          ...state.sendFaxAck,
          loading: false,
        },
      };
    case RESEND_FAX_ACK_ERROR:
      return {
        ...state,
        reSendFaxAck: {
          ...state.reSendFaxAck,
          loading: false,
          error: action.payload,
        },
        sendFaxAck: {
          ...state.sendFaxAck,
          loading: false,
        },
      };

    case FETCH_FAX_TYPES:
      return {
        ...state,
        faxTypes: {
          ...state.faxTypes,
          loading: true,
        },
      };
    case FETCH_FAX_TYPES_SUCCESS:
      return {
        ...state,
        faxTypes: {
          ...state.faxTypes,
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_FAX_TYPES_ERROR:
      return {
        ...state,
        faxTypes: {
          ...state.faxTypes,
          error: action.payload,
          loading: false,
        },
      };

    case CREATE_MEDICAL_REQUEST:
      return {
        ...state,
        createMedicalRequestItems: {
          ...state.createMedicalRequestItems,
          loading: true,
        },
      };
    case CREATE_MEDICAL_REQUEST_ERROR:
      return {
        ...state,
        createMedicalRequestItems: {
          ...state.createMedicalRequestItems,
          loading: false,
          error: action.payload,
        },
      };
    case CREATE_MEDICAL_REQUEST_SUCCESS:
      return {
        ...state,
        createMedicalRequestItems: {
          ...state.createMedicalRequestItems,
          loading: false,
          data: action.payload,
        },
      };
    case SEND_MEDICAL_REQUEST_FAX:
      return {
        ...state,
        sendMedicalRecordRequestFax: {
          ...state.sendMedicalRecordRequestFax,
          loading: true,
        },
      };
    case SEND_MEDICAL_REQUEST_FAX_ERROR:
      return {
        ...state,
        sendMedicalRecordRequestFax: {
          ...state.sendMedicalRecordRequestFax,
          loading: false,
          error: action.payload,
        },
      };
    case SEND_MEDICAL_REQUEST_FAX_SUCCESS:
      return {
        ...state,
        sendMedicalRecordRequestFax: {
          ...state.sendMedicalRecordRequestFax,
          loading: false,
          data: action.payload,
        },
      };
    case MEDICAL_REQUEST_OPTIONS:
      return {
        ...state,
        medicalRecordsRequestOptions: {
          ...state.medicalRecordsRequestOptions,
          loading: true,
        },
      };
    case MEDICAL_REQUEST_OPTIONS_ERROR:
      return {
        ...state,
        medicalRecordsRequestOptions: {
          ...state.medicalRecordsRequestOptions,
          loading: false,
          error: action.payload,
        },
      };
    case MEDICAL_REQUEST_OPTIONS_SUCCESS:
      return {
        ...state,
        medicalRecordsRequestOptions: {
          ...state.medicalRecordsRequestOptions,
          loading: false,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
