import React, { FC, useEffect, useState } from 'react';
import SelectMenu, { SelectMenuProps } from '../SelectMenu/SelectMenu';
import { useDispatch, useSelector } from 'react-redux';
// import { Box, Typography } from '@mui/material';
// import providerPlusPrimary from '../../images/providerPlusIconPrimary.svg';
import { getClinicsAction } from '../../redux/actions/clinicActions';
import { RootState } from '../../redux/types';
import clinicBlueImage from '../../images/clinicBlue.svg';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'validate.js';
// import { format } from 'date-fns';

interface Props extends Partial<SelectMenuProps> {
  initialValue?: any;
  regionToFilterBy?: any;
  onChange: (item: any) => void;
  style?: React.CSSProperties;
  labelFor?: string;
  icon?: React.ReactNode;
  valueIcon?: React.ReactNode;
  showClearIcon?: boolean;
  showExpandMore?: boolean;
  homeCityToFilterBy?: string;
}

const EditClinic: FC<Props> = (props) => {
  const { initialValue, onChange, regionToFilterBy, labelFor, homeCityToFilterBy, ...restProps } = props;
  const dispatch = useDispatch();

  function loadClinics() {
    dispatch(getClinicsAction({ homeCity: homeCityToFilterBy }));
  }

  const { data: clinics, loading } = useSelector((state: RootState) => state.clinics);
  const filteredClinics = clinics?.filter((clinic: any) => {
    return clinic?.regionId === regionToFilterBy?.id;
  });

  const clinicsToDisplay = isEmpty(filteredClinics) ? clinics : filteredClinics;

  // const providers: any[] = [];

  const [value, setValue] = useState(initialValue);

  const handleChange = (item: any) => {
    onChange(item ? item : { id: null });
    setValue(item);
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (
      regionToFilterBy?.id !== (value?.region?.id || value?.regionId) &&
      !initialValue &&
      !isEmpty(value) &&
      regionToFilterBy?.id
    ) {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionToFilterBy, value]);

  const options = clinicsToDisplay
    ?.map((item: any) => {
      return {
        ...item,
        label: item?.name,
        secondaryLabel: item?.distance ? `${item?.distance} miles` : '',
        searchString: item.name,
      };
    })
    .sort((a: any, b: any) => {
      return (a.distance || Number.POSITIVE_INFINITY) - (b.distance || Number.POSITIVE_INFINITY);
    });

  const isSelectedOption = (option: any): boolean => option?.id === value?.id;

  return (
    <SelectMenu
      loading={loading}
      disabled={loading}
      value={value?.label ? value : initialValue}
      onClicking={loadClinics}
      onChange={handleChange}
      onAddNew={console.log}
      handleSearch={console.log}
      showClearIcon
      hideAddnew
      searchLoading={false}
      valueIcon={<img src={clinicBlueImage} alt="ellipse" />}
      icon={<img src={clinicBlueImage} alt="ellipse" />}
      options={options.map((option: any) => ({
        ...option,
        disabled: option?.disabled || isSelectedOption(option),
        showDoneIcon: isSelectedOption(option),
      }))}
      labelTransform={(val) => (
        <Box style={{ paddingBottom: 8 }}>
          {labelFor && (
            <Typography variant="caption" color="textSecondary">
              {labelFor}
            </Typography>
          )}
          <Typography style={{ lineHeight: '16px' }} color="textPrimary">
            {val?.label}
          </Typography>
        </Box>
      )}
      label="Select"
      style={{ paddingLeft: 8 }}
      groupBy={(item) => {
        return {
          label: (
            <Box style={{ padding: `8px 16px` }}>
              <Typography variant="button" color="textSecondary">
                {item?.region?.name || 'OTHERS'}
              </Typography>
            </Box>
          ),
          key: item?.region?.name || 'OTHERS',
        };
      }}
      {...restProps}
    />
  );
};

export default EditClinic;
