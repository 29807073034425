import { Box, BoxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useStyles } from './referralViewStyles';

export function FieldLabel({ children }: { children: ReactNode }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.label}>
      <Typography variant="subtitle2" color="textSecondary">
        {children}
      </Typography>
    </Box>
  );
}

export function FieldBody({ children, ...props }: BoxProps) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.labelInput}
      style={{ ...(props?.style || {}), paddingRight: 16 }}
      {...props}
    >
      {children}
    </Box>
  );
}
