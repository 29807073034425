import { FC, Suspense } from 'react';
import { Provider } from 'react-redux';
import store from '../../redux/store';
import AppRoutes from '../../routes';
import { BrowserRouter } from 'react-router-dom';
import { theme } from '../../themes/theme';
import { IconButton, ThemeProvider } from '@mui/material';
import { THEMES } from '../../themes/themeConstants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../styles/global.css';
import '../../components/NotificationToast/NotificationToast.css';
import CacheBuster from 'react-cache-buster';
import AppPackage from '../../../package.json';
import HelpIcon from '@mui/icons-material/Help';

const App: FC<{}> = () => {
  console.log('The package version is============>', AppPackage.version);
  const isProduction: boolean = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={AppPackage.version}
      isEnabled={isProduction}
      loadingComponent={<div>...</div>}
      isVerboseMode={true}
    >
      <Suspense fallback={<div>...</div>}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns as any}>
            <ThemeProvider theme={theme(THEMES.LIGHT)}>
              <BrowserRouter>
                <Provider store={store}>
                  <AppRoutes />
                </Provider>
              </BrowserRouter>
            </ThemeProvider>
          </LocalizationProvider>
        </SnackbarProvider>
        <IconButton
          style={{
            position: 'fixed',
            right: '16px',
            bottom: '16px',
            color: '#FF9800',
            zIndex: 10000,
            padding: 0,
            fontSize: '48px',
          }}
          href="https://form.asana.com/?k=f4TaxsTKIj2OvKaXF98khw&d=1117710989905834"
          target="_blank"
        >
          <HelpIcon fontSize="inherit" />
        </IconButton>
      </Suspense>
    </CacheBuster>
  );
};

export default App;
