import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    searchInput: {
      width: '100%',
      border: 'none',
      background: '#0000000A',
      borderRadius: 24,
      padding: '8px 4px 8px 8px',
      height: 40,
      alignItems: 'center',
      paddingRight: 16,
    },
    searchIcon: {
      paddingLeft: 16,
      paddingRight: 14,
    },
    customIconContainer: {
      paddingRight: 14,
    },
    adornmentContainer: {
      width: 30,
      height: 30,
    },
  };
});
